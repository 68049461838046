import React, { ReactNode } from 'react';

import { NcAlert } from '../NcAlert';

export type NcErrorPropsT = {
  message: string;
  children?: ReactNode;
  className?: string;
  displaySupportText?: boolean;
};
export type NcErrorT = React.FC<NcErrorPropsT>;

export const NcError: NcErrorT = ({
  message,
  children,
  className,
  displaySupportText,
}) => {
  return (
    <div className={`nc-error ${className || ''} col col-md-6 mx-auto`}>
      <NcAlert dismissible={false} variant="warning">{message}</NcAlert>
      <p className="support-message text-center mb-1">
        {children}
      </p>
      {displaySupportText && (
        <p className="support-message text-center">
          Please call our Customer Support Team at <a href="tel:8773922014">(877)&nbsp;392-2014</a> or try again later.
        </p>
      )}
    </div>
  );
};
