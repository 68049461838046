import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { cookieJar } from './cookieJar';
import {
  accountHomeApiBaseUrl,
  apiEndpoints,
  loginServerBaseUrl,
  loginServerTenantID,
  uiRoutes,
} from './endpoints';

const clientId = import.meta.env.VITE_CFE_CLIENT_ID || 'dashboard';
const grantType = 'refresh_token';

export type AxiosConfigT = InternalAxiosRequestConfig & { _retry?: boolean; };

// API client
export const defaultAxiosConfig: AxiosConfigT = {
  baseURL: accountHomeApiBaseUrl,
  headers: new AxiosHeaders({
    Accept: 'application/json, text/html, text/plain, */*',
    Authorization: `Bearer ${cookieJar.getAccessToken()}`,
    ClientID: clientId,
    'Content-Type': 'application/json',
    'X-APP-NAME': 'dashboard',
  }),
  withCredentials: true, // Tells axios to use https and cookies rather than plain http.
};
export const apiClient = axios.create(defaultAxiosConfig);

// Login server client
// Can't use the default apiClient here because login server needs `withCredentials` to be false.
export const defaultLoginServerPostData = {
  grant_type: grantType,
};
export const loginServerAxiosConfig: AxiosConfigT = {
  baseURL: loginServerBaseUrl,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json',
    'X-Login-Server-Tenant-ID': loginServerTenantID,
  }),
  withCredentials: false,
};
export const loginServerClient = axios.create(loginServerAxiosConfig);

// Blob client
export const blobFetcher = (url: string) => apiClient.get(url, { responseType: 'blob' }).then((res) => res.data);

// Interceptor helpers
export const noopSuccessHandler = <T = AxiosResponse>(resp: T) => Promise.resolve(resp);
export const noopErrorHandler = (err: AxiosError) => Promise.reject(err);

export const logOutAndRedirect = () => {
  cookieJar.expire();
  window.location.href = uiRoutes.logOut;
};

export const refreshTokens = (): Promise<AxiosResponse | AxiosError | Error> => {
  const currentToken = cookieJar.getRefreshToken();

  if (!currentToken && import.meta.env.VITE_MOCKING !== 'true') {
    logOutAndRedirect();

    return Promise.reject(new Error('No refresh token found'));
  }

  return loginServerClient
    .post(apiEndpoints.token, {
      ...defaultLoginServerPostData,
      refresh_token: currentToken,
    })
    .then(
      (resp: AxiosResponse<{ access_token: string; refresh_token: string; }>) => {
        cookieJar.expire();
        cookieJar.saveTokens(resp.data.access_token, resp.data.refresh_token);

        return Promise.resolve(resp);
      },
      (err: AxiosError) => {
        console.warn('Error refreshing token:', err);

        return Promise.reject(err);
      },
    )
  ;
};

export const retryRequest = (originalRequest: AxiosConfigT): Promise<AxiosResponse | AxiosError> => {
  const newRequest = { ...originalRequest, _retry: true };

  return apiClient.request(newRequest).then(
    (resp: AxiosResponse) => Promise.resolve(resp),
    (err: AxiosError) => Promise.reject(err),
  );
};

export const refreshAndRetry = (err: AxiosError): Promise<AxiosResponse | AxiosError> => {
  const originalRequest = err.config as AxiosConfigT;
  const status = err.status || err.response?.status;

  if (status && [401, 403].includes(status)) {
    // Retry if the request hasn't already been retried.
    if (!originalRequest._retry) {
      return refreshTokens().then(
        () => retryRequest(originalRequest),
        (refreshError: AxiosError) => {
          logOutAndRedirect();

          return Promise.reject(refreshError);
        },
      );
    }

    // If the request has already been retried but we're still seeing 401/403 from the API, this means the token refresh
    // succeeded but the session is expired, so log out and redirect.
    logOutAndRedirect();

    return Promise.reject(err);
  }

  return Promise.reject(err);
};

export const ensureFreshToken = (req: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  if (!cookieJar.isTokenExpired()) {
    return Promise.resolve(req);
  }

  return refreshTokens().then(
    () => {
      apiClient.defaults.headers.Authorization = `Bearer ${cookieJar.getAccessToken()}`;
      req.headers = req.headers || {};
      req.headers.Authorization = `Bearer ${cookieJar.getAccessToken()}`;

      return Promise.resolve(req);
    },
    (err: AxiosError) => {
      logOutAndRedirect();

      return Promise.reject(err);
    },
  );
};

// Axios interceptors
apiClient.interceptors.request.use(
  ensureFreshToken,
  noopErrorHandler,
);

apiClient.interceptors.response.use(
  noopSuccessHandler,
  refreshAndRetry,
);
