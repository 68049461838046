import React from 'react';

import { uiRoutes } from '@consumer-frontend/nc-api';
import { Link } from '@consumer-frontend/shared-ui';

import { NcOsano } from './NcOsano';
import { NcSocial } from './NcSocial';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcLinksT = React.FC;

export const NcLinks: NcLinksT = () => {
  return (
    <div className="links d-flex flex-md-row flex-column flex-grow-1 justify-content-between">
      <NcSocial />
      <div className="link-group d-flex flex-column">
        <Link to={uiRoutes.howItWorks}>How It Works</Link>
        <Link to={uiRoutes.faq}>FAQ</Link>
        <Link to={uiRoutes.ratesAndTerms}>Rates and Terms</Link>
      </div>
      <div className="link-group d-flex flex-column">
        <Link to={uiRoutes.aboutUs}>Our Story</Link>
        <Link to={uiRoutes.contactUs}>Contact Us</Link>
        <Link to={uiRoutes.newsMedia}>News & Media</Link>
      </div>
      <div className="link-group d-flex flex-column">
        <Link to={uiRoutes.privacyPolicy}>Privacy Policy</Link>
        <Link to={uiRoutes.termsOfUse}>Terms of Use</Link>
        <Link to={uiRoutes.optOut}>Opt Out</Link>
        <Link to={uiRoutes.californiaPrivacyPolicy}>California Privacy Policy</Link>
        <Link to={uiRoutes.oregonPrivacyPolicy}>Oregon Privacy Policy</Link>
      </div>
      <div className="link-group d-flex flex-column">
        <Link to={uiRoutes.blog}>Blog</Link>
        <Link to={uiRoutes.siteMap}>Sitemap</Link>
        <Link to={uiRoutes.financialResources}>Financial Resources</Link>
        <Link to={uiRoutes.financingTypes}>Financing Types</Link>
        <NcOsano />
      </div>
    </div>
  );
};
