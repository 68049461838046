import React from 'react';

import CardFooter from 'react-bootstrap/CardFooter';

type FooterPropsT = {
  children?: React.ReactNode;
  className?: string;
};
export type FooterT = React.FC<FooterPropsT>;

export const Footer: FooterT = ({ children, className }: FooterPropsT) => (
  <CardFooter as="footer" className={`footer ${className}`}>
    {children}
  </CardFooter>
);
