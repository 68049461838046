import React from 'react';

import { ListGroupItem as BootstrapListGroupItem } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';

export type ListGroupItemPropsT = {
  action?: boolean;
  active?: boolean;
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  variant?: Variant;
};
export type ListGroupItemT = React.FC<ListGroupItemPropsT>;

export const ListGroupItem: ListGroupItemT = ({ children, ...props }) => (
  <BootstrapListGroupItem {...props} as="li">
    {children}
  </BootstrapListGroupItem>
);
