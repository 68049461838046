/* eslint-disable camelcase */
import { faker } from '@faker-js/faker/locale/en';

import { MessageT } from '../types';

export const getMockedAccessToken = (): string => {
  const secretKey = 'fake-secret-key';

  const header = {
    alg: 'RS256',
    kid: 'netcredit_dev',
    typ: 'JWT',
  };

  const payload = {
    aud: 'netcredit_dev',
    authorized: true,
    client_id: 'dashboard',
    exp: Math.floor(Date.now() / 1000) + (60 * 60), // Expires in 1 hour
    iss: 'https://login-server.enova.test/',
    tenant_id: 'netcredit_dev',
    user_id: '123456',
  };

  return `${window.btoa(JSON.stringify(header))}.${window.btoa(JSON.stringify(payload))}.${window.btoa(secretKey)}`;
};

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  month: 'long',
  timeZone: 'CST',
  timeZoneName: 'short',
  year: 'numeric',
};

export const formattedDate = (date: Date) => (
  new Intl.DateTimeFormat('en-US', dateFormatOptions)
    .format(date)
    .replace('at ', '')
);

type HtmlBodyT = (args: {
  sent_at: string;
  subject: string;
  to: string;
}) => string;

export const htmlBody: HtmlBodyT = ({
  sent_at,
  subject,
  to,
}) => {
  const name = to.split(/[_.0-9]/g)[0];

  return `<html>
  <head>
    <style>
      body {
        color: #333;
        font-family: "Open Sans", -apple-system, blinkmacsystemfont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial",
          "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 16px;
        line-height: 1.625;
        margin: 0;
        overflow-y: hidden;
        padding: 0;
      }
      p {
        margin-top: 1.5rem;
      }
      a {
        color: #006A95;
        font-weight: bold;
      }
      hr {
        opacity: .4;
        margin: 2.5rem 0;
      }
      .legalese {
        font-size: 12px;
      }
    </style>
  <body>
    Account Number: 24835287
    <br/>
    Loan Number: 2019AL642685929
    <br/>
    Issued Date: March 1, 2023
    <br/>
    Balance Owed: $500.00

    <p>
      <strong>
        ${subject}
      </strong>
    </p>
    <p>${sent_at}</p>
    <p>${name},</p>
    <p>
      Because you did not pay your past-due balance in the amount of $100.00 by
      November 8, 2024 your entire outstanding balance has been accelerated, and
      the entire balance for your loan balance is now due. You now owe $500.00,
      which includes all amounts owed on your loan.
    </p>
    <p>
      Please call us at (877) 392-2016 to set up an arrangement to pay the
      outstanding balance mentioned above.
    </p>
    <p>Sincerely,</p>
    <strong>The NetCredit Collections Support Team</strong>
    <br/>
    Phone: (877) 392-2016
    <br/>
    Email: <a href="mailto:collections@netcredit.com">collections@netcredit.com</a>
    <br/>
    Fax: (855) 878-1070
    <br/>
    <a href="https://www.netcredit.com" target="_blank">www.netcredit.com</a>

    <hr>

    <div class="legalese">
      <p>
        THE PURPOSE OF THIS COMMUNICATION IS TO COLLECT A DEBT AND ANY INFORMATION OBTAINED WILL BE USED FOR THAT PURPOSE.
      </p>

      <p>
        All NetCredit loans and lines of credit are underwritten by, approved by and funded by either NetCredit, Republic
        Bank & Trust Company, or Transportation Alliance Bank, Inc. See your loan or line of credit documents, or visit
        <a href="https://www.netcredit.com/lending-partners" target="_blank">https://www.netcredit.com/lending-partners</a>,
        for lender information and details. All loans and lines of credit are serviced by NetCredit.
      </p>

      <p>
        For California Customers: The state Rosenthal Fair Debt Collection Practices Act and Federal Fair Debt Collection
        Practices Act require that, except under unusual circumstances, collectors may not contact you before 8am or after
        9pm. They may not harass you by using threats of violence or arrest or by using obscene language. Collectors may not
        use false or misleading statements or call you at work if they know or have reason to know that you may not receive
        personal calls at work. For the most part, collectors may not tell another person, other than your attorney or
        spouse, about your debt. Collectors may contact another person to confirm your location or enforce a judgment. For
        more information about debt collection activities, you may contact the Federal Trade Commission at 1-877-FTC-HELP or
        <a href="https://www.ftc.gov" target="_blank">www.ftc.gov</a>.
      </p>

      <p>
        IF YOU ARE A DEBTOR IN AN ACTIVE BANKRUPTCY CASE OR HAVE RECEIVED A DISCHARGE IN BANKRUPTCY WITH RESPECT TO THE
        ABOVE-REFERENCED LOAN, THIS LETTER IS FOR INFORMATIONAL PURPOSES ONLY AND IS NOT A DEMAND FOR PAYMENT OR AN ATTEMPT
        TO COLLECT A DEBT FROM YOU PERSONALLY.
      </p>

      <p>
        To stop further collection emails, please fill out the collections opt-out form:
        <a href="https://www.netcredit.com/collections-opt-out" target="_blank">www.netcredit.com/collections-opt-out</a>.
      </p>
    </div>
  </body>
</html>`;
};

export const mockMessage: (id?: number) => MessageT = (id) => {
  const sent_at = formattedDate(faker.date.recent());
  const subject = faker.lorem.sentence();
  const to = faker.internet.email();

  return {
    from: faker.internet.email(),
    html_part: htmlBody({
      sent_at,
      subject,
      to,
    }),
    id: id || faker.number.int(),
    sent_at,
    status: faker.helpers.arrayElement([
      'queued',
      'failed',
      'sent',
      'received',
      'income',
      'bccd',
      'bounced',
    ]),
    subject,
    to,
  };
};
