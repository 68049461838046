import React from 'react';

import { NcContractPDF } from '../NcContractPDF/NcContractPDF';
import { NcFeeSaverBanner } from '../NcFeeSaverBanner/NcFeeSaverBanner';

import '@consumer-bootstrap/brands/netcredit/contract/contract-header.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcContractHeaderPropsT = {
  accountId: string;
  feeSaverEnabled: boolean;
  loanNumber: string;
  productTypeLabel: string;
};
export type NcContractHeaderT = React.FC<NcContractHeaderPropsT>;

export const NcContractHeader: NcContractHeaderT = ({
  accountId,
  feeSaverEnabled,
  loanNumber,
  productTypeLabel,
}) => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="contract-header-container">
      <div className="contract-header">
        <div className="contract-header-details">
          <h3>{productTypeLabel} Loan Agreement</h3>
          <p data-chromatic="ignore">{currentDate}</p>
        </div>
        <NcContractPDF accountId={accountId} loanNumber={loanNumber} />
      </div>
      { feeSaverEnabled && <NcFeeSaverBanner /> }
    </div>
  );
};
