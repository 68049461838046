import React from 'react';

import BsPagination from 'react-bootstrap/Pagination';

import { PageValuesT, PaginationLabel } from './PaginationLabel';
import { Container } from '../Container';

export * from './PaginationLabel';

export type PaginationPropsT = {
  nextHandler: () => void;
  paginationValues: PageValuesT;
  prevHandler: () => void;
  className?: string;
  hideLabel?: boolean;
  size?: 'sm' | 'lg';
};
export type PaginationT = React.FC<PaginationPropsT>;

export const Pagination: PaginationT = ({
  className,
  hideLabel,
  nextHandler,
  paginationValues,
  prevHandler,
  ...props
}) => {
  const { offset, total } = paginationValues;
  const limit = paginationValues.limit || 10; // Limit should never be 0, so fallback to default value of 10.

  return (
    <Container className={`${className} p-0 m-0`}>
      {!hideLabel && <PaginationLabel {...paginationValues} />}
      <BsPagination {...props}>
        <BsPagination.Prev
          className="prev"
          disabled={offset <= 0}
          onClick={prevHandler}
        />
        <BsPagination.Next
          className="next"
          // TEMP PAGINATION HACK, to be reverted once the total count is fixed.
          // disabled={offset + limit >= total}
          disabled={total < limit}
          onClick={nextHandler}
        />
      </BsPagination>
    </Container>
  );
};
