import React from 'react';

import { Nav as BootstrapNav } from 'react-bootstrap';

export type NavPropsT = {
  children: React.ReactNode;
  as?: React.ElementType;
  className?: string;
  fill?: boolean;
  id?: string;
  justify?: boolean;
  navbar?: boolean;
  navbarScroll?: boolean;
  role?: string;
  variant?: 'tabs' | 'pills' | 'underline' | string;
};
export type NavT = React.FC<NavPropsT>;

export const Nav: NavT = ({ children, ...props }) => {
  return <BootstrapNav {...props}>{children}</BootstrapNav>;
};
