import React from 'react';

import { Alert, AlertPropsT } from '@consumer-frontend/shared-ui';

import '@consumer-bootstrap/brands/netcredit/alerts.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcAlertT = React.FC<AlertPropsT>;

export const NcAlert: NcAlertT = ({ children, ...props }) => {
  return (
    <Alert className="nc-alert" {...props}>
      {children}
    </Alert>
  );
};
