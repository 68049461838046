import React from 'react';

import { Footer } from '@consumer-frontend/shared-ui';

import { NcDisclaimerText } from './NcDisclaimerText';
import { NcLinks } from './NcLinks';
import { NcSecurityWidgets } from './NcSecurityWidgets';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

export const NcFooter: React.FC = () => {
  return (
    <Footer className="flex-column">
      <div className="footer-links">
        <NcLinks />
      </div>

      <hr className="footer-divider" />

      <div className="footer-security d-flex flex-md-row flex-column">
        <NcDisclaimerText />
        <NcSecurityWidgets />
      </div>
    </Footer>
  );
};
