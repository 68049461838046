const OpenLoanTypeList = [
  '',
  undefined,
  'netcredit',
  'netcredit_line_of_credit',
  'netcredit_bank_partnership',
  'netcredit_bank_partnership_loc',
  'netcredit_bank_partnership_tab',
  'netcredit_bank_partnership_tab_loc',
  'netcredit_bank_partnership_ccb',
  'netcredit_bank_partnership_ccb_loc',
] as const;

export type OpenLoanTypeT = typeof OpenLoanTypeList[number];

export type SimplifiedAccountInfoT = {
  account_id: number;
  first_name: string;
  last_name: string;
  login: string;
  open_loan_type: OpenLoanTypeT;
  show_fee_saver_status: boolean;
  open_loan_number?: string;
};

const locLoanTypes = [
  'netcredit_bank_partnership_loc',
  'netcredit_bank_partnership_ccb_loc',
  'netcredit_bank_partnership_tab_loc',
  'netcredit_line_of_credit',
];

export const isLoanTypeLoc = (loanType: OpenLoanTypeT) => loanType && locLoanTypes.includes(loanType);

export const isOpenLoan = (loanType?: OpenLoanTypeT) => !!loanType;
