export const accountHomeBaseUrl = import.meta.env.VITE_NC_ACCOUNT_HOME_BASE_URL || 'https://account-home.netcredit.dev';
export const accountHomeApiBaseUrl = import.meta.env.VITE_NC_ACCOUNT_HOME_API_BASE_URL
  || 'https://account-home.netcredit.dev/api';
export const loginServerBaseUrl = import.meta.env.VITE_LOGIN_SERVER_BASE_URL || 'http://localhost:4180';
export const loginServerTenantID = import.meta.env.VITE_LOGIN_SERVER_TENANT_ID || 'netcredit_dev';
export const netcreditBaseUrl = accountHomeBaseUrl.includes('my')
  ? accountHomeBaseUrl.replace('my', 'www')
  : accountHomeBaseUrl
;

type QueryParamsT = Record<string, string>;

const urlWithQueryParams = (url: string, queryParams?: QueryParamsT) => {
  if (!queryParams) {
    return url;
  }

  const params = new URLSearchParams(queryParams);

  return `${url}?${params.toString()}`;
};

export const apiEndpoints = {
  accountSummary: (accountId: string) => `${accountHomeApiBaseUrl}/v1/accounts/${accountId}/summary`,
  adobeDataLayer: (accountId: string) => `${accountHomeApiBaseUrl}/v1/accounts/${accountId}/adobe_data_layer`,
  contract: (accountId: string, loanNumber: string) => (
    `${accountHomeApiBaseUrl}/v1/accounts/${accountId}/loans/${loanNumber}/contract`
  ),
  contractPDF: (accountId: string, loanNumber: string) => (
    `${accountHomeApiBaseUrl}/v1/accounts/${accountId}/loans/${loanNumber}/pdf_contract`
  ),
  loanSummary: (accountId: string, loanNumber: string) => (
    `${accountHomeApiBaseUrl}/v1/accounts/${accountId}/loans/${loanNumber}/summary`
  ),
  messages: (accountId: string, queryParams?: QueryParamsT) => (
    urlWithQueryParams(`${accountHomeApiBaseUrl}/v1/accounts/${accountId}/messages`, queryParams)
  ),
  token: `${loginServerBaseUrl}/token`,
};

export const uiRoutes = {
  aboutUs: `${netcreditBaseUrl}/about-us`,
  accountSummary: `${accountHomeBaseUrl}/my_account/summary`,
  bankInfo: `${accountHomeBaseUrl}/my_info/bank`,
  blog: `${netcreditBaseUrl}/blog`,
  californiaPrivacyPolicy: `${netcreditBaseUrl}/california-privacy-policy`,
  cashAdvance: (loanNumber: string) => `${accountHomeBaseUrl}/loans/${loanNumber}/draw`,
  contactUs: `${netcreditBaseUrl}/contact-us`,
  contract: '/contract',
  employmentInfo: `${accountHomeBaseUrl}/my_info/employment`,
  faq: `${netcreditBaseUrl}/faq`,
  feeSaverStatus: `${accountHomeBaseUrl}/fee_saver_status`,
  financialResources: `${netcreditBaseUrl}/resources`,
  financingTypes: `${netcreditBaseUrl}/financing-types`,
  howItWorks: `${netcreditBaseUrl}/how-it-works`,
  legacyContract: (loanNumber: string) => `${accountHomeBaseUrl}/loans/${loanNumber}/contract`,
  lendingPartners: `${netcreditBaseUrl}/lending-partners`,
  loanDetails: `${accountHomeBaseUrl}/my_account/loan_details`, // used in minimum payment estimator
  logOut: `${accountHomeBaseUrl}/logout`,
  makePayment: `${accountHomeBaseUrl}/my_account/make_a_payment`,
  messageDetail: '/message-detail',
  messages: '/messages',
  newsMedia: `${netcreditBaseUrl}/news-media`,
  optOut: `${accountHomeBaseUrl}/opt-out`,
  oregonPrivacyPolicy: `${netcreditBaseUrl}/oregon-privacy-policy`,
  personalInfo: `${accountHomeBaseUrl}/my_info/personal`,
  privacyPolicy: `${netcreditBaseUrl}/privacy-policy`,
  ratesAndTerms: `${netcreditBaseUrl}/rates-and-terms`,
  siteMap: `${netcreditBaseUrl}/sitemap`,
  statements: (loanNumber: string) => `${accountHomeBaseUrl}/loans/${loanNumber}/statements`,
  termsOfUse: `${netcreditBaseUrl}/terms-of-use`,
};

export const externalLinks = {
  facebook: 'https://www.facebook.com/NetCredit',
  mcafee: 'https://www.mcafeesecure.com/RatingVerify?ref=netcredit.com',
  x: 'https://x.com/NetCredit',
};
