import { http, HttpResponse } from 'msw';

import { apiEndpoints } from '../../lib';

export const messageHandlers = {
  empty: http.get(apiEndpoints.messages(':accountId'), () => HttpResponse.json([], { status: 200 })),
  error: http.get(apiEndpoints.messages(':accountId'), () => new Promise(
    (_, reject) => { setTimeout(() => reject(HttpResponse.error()), 1000); },
  )),
  loading: http.get(apiEndpoints.messages(':accountId'), () => new Promise(
    (resolve) => { setTimeout(() => resolve(HttpResponse.json([], { status: 200 })), 30000); },
  )),
};
