import { faker } from '@faker-js/faker/locale/en';
import { http, HttpResponse } from 'msw';

import { apiEndpoints } from '../../lib';

export const accountSummaryHandlers = {
  default: http.get(apiEndpoints.accountSummary(':accountId'), () => HttpResponse.json(
    {
      account_id: faker.number.int(),
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      login: faker.internet.email(),
      open_loan_number: `LOAN${faker.number.int({ max: 999999, min: 100000 })}`,
      open_loan_type: faker.helpers.arrayElement([
        '',
        'netcredit',
        'netcredit_line_of_credit',
        'netcredit_bank_partnership',
        'netcredit_bank_partnership_tab',
        'netcredit_bank_partnership_tab_loc',
        'netcredit_bank_partnership_ccb',
        'netcredit_bank_partnership_ccb_loc',
      ]),
      show_fee_saver_status: faker.datatype.boolean(),
    },
    { status: 200 },
  )),
  installment: http.get(apiEndpoints.accountSummary(':accountId'), () => HttpResponse.json(
    {
      account_id: 12345,
      first_name: 'Jane',
      last_name: 'Doe',
      login: 'janedoe@gmail.com',
      open_loan_number: 'LOAN1234',
      open_loan_type: 'netcredit',
      show_fee_saver_status: true,
    },
    { status: 200 },
  )),
  lineOfCredit: http.get(apiEndpoints.accountSummary(':accountId'), () => HttpResponse.json(
    {
      account_id: 12345,
      first_name: 'John',
      last_name: 'Doe',
      login: 'johndoe@gmail.com',
      open_loan_number: 'LOAN1234',
      open_loan_type: 'netcredit_line_of_credit',
      show_fee_saver_status: true,
    },
    { status: 200 },
  )),
};
