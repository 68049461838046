import React from 'react';

import { AlertHeading, Alert as BoostrapAlert } from 'react-bootstrap';

import { Icon } from '../Icon';
import { AllowedIconNameT } from '../Icon/allowedIcons';

import '@consumer-bootstrap/components/alerts.scss';

export type AlertVariantT = 'warning' | 'info' | 'danger' | 'success';
export type AlertVariantIconNameMapT = { [key in AlertVariantT]: AllowedIconNameT; };
export const ALERT_VARIANT_ICON_NAME_MAP: AlertVariantIconNameMapT = {
  danger: 'exclamation-circle',
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
};

export type AlertPropsT = {
  dismissible: boolean;
  variant: AlertVariantT;
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  header?: React.ReactNode;
};
export type AlertT = React.FC<AlertPropsT>;

export const Alert: AlertT = ({
  children,
  className,
  dismissible,
  header,
  variant,
  'data-testid': testId,
}) => {
  return (
    <BoostrapAlert
      className={`d-flex ${className}`}
      dismissible={dismissible}
      variant={variant}
      data-testid={testId}
    >
      <div className="alert-icon-container">
        <Icon className="alert-icon" iconName={ALERT_VARIANT_ICON_NAME_MAP[variant]} />
      </div>
      <div>
        {header
          ? <AlertHeading as="div" className="h6">{header}</AlertHeading>
          : null}
        {children}
      </div>
    </BoostrapAlert>
  );
};
