export const installmentRBTFLAchWithoutAccount = `<html><head></head><body><div class="loan-contract installment-contract">

  <div class="article loan-agreement">

    <h2 class="contract-title">INSTALLMENT LOAN AGREEMENT</h2>

    <div class="section summary">
      <div class="entities">
            <div class="entity lender">
              </div></div><div class="entity borrower">
              </div><table class="table table-bordered">
        <tbody><tr>
          <td width="50%">
            <dl class="projected-disbursement-date"><dt>Projected Disbursement Date:</dt><dd>Nov 19, 2024</dd></dl>
          </td>
          <td style="vertical-align:middle">
            <dl class="account-num"><dt>Loan No.:</dt><dd>2024FL996348698</dd></dl>
            <dl class="account-num"><dt>Account ID:</dt>52147926</dl>
          </td>
        </tr>
        <tr>
          <td>
                <h3>CREDITOR:</h3>
                <div class="location">
                    <div class="company">Republic Bank &amp; Trust Company</div>
                    <address>
                      <span class="address-1">601 West Market Street</span><br>
		      <span class="city">Louisville,</span>
		      <span class="state">KY</span>
		      <span class="zip">40202</span><br>
                    </address>
                </div>
              </td>
            
            <td>
                <h3>BORROWER:</h3>
                <div class="location">
                    <div class="person">Johanna Tromp  </div>
                    <address>
                      <span class="address-1">leads_decision: staging_approved post_contract_mock: staging_approved ebs_uw_required:0 lender_odi_mock:staging_republic_bank, </span><br>
                      
                        <span class="address-2">1 </span><br>
                      
                      <span class="city">Bentonville, </span>
                      <span class="state">FL </span>
                      <span class="zip">24635 </span><br>
                      <div class="phone home">074-276-8921 </div>
                      <div class="phone work">880-747-3855 </div>
                    </address>
                </div>
              </td>
            
          
        </tr>
      </tbody></table>
    </div>

    <div class="section terms">
      <br>
      <p>
      This is a consumer credit transaction. This Installment Loan Agreement (“<b>Agreement</b>”) includes the disclosures above, the Federal Truth in Lending Act Disclosures below (“<b>TILA Disclosures</b>”) and the Additional Terms. In this Agreement, the following terms have the following meanings: “<b>you</b>,” “<b>your</b>” and “<b>I</b>” mean the Borrower named above. “<b>We</b>,” “<b>our</b>,” “<b>us</b>,” and “<b>Lender</b>” refer to Republic Bank &amp; Trust Company and/or its successors and assigns. “<b>Loan</b>” refers to the loan that Republic Bank &amp; Trust Company is making to you in this Agreement. “<b>Principal Amount</b>” refers to the total amount loaned to you, including any Origination Fee (as defined below).  “<b>Business Day</b>” means Monday through Friday, excepting Federal holidays.
      </p>
      <table class="table table-bordered">
        <tbody><tr>
      <td><u>HIGH COST CREDIT DISCLOSURE</u>. This Loan is an expensive form of credit. Other credit options may be less expensive.</td>
        </tr>
      </tbody></table>
      <p>
        <strong>THIS AGREEMENT INCLUDES AN ARBITRATION PROVISION. UNLESS YOU PROPERLY REJECT THE ARBITRATION PROVISION OR ARE A
        MEMBER OF THE ARMED FORCES OR A DEPENDENT OF SUCH A MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT, THE ARBITRATION
        PROVISION WILL HAVE A SUBSTANTIAL EFFECT ON YOUR RIGHTS IN THE EVENT OF A DISPUTE WITH US. FOR EXAMPLE, FOR ANY CLAIM
        SUBJECT TO ARBITRATION, YOU WILL NOT HAVE THE RIGHT TO A JURY TRIAL OR THE RIGHT TO PARTICIPATE IN A CLASS ACTION IN COURT
        OR IN ARBITRATION.</strong>
      </p>
      <br>
    </div>

    <div class="anchor" id="federal_truth_in_lending_act"> </div>
    <div class="section truth-in-lending-republic-bank panel panel-default" style="padding: 12px;" id="truth_in_lending">
      <div class="table-responsive">
        <h3><strong>FEDERAL TRUTH-IN-LENDING ACT DISCLOSURES</strong></h3>
        <table class="finance-charge table table-bordered">
          <tbody><tr>
            <th class="important">
              ANNUAL PERCENTAGE RATE ("APR")
              <span>The cost of your credit as a yearly rate.</span>
            </th>
            <th class="important">
              FINANCE CHARGE
              <span>The dollar amount the credit will cost you.</span>
            </th>
            <th>
              Amount Financed
              <span>The amount of credit provided to you or on your behalf.</span>
            </th>
            <th>
              Total of Payments
              <span>The amount you will have paid after you have made all payments as scheduled.</span>
            </th>
          </tr>
          <tr>
            <td class="cost-of-credit"><strong>35.51% (e) </strong></td>
            <td class="cost-of-credit"><strong>$4,538.01 (e) </strong></td>
            <td>$4,000.00</td>
            <td>$8,538.01 (e) </td>
          </tr>
        </tbody></table>
      </div>

     
     <div class="clause federal-disclosure" id="fed_disclosure" style="padding:5px;">
      <h3>Payment Schedule (e):</h3>
      <p>
      Your payment schedule will be 130 payments due every two weeks on Fridays, beginning Friday, Nov 29, 2024
      and ending Friday, Nov 09, 2029,
      
        except that if any of these days is not a Business Day, your payment will be due on the preceding Business Day.
      
      
        Each payment will be in the amount of $65.69, except for your last payment
        which will be in the amount of $64.00.
      
      </p>
      <p>
        <b>Late Charge:</b> If you fail to make all or any part of a scheduled installment payment within 15 days of its due date, you may incur a late charge of $25.00.
      </p>
      <p>
        <b>Prepayment:</b> If you payoff early in full or in part, you <br>
         ☐ may &nbsp;&nbsp;&nbsp;&nbsp; ☒ will not &nbsp;&nbsp;&nbsp;&nbsp; have to pay a penalty.
      </p>
      <p>
        <b>Contract Reference:</b> See your Agreement for any additional information about nonpayment, default, any required repayment in full before the scheduled date,
        and prepayment refunds and penalties.
      </p>
      <p>
        (e) means an estimate.
      </p>
    </div>
  </div>

  <div class="anchor" id="itemization_of_amount_financed"> </div>
  <div class="amount-itemization">
    <h3>ITEMIZATION OF AMOUNT FINANCED OF $4,000.00</h3>
      <ol>
        <li>
          <span class="value">Total Loan amount (Principal Amount):</span>
          <span class="value">$4,000.00</span>
        </li>
        <li>
          <span class="value">Prepaid finance charge (Origination Fee):</span>
	  <span class="value">$0.00</span>
        </li>
        <li>
          <span class="value">Amount given to you directly (1-2=3):</span>
          <span class="value">$4,000.00</span>
        </li>
      </ol>
    </div>
  </div>

  <h3 style="text-decoration: underline; text-align: center;">ADDITIONAL TERMS</h3>
  <p>
    <strong>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT:</strong> To help
    the government fight the funding of terrorism and money laundering activities, Federal law requires
    all financial institutions to obtain, verify, and record information that identifies each
    person who opens an account.  What this means for you: When you open an account, we will
    ask for your name, address, date of birth, government ID number, or other information
    that will allow us to identify you.  We may also ask to see your driver’s license or other
    identifying documents.
  </p>
  <p>
    Before we disburse Loan proceeds, we will seek to verify the information we have collected in
    connection with your request for a Loan. Such verification may require you to provide additional
    information to us. You agree that, in the event that we are unable to complete the verification
    to our satisfaction and the final approval of your Loan, we are not obligated to disburse Loan
    proceeds. If we do not disburse Loan proceeds, we will notify you at the e-mail address you provided to us.
  </p>
  <p>
    If we are able to complete the final approval of your Loan and verification to our satisfaction, we
    will use commercially reasonable efforts to disburse Loan proceeds by the Projected Disbursement Date
    stated above. Certain of the TILA Disclosures are estimated (as indicated by the (e)) and are based on
    our expectation that Loan proceeds will be disbursed to you on the Projected Disbursement Date set forth
    above. Banking delays, Federal holidays, or delays in making contact with you to verify the information
    you have provided to us may cause the Loan proceeds to be disbursed later than the Projected Disbursement Date
    noted above.
  </p>

  <div class="anchor" id="cancellation"> </div>
  <h3>CANCELLATION.</h3>
  <p>
    You have the right to cancel this Loan until 6:00 PM Eastern Time on the next Business Day immediately following
    the day we disburse the proceeds of your Loan (“<b>Cancellation Deadline</b>”). To cancel, you must inform us of your
    intent to cancel in writing no later than the Cancellation Deadline via fax at (855) 878-1068 or via e-mail at
    <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
    If the cancellation of your Loan is successful, all interest that has accrued and the
    Origination Fee, if any, will be waived and you will have no further payment obligations under this Agreement.
    If you seek to cancel, you authorize us to initiate an electronic fund transfer (“<b>EFT</b>”) debit transaction through
    the Automated Clearing House (“<b>ACH</b>”) network, to debit your Deposit Account (as defined below in the
    <a href="#electronic_fund_transfer">ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS</a>) for the amount given to you directly as specified
    in the <a href="#itemization_of_amount_financed">ITEMIZATION OF AMOUNT FINANCED</a> section above.
    <strong style="text-decoration:underline;">If we are not able to successfully debit your Deposit Account (through no fault of our own) or the debit
    attempt is returned unpaid, your Loan will not be canceled and your obligations under this Agreement will continue
    just as if you had not attempted to cancel.</strong>
  </p>

  <h3>SERVICING.</h3>
  <p>
    Any rights granted to us and any duties we have under this Agreement may be exercised or undertaken by servicers or
    other agents we designate. Unless and until we notify you otherwise, this Loan will be serviced by NetCredit Loan Services, LLC (“<b>NetCredit</b>”).
  </p>

  <h3>ASSIGNMENT.</h3>
  <p>
    We may sell, assign, or otherwise transfer this Loan and our rights under this Agreement and amounts owed by you to
    another creditor at any time. If we do so, this Agreement will remain in effect and will be binding on and will inure
    to the benefit of our assign(s). You may not transfer your rights under this Agreement. Any attempt to do so will be void.
  </p>

  <h3>PROMISE TO PAY AND LATE CHARGE.</h3>
  <p>
    You promise to pay us the Principal Amount of $4,000.00, plus interest, as described below, according to the Payment
    Schedule above (or any adjusted payment schedule subsequently agreed to by you and us), plus any other amounts you owe
    us under this Agreement.
  </p>
  <p>
    <em>Origination Fee and other Charges</em>. If there is a prepaid finance charge specified on line two (2) of the Itemization of
    Amount Financed above ("<b>Origination Fee</b>"), it is included in the Principal Amount of this Loan, it is fully earned as of
    the date of this Agreement, and it is non-refundable, unless you cancel this Loan in accordance with the <a href="#cancellation">CANCELLATION</a>
    section of this Agreement. Interest will accrue on the Origination Fee, if any, from the date Loan proceeds are disbursed.
    You also promise to pay the Origination Fee, if any, and any other charges provided for under this Agreement.
  </p>
  <p>
    <em>Interest</em>. We charge interest on your Loan. Interest accrues beginning on the later of (i) the Projected Disbursement Date;
    or (ii) the date Loan proceeds are disbursed and is accrued until the earlier of (i) the date that your Loan is paid in
    full or (ii) the scheduled due date of the final installment payment for your Loan or (iii) as of the date your Loan is
    accelerated pursuant to the <a href="#default_and_acceleration">DEFAULT AND ACCELERATION</a> provision of this Agreement.
  </p>
  <p>
    The interest on your Loan is calculated using an “adjusted daily simple interest method” at an annual rate of 35.68% (“<b>Contract Rate</b>”).
    The “<b>Daily Rate</b>” is the Contract Rate divided by 365. We calculate the interest on a daily basis by multiplying the
    Adjusted Principal Balance of your Loan by the Daily Rate. “<b>Adjusted Principal Balance</b>” means the Principal Amount of your
    Loan, minus any payments that have been applied to the Principal Amount, and minus any part of the Principal Amount that is past due.
    If the daily interest includes a fraction of a cent, the daily interest is rounded down to the nearest whole cent. Interest will not be
    payable before it accrues and will not be compounded. Interest does not accrue on late charges, but it accrues on the Origination Fee, if any.
  </p>
  <p>
    The “Finance Charge” stated in the TILA Disclosures above represents the Origination Fee, if any, and the amount of interest that you
    will pay under this Agreement if you receive your Loan proceeds on the Projected Disbursement date and you make all the scheduled
    installment payments on each due date. PLEASE NOTE: If the parties agree to an adjusted payment schedule, interest will accrue based
    on the adjusted payment schedule. However, you will not be required to pay more total interest than the Finance Charge, less
    the Origination Fee, if any, set forth in the TILA Disclosures above.
  </p>
  <p>
  <em>Payment Methods</em>. You may make payments on your Loan using any of the following permitted payment methods. We reserve the
  right to add or remove accepted payment methods at any time.
  </p><ul>
    <li>
      <u>Recurring ACH (EFT – AutoPay)</u>:
      
        You have indicated that you wish to make your payments via recurring electronic funds transfers
        debited from your Deposit Account. <b>You are not required to authorize us to initiate EFTs for your
        installment payments in order to qualify for a Loan.</b>
      
    </li>
    <li>
      <u>One-time ACH (EFT)</u>: You may authorize us to initiate a one-time electronic fund transfer debited from your Deposit Account via your
      online account with us or by calling us at (877) 392-2014.
    </li>
    <li>
      <u>Check or Money Order</u>:
      
        By mailing your payment to Republic Bank &amp; Trust Company, c/o NetCredit, P.O. Box 206766, Dallas, TX 75320-6766.
      
    </li>
    <li>
      <u>Other Payment Methods</u>: We may permit you to make payments using other payment methods from time to time. Please call us at (877) 392-2014 for more information.
    </li>
  </ul>
  <p>
    For more information, please see www.netcredit.com/faq under "Repayment."  The individual account ID and your name should be included
    with each payment— if we cannot identify you and your Loan, we may reject your payment. No correspondence should be included if you are
    mailing your payment. You may not pay any installment payment with a credit card.
  </p>
  <p>
    <em>Due Dates</em>. You must make each payment by the date it is due, according to your Payment Schedule. In order for your payment to be
    considered timely, it must be received by the cut-off time applicable to your method of payment.
    
      One-time ACH payments must be authorized by 1 PM Eastern Time on the day before your payment due date to be considered timely.
    
    Any payment not actually received by us will not be considered timely. We reserve the right to change the cut-off time for any
    payment method at any time. For the cut-off time applicable to your method of payment and any changes to these cutoff times,
    please visit <a href="https://www.netcredit.com/faq#how-to-repay-personal-loan" target="_blank">www.netcredit.com/faq</a>
    and review the “How can I repay my personal loan or line of credit?” section.
  </p>
  <p>
    <em>Late Charges</em>. You may incur a $25.00 late charge if you fail to make the entire amount of a scheduled installment payment within
    15 days of its due date. However, you will not incur a late charge if the delinquency is solely due to the non-payment of a prior late charge.
    If you incur any late charges, you agree to allow us to add the amount of such late charge to the amount of your next scheduled installment
    
      payment or to initiate a separate EFT or other payment for the late charge on or after the date of your next installment due date.
    
  </p>

  <h3>PREPAYMENT.</h3>
  <p>
    You have the right to prepay your Loan in full or in part at any time. You will not incur an additional charge, fee or penalty for prepayment.
    If you prepay in full, you will not be entitled to a refund of the Origination Fee, if any, unless you cancel the Loan as described above in the
    <a href="#cancellation" target="_blank">CANCELLATION</a> section of this Agreement. If you prepay in full or in part, you will not be entitled to any rebates. Prepayment of the Principal
    Amount may reduce the total amount of interest you pay under this Agreement, but a partial prepayment may not affect your obligation to make your
    next installment payment according to the Payment Schedule. Your prepayment will be applied as set forth in the <a href="#application_of_payments" target="_blank">APPLICATION OF PAYMENTS</a> section below.
    To process a partial or full prepayment or receive a payoff balance, you should call us at (877) 392-2014, or tell us of your intent to prepay in
    writing via fax at (855) 878-1068 or e-mail at support@netcredit.com. You may also make a prepayment by using any permitted payment method(s) listed
    above in the Payment Methods section of this Agreement. Unless otherwise specified, any payoff amount we provide will be calculated as of the date
    we expect to seek payment from your Deposit Account for the balance owing or such other date we indicate.
  </p>

  <div class="anchor" id="application_of_payments"> </div>
  <h3>APPLICATION OF PAYMENTS.</h3>
  <p>
    If your Loan is current, we credit all payments to the currently due installment by applying the payment first to fees (excluding any Origination Fee),
    second to interest, and third to the portion of the Principal Amount then due (including any Origination Fee). All payments are credited as of the date we receive the payment.
  </p>
  <p>
    If any amount you owe is past due, payments will be applied first to satisfy any past due fees (excluding any Origination Fee), second to past due interest,
    third to the portion of the Principal Amount that is past due (including any Origination Fee), fourth to currently due fees (excluding any Origination Fee),
    fifth to currently due interest and sixth to the portion of the Principal Amount that is currently due (including any Origination Fee).
  </p><p>
    If we receive an early payment at least two Business Days before your next due date, the payment will be applied to your next-due installment.
    If such early payment is less than the amount of the next-due installment, then you must pay the difference on or before your next due date.

    If you have authorized payments by AutoPay, we will debit the difference between the amount paid and the amount due on your next due date.
  </p>
  <p>
    If we receive a payment within two Business Days of that installment’s due date or on the due date, it may not reduce the amount of any AutoPay we initiate
    on the due date.

    For more details, please visit <a href="https://www.netcredit.com/faq#how-will-my-payment-be-applied" target="_blank">https://www.netcredit.com/faq#how-will-my-payment-be-applied</a>.
  </p>
  <p>
    Making an installment payment in excess of what is due may reduce the total amount of interest you are scheduled to pay on your Loan under this Agreement
    and the amount of your remaining payments (starting with your last payment and working backwards). For example, if you have twelve (12) installments and you
    make a double payment on your second installment due date, then your extra payment will be applied to the outstanding Principal Amount, and you will still
    owe a regular installment payment on your third due date; however, your extra payment will likely reduce the amount of interest you pay and may reduce or
    eliminate one or more installment payments beginning with your twelfth (12th) scheduled installment payment).
  </p>

  <h3>CREDIT REPORTING.</h3>
    <p>
      <strong>You authorize and instruct us to obtain credit and other reports about you from time to time for any permissible purpose, including, but not limited to, the origination and maintenance of your Loan.</strong>
    </p>
    <p>
      <strong>We may report information about your Loan to credit bureaus. Late payments, missed payments or other defaults on your Loan may be reflected in your credit report.</strong>
    </p>
    <p>
      <strong>You have the right to dispute the accuracy of information we have reported.  If you believe that any information about your Loan that we have furnished to a
      consumer reporting agency is inaccurate, or if you believe that you have been the victim of identity theft in connection with any Loan made by us, write to
      Republic Bank &amp; Trust Company, c/o NetCredit, Attn: Legal Department, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. In your letter (i) provide your
      name and Loan number, (ii) identify the specific information that is being disputed, (iii) explain the basis for the dispute and (iv) provide any supporting
      documentation you have that substantiates the basis of the dispute. If you believe that you have been the victim of identity theft, please also submit an identity
      theft report to the address specified above.</strong>
    </p>

  <div class="anchor" id="electronic_fund_transfer"> </div>
  <h2>ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS.</h2>
  <p>
    You authorize us to initiate an EFT to credit the checking account specified in your Loan application, or that you have previously identified to us or our servicer for
    purposes of receiving prior loan proceeds or making payments on a prior loan, or any substitute account you may later provide us (“<b>Deposit Account</b>”) with the proceeds
    of your Loan. In the event that an error is made in processing this EFT, you further authorize us to initiate an EFT to the Deposit Account to correct such error.
  </p>

  <div class="anchor" id="payment_authorization"> </div>
  
    <h2>PAYMENT AUTHORIZATION.</h2>
  

  
    <h4 style="text-decoration: underline;">Optional Nature of Authorization</h4>
    <p>
      You are not required to authorize us to initiate EFTs for your installment payments in order to qualify for a Loan. You have the option of arranging to make your installment
      payments by a method that does not involve EFTs. You agree that making your installment payments by EFT is for your convenience. By entering the last four digits of your
      Social Security Number, signing this Payment Authorization and clicking the “I AGREE” button below, you acknowledge that you are voluntarily choosing to make your installment payments by EFT.
    </p>
  

  <h4 style="text-decoration: underline;">
    
      EFT Authorization for Installment Payments, Late Charges, Cancellation, and Error Correction
    
  </h4>
  <p>
    You authorize us (which includes, for the purpose of this
    
      Payment
    
    Authorization, our servicers/service providers, successors and assigns and the servicers/service
    providers of our successors and assigns) to initiate an EFT through the ACH network (or another network of our choosing) to debit (or, in the case of an error,
    debit or credit, as applicable) your Deposit Account for the
    
      following amounts, on or after the date each such amount is due:
    
      </p><ul>
      
        <li>
          Each of the installment payments due under the Agreement, up to the amount specified in the Payment Schedule (or, if you make a partial installment payment
          prior to the cut-off time for your payment method for your scheduled due date, the amount of the installment less the amount of any such payment) plus any
          applicable late charges on or after its due date;
        </li>
        <li>
          Each late charge of $25.00, if any, which shall be due on the scheduled installment due date immediately following the date it is assessed;
        </li>
        <li>
          The final installment payment due, which may vary based upon payment history (including early payments or extra payments) during the course of the
          Agreement, on the final installment due date set forth in the Payment Schedule;
        </li>
      

      <li>
        In the event that you cancel the Loan as prescribed in the <a href="#cancellation" target="_blank">CANCELLATION</a> provision above, the entire amount disbursed directly to you as stated
        above in the <a href="#itemization_of_amount_financed">ITEMIZATION OF AMOUNT FINANCED</a> section, within two (2) Business Days of timely cancellation; and
      </li>
      <li>
        The amount required to correct any error in processing an EFT (or that we otherwise discover) on or after the date the error is discovered.
      </li>
    </ul>
  <p></p>
  <p>
    You agree that we may resubmit any EFT that is returned due to insufficient funds in your Deposit Account up to two additional times, unless
    prohibited by applicable law or network rules. We may submit any EFT returned for any other reason as permitted by applicable law and network rules.
    You acknowledge that the origination of EFT transactions to the Deposit Account must comply with applicable provisions of U.S. law.
  </p>

  <h4 style="text-decoration: underline;">
  
    Modification of Payment Authorization
  
  </h4>
  <p>
    Instead of or in addition to any of the EFTs described above, you may subsequently authorize us by phone, text message, e-mail, fax or by
    logging into the online Loan account to process one or more EFTs through the ACH network or another network of our choosing (including,
    for example, a payment card network) for payment on your Loan. If you, using any of the methods of contact described above, (i) specify
    the amount(s) and date(s) of the EFT(s); (ii) identify the Deposit Account from which the EFT(s) will be debited; and (iii) electronically
    sign an EFT authorization, then you authorize us to initiate EFTs from your Deposit Account in the amounts and on the dates that you specify
    (and, if necessary, to electronically correct any erroneous EFTs with debits or credits). All EFT transactions that you authorize must comply
    with applicable law and network rules.
  </p>

  <h4 style="text-decoration: underline;">Right to Stop Payment and Procedure for Doing So</h4>
  <p>
  
    You may terminate one or all of the authorizations to initiate EFTs from the Deposit Account set forth above
  
    by calling us at (877) 392-2014 or
    writing to us via fax at (855) 878-1068, by e-mail at support@netcredit.com, or by mail at Republic Bank &amp; Trust Company,
    c/o NetCredit, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604, in time for us to receive your request three (3) Business Days or more
    before the EFT is scheduled to be processed. If you call, we may also require you to confirm your stop payment order in writing and get it to us
    within fourteen (14) days after you call.
  </p>

  
    <p>
      In the event that you terminate this authorization, you will still be responsible for paying all amounts you owe us under this Agreement by using another permitted payment method.
    </p>
  

  <h4 style="text-decoration: underline;">Your Deposit Account Information</h4>
  <p>
    You confirm that you own the Deposit Account, that your Deposit Account is an open and active consumer deposit account held at a U.S. financial institution,
    and that your Deposit Account is in good standing. If there is any missing or erroneous information concerning your Deposit Account or the financial institution
    that holds it, then you authorize us to verify and correct such information. You have given us certain information about your Deposit Account so that, among other
    things, we can verify the account number and routing number. You are responsible for updating us if there are any changes to your Deposit Account or information
    relating to your Deposit Account that may affect your Loan.
  </p>
    <h4 style="text-decoration: underline;">Fees Your Financial Institution May Charge You for Rejected Payments</h4>
  <p>
    You understand that the financial institution holding your Deposit Account may impose additional fees in connection with returned or rejected EFTs, including when
    you have insufficient funds to cover a payment, and you agree that we do not have any liability regarding any such fees.
    <strong>IN THE EVENT THAT YOU DO NOT HAVE SUFFICIENT FUNDS IN YOUR DEPOSIT ACCOUNT TO COVER THE AMOUNT OF ANY EFT AUTHORIZED HERE, YOU SHOULD CONTACT US AT LEAST
    THREE (3) BUSINESS DAYS IN ADVANCE OF
    
      YOUR NEXT SCHEDULED DUE DATE
    
    SO THAT WE CAN STOP THE EFT AND ARRANGE FOR AN ALTERNATIVE METHOD OF REPAYMENT</strong>.
  </p>

  
    <h4 style="text-decoration: underline;">Range of Varying Amounts</h4>
    <p>
      Your scheduled installment payments provided in the Payment Schedule shown above will vary if: (1) you make partial payments; (2) make prepayments; and/or
      (3) you are assessed any late charges pursuant to the Agreement. The range of your scheduled installment payments will normally not vary by more than the amount
      necessary for such adjustments and will be between $0.01 and the amount indicated for each payment in the Payment Schedule of your TILA Disclosures above plus
      a late charge of $25.00. If your scheduled installment payment exceeds the amount of your installment set forth in the Payment Schedule, plus any applicable late
      charges due, we will send you notice at least ten (10) days in advance of the payment.
    </p>
    <p>
      You understand that you have the right to receive notice in advance of any payment amount that varies from that which is in your Payment Schedule.
      To receive advance notice of all varying amount payments, you may call us at (877) 392-2014, or write to us via e-mail at support@netcredit.com or fax at (855) 878-1068.
    </p>
  

  <h4 style="text-decoration: underline;">Full Force and Effect</h4>
  <p>
    
      This Payment Authorization will remain in full force and effect until the earlier of: (i) we receive full and final payment of the amounts you owe us
      under this Agreement; or (ii) we have received notification from you, as described above, of its termination. You must notify us at least
      three (3) Business Days in advance of your next scheduled due date, to afford us and the financial institution holding the Deposit Account
      a reasonable opportunity to act on your notice. If you do not notify us at least three (3) Business Days in advance of your next scheduled due date, the
      revocation of your payment authorization will be effective for your subsequent scheduled payment.
    
  </p>

  <div class="anchor" id="default_and_acceleration"> </div>
  <h3>DEFAULT AND ACCELERATION.</h3>
  <p>
    Subject to applicable law, which may require that we notify and/or give you a right to cure your default, we may declare you in default under this
    Agreement for any of the following reasons: (i) if you do not pay in full a payment within thirty (30) days of the due date set forth in the
    Payment Schedule or any adjusted payment schedule subsequently agreed to by you and us and confirmed by us in writing; (ii) if you violate any
    promise you make to us in this Agreement, which significantly impairs your ability to pay or our ability to collect the amounts due under this Agreement;
    (iii) if you file for protection under the United States Bankruptcy Code, or become subject to a proceeding which seeks relief from debt;
    (iv) if you have made a false or misleading statement about an important matter in connection with this Agreement, including your application
    for credit; or (v) you die or become legally incompetent.
  </p>
  <p>
    In the event of default, we may (i) declare the entire outstanding balance that you owe under this Agreement, and any other applicable charges
    (as permitted by law), immediately due and payable; (ii) proceed to reduce our claim to a judgment in accordance with applicable law;
    and (iii) exercise any other rights we have under this Agreement or the law. To the extent permitted by applicable law, you agree to pay
    our court costs, reasonable attorneys' fees and other collection costs related to the default.  PLEASE NOTE: Residents of Minnesota,
    Nebraska, Ohio, Oklahoma and Wyoming should see important terms regarding court costs, attorneys' fees and other collection costs below under "IMPORTANT NOTICES."
  </p>
  <p>
    By choosing any one or more of these remedies listed above, we do not give up our right to use another remedy later. By deciding not to
    use any remedy should you be in default, we do not give up our right to consider the event a default if it happens again. We may delay or
    refrain from enforcing any of our rights under this Agreement without waiving such rights. You understand that any waiver of our rights
    will not be effective unless it is in writing signed by us.
  </p>

  <h3>GOVERNING LAW AND INTERPRETATION.</h3>
    <p>
      <strong>Except as otherwise expressly provided in this Agreement, this Agreement (other than the Arbitration Provision) and all related disputes are governed
      by applicable federal law and the laws of the Commonwealth of Kentucky, without regard to its conflict of law provisions.</strong>
    </p>
    <p>
      <strong>Except as provided in the Arbitration Provision, if any provision of this Agreement is determined to be void, invalid or unenforceable under
      any applicable law, rule or regulation, it shall not affect the validity or enforceability of any other provision of this Agreement and such other
      provisions will remain valid and enforceable.</strong>
    </p>
    <p>
      <strong><u>Kansas and Wyoming Residents Only</u>: If you are a resident of Kansas or Wyoming, this Agreement is governed by the laws of Kansas or Wyoming,
      respectively, except to the extent preempted by federal law.</strong>
    </p>

  <h3>CORRESPONDENCE.</h3>
  <p>
    All notices to us must be sent to Republic Bank &amp; Trust Company, c/o NetCredit, Attn: Legal Department, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604.
    To the extent permitted under applicable law, any notice you send us will not be effective until we receive and have had a reasonable opportunity to act
    on such notice. Any written or electronic correspondence we send to you will be effective and deemed delivered when sent in accordance with any authorization
    for electronic communications you execute or mailed to you at your mailing address, as it appears on our records.
  </p>

  <h3>BANKRUPTCY.</h3>
  <p>
    All bankruptcy notices and related correspondence to us must be sent to us at Republic Bank &amp; Trust Company, c/o NetCredit, Attn: Legal Department,
    175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. You promise that you have no current intent to file any bankruptcy petition and have not consulted
    a bankruptcy attorney in the past six (6) months.
  </p>

  <h3>CHANGE IN CIRCUMSTANCES.</h3>
  <p>
    We will rely on your mailing and e-mail addresses as they appear on our records for any and all communications we send you by mail or e-mail unless and
    until either you or, in the case of your mailing address, the U.S. Postal Service, notifies us of a change of address and we have had a reasonable opportunity
    to act on such notice. You agree to notify us of any changes to your name, mailing or e-mail address, or phone number (including your cell phone number)
    within fifteen (15) days by writing us at the Notice Address or going on www.netcredit.com (“<b>Website</b>”).
  </p>

  <h3>PARTIAL PAYMENTS MARKED PAYMENT IN FULL; SETTLEMENTS.</h3>
  <p>
    Any check or other payment you send us for less than the total outstanding balance due under this Agreement that is marked “payment in full” or with any
    similar language or that you otherwise tender as full satisfaction of a disputed amount must be sent to Republic Bank &amp; Trust Company, c/o NetCredit,
    P.O. Box 206766, Dallas, TX 75320-6766. We may deposit any such payment without such deposit effecting a satisfaction of the disputed amount. Any settlement
    of your account balance for less than what is owed requires our written agreement.
  </p>

  <h3>INADVERTENT OVERCHARGES.</h3>
  <p>
    It is not our intention to charge any interest, fees or other amounts in excess of those permitted by applicable law or this Agreement. If any interest,
    fee or other amount is finally determined to be in excess of that permitted by applicable law or this Agreement, the excess amount will be applied to
    reduce the outstanding balance due under this Agreement or, if there is no outstanding balance, will be refunded to you.
  </p>

  <h3>CONTACTING YOU; PHONE AND TEXT MESSAGES; CALL RECORDING.</h3>
  <p>
    You authorize us and our successors and assigns and the affiliates, agents, representatives, assigns and servicers/service providers of ours and our successors and
    assigns (collectively, the “<b>Messaging Parties</b>”) to contact you using automatic telephone dialing systems, artificial or prerecorded voice message systems, text
    messaging systems and automated e-mail systems in order to provide you with information about this Agreement, including information about upcoming payment due dates,
    missed payments and returned payments. You authorize the Messaging Parties to make such contacts using any telephone numbers (including wireless, landline and VOIP
    numbers) or e-mail addresses you supply to the Messaging Parties in connection with this Agreement, the Messaging Parties' servicing and/or collection of amounts
    you owe the Messaging Parties or any other matter.
  </p>
  <p>
    You understand that anyone with access to your telephone or e-mail account may listen to or read the messages the Messaging Parties leave or send you, and you agree
    that the Messaging Parties will have no liability for anyone accessing such messages. You further understand that, when you receive a telephone call, text message
    or e-mail, you may incur a charge from the company that provides you with telecommunications, wireless and/or Internet services, and you agree that the Messaging
    Parties will have no liability for such charges. You expressly authorize the Messaging Parties to monitor and record your calls with the Messaging Parties. You agree
    that this authorization is part of a bargained-for exchange. To the extent you have the right under applicable law to revoke your authorization, you agree you may
    only do so as to text messages by replying “STOP” to a text message we send you, and as to the calls authorized above by calling customer service at (877) 392-2014.
  </p>

  <h3>MILITARY LENDING ACT.</h3>
      <h3 style="text-decoration: underline;">General</h3>
      <p>
        <strong>The Military Lending Act provides important protections to certain members of the Armed Forces and their dependents (“Covered Borrowers”)
        relating to extensions of consumer credit. The provisions of this section apply to Covered Borrowers. If you would like more information
        about whether you are a Covered Borrower and whether this section applies to you, please contact us at (877) 392-2014.</strong>
      </p>
      <h3 style="text-decoration: underline;">Statement of MAPR</h3>
      <p>
      <strong>Federal law provides important protections to members of the Armed Forces and their dependents relating to extensions of consumer credit.
        In general, the cost of consumer credit to a member of the Armed Forces and his or her dependent may not exceed an Annual Percentage Rate of
        36 percent. This rate must include, as applicable to the credit transaction or account: (1) the costs associated with credit insurance premiums;
        (2) fees for ancillary products sold in connection with the credit transaction; (3) any application fee charged (other than certain application
        fees for specific credit transactions or accounts); and (4) any participation fee charged (other than certain participation fees for a credit card account).</strong>
      </p>
      <h3 style="text-decoration: underline;">Oral Disclosures</h3>
      <p>
        <strong>If you are a Covered Borrower, please call (877) 392-2014 before signing this Agreement for an oral disclosure of the statement of the
        military APR applicable to your Loan and a description of your payment obligation.</strong>
      </p>
      <h3 style="text-decoration: underline;">Covered Borrowers Savings Clause</h3>
      <p>
      <strong>The provisions of this paragraph apply only to a “Covered Borrower” as that term is defined by 32 C.F.R. § 232.3(g). If any contract provision
        not identified herein is contrary to the rights and protections afforded to you by federal law pursuant to 10 U.S.C. § 987 and its implementing regulations,
        including, but not limited to 32 C.F.R. § 232.8, then the conflicting provisions or proscribed terms are inoperative, and shall have no force and effect.
       However, all remaining contract terms and provisions not proscribed or prohibited shall remain in full force and effect.</strong>
      </p>

    <h3>MISCELLANEOUS.</h3>
    <p>
      The section headings used in this Agreement are for convenience of reference only and do not in any way limit or define your or our rights or obligations hereunder.
      Except as otherwise provided in this Agreement or as required by applicable law, we will not be responsible for any claim or defense you may have against any third
      party that arises out of or in connection with this Agreement.
    </p>

    <div class="anchor" id="waiver_of_jury_trial"> </div>
    <h2>WAIVER OF JURY TRIAL.</h2>
    <p>
      Unless prohibited by applicable law, if you sign this Agreement, you waive your right to have a jury trial to resolve any dispute you may have against us or a related third party. This Waiver of
      Jury Trial provision does not apply to you if, at the time of entering into this Agreement, you are a resident of Arkansas or a member of the armed forces or a dependent of such a member covered
      by the Federal Military Lending Act.
    </p>

    <div class="anchor" id="class_action_waiver"> </div>
    <h3>CLASS ACTION WAIVER.</h3>
      <p>
        Unless prohibited by applicable law, if you sign this Agreement, you waive your right to ask a court or an arbitrator to allow you to pursue any
        claims on a class action basis or in a representative capacity on behalf of the general public, other borrowers or other persons similarly situated.
        Furthermore, claims brought by you against us or by us against you may not be joined or consolidated with claims brought by or against someone other
        than you, unless otherwise agreed to in writing by all parties. For purposes of this Class Action Waiver, the terms “<b>we</b>,” “<b>us</b>” and “<b>our</b>” shall also
        include Republic Bank &amp; Trust Company and each of its employees, directors, officers, shareholders, governors, managers, members, parent companies,
        affiliated entities, successors or assignees (including but not limited to NetCredit), the servicer of your Loan (including but not limited to NetCredit)
        and any third party who is named as a co-defendant with us in a claim asserted by you. In the event of a conflict between this <a href="#class_action_waiver" target="_blank">CLASS ACTION WAIVER</a>
        provision and the <a href="#arbitration_provision" target="_blank">ARBITRATION PROVISION</a>, the <a href="#arbitration_provision" target="_blank">ARBITRATION PROVISION</a> shall control. This <a href="#class_action_waiver" target="_blank">CLASS ACTION WAIVER</a> does not apply to you if, at the time of
        entering into this Agreement, you are a Covered Borrower under the Military Lending Act.
      </p>

      <div class="anchor" id="arbitration_provision"> </div>
      <h3>ARBITRATION PROVISION.</h3>
      <p>
        Arbitration is a process in which persons with a dispute(s): (a) agree to submit their dispute(s) to a neutral third person (an "<b>arbitrator</b>")
        for a decision; and (b) waive their rights to file a lawsuit in court to resolve their dispute(s). Each party to the dispute(s) has an opportunity
        to present some evidence to the arbitrator. Pre-arbitration discovery may be limited. Arbitration proceedings are private and less formal than court
        trials. The arbitrator will issue a final and binding decision resolving the dispute(s), which may be enforced as a court judgment. A court rarely
        overturns an arbitrator's decision.
      </p>

      <p>
        <strong>THIS ARBITRATION PROVISION APPLIES UNLESS (1) YOU HAVE EXERCISED OR EXERCISE YOUR RIGHT TO REJECT ARBITRATION OR (2) AS OF THE DATE OF THIS AGREEMENT,
        YOU ARE A MEMBER OF THE ARMED FORCES OR A DEPENDENT OF SUCH MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT. IF YOU WOULD LIKE MORE INFORMATION ABOUT
        WHETHER YOU ARE COVERED BY THE MILITARY LENDING ACT, IN WHICH CASE THIS ARBITRATION PROVISION DOES NOT APPLY TO YOU, PLEASE CONTACT US AT (877) 392-2014.</strong>
      </p>
      <p>
        <strong>READ THIS ARBITRATION PROVISION CAREFULLY AS IT WILL HAVE A SUBSTANTIAL IMPACT ON HOW LEGAL CLAIMS YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED.</strong>
      </p>
      <p>
        <strong>YOU HAVE THE RIGHT TO OPT-OUT OF (NOT BE BOUND BY) THIS ARBITRATION PROVISION AS DESCRIBED BELOW. IF YOU DO NOT DO SO:</strong>
        </p><ol type="a">
          <li>
            <strong>YOU ARE WAIVING YOUR RIGHT TO HAVE A JURY OR A COURT, OTHER THAN A SMALL CLAIMS COURT OR SIMILAR COURT OF LIMITED JURISDICTION, RESOLVE ANY CLAIM THAT
            IS SUBJECT TO ARBITRATION; and</strong>
          </li>
          <li>
            <strong>YOU ARE WAIVING YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, AND/OR TO PARTICIPATE
            AS A MEMBER OF A CLASS OF CLAIMANTS, IN COURT OR IN ARBITRATION WITH RESPECT TO ANY CLAIM THAT IS SUBJECT TO ARBITRATION.</strong>
          </li>
        </ol>
      <p></p>


      <p>THEREFORE, YOU ACKNOWLEDGE AND AGREE AS FOLLOWS:</p>

      <h3 style="text-decoration: underline;">Interstate Commerce</h3>
      <p>
        This Arbitration Provision is made pursuant to a transaction involving interstate commerce and shall be governed by the Federal Arbitration Act (“<b>FAA</b>”),
        9 U.S.C. § 1 et seq., and not by any state arbitration law.
      </p>

      <h3 style="text-decoration: underline;">Scope</h3>
      <p>
        For purposes of this Arbitration Provision, the word “<b>Claim</b>” has the broadest possible meaning and includes, without limitation (a) all federal or state law claims,
        disputes or controversies, whether preexisting, present or future, arising from or relating directly or indirectly to this Agreement, the relationship between you and us,
        the information you gave us before entering into this Agreement, including your application, and/or any past agreement or agreements between you and us, any loan or credit
        product or related product or services obtained from us and any advice, recommendations, solicitations, communications, disclosures, promotions or advertisements concerning
        the same; (b) all initial claims, counterclaims, cross-claims and third-party claims and claims which arose before the effective date of this Arbitration Provision; (c) all
        common law claims based upon contract, tort, fraud, or other intentional torts; (d) all claims based upon a violation of any local, state or federal constitution, statute,
        ordinance or regulation, including without limitation all claims alleging unfair, deceptive or unconscionable trade practices; (e) all claims asserted by us against you,
        including claims for money damages to collect any sum we claim you owe us; (f) all claims asserted by you individually against us and/or any of our employees, directors,
        officers, shareholders, governors, managers, members, parent company or affiliated entities, or assignees (including but not limited to NetCredit) or against the servicer
        of your Loan (including but not limited to NetCredit) (hereinafter collectively referred to as "<b>related third parties</b>"), including claims for money damages, restitution
        and/or equitable or injunctive relief; (g) claims asserted by you against other persons and entities if you assert a Claim against such other persons and entities in
        connection with a Claim you assert against us or related third parties; and (h) all data breach or privacy claims arising from or relating directly or indirectly to the
        disclosure by us or related third parties of any non-public personal information about you. Notwithstanding the foregoing, the word “Claim” does not include any dispute or
        controversy about the validity, enforceability, coverage or scope of this Arbitration Provision or any part thereof (including, without limitation, the provision titled
        "Class Action Waiver" set forth below, subparts A and B of the provision titled "Binding Effect; Survival; Severability" set forth below and/or this sentence); all such
        disputes or controversies are for a court and not an arbitrator to decide. However, any dispute or controversy that concerns the validity or enforceability of the
        Agreement as a whole is for the arbitrator, not a court, to decide. In addition, this Arbitration Provision will not apply to (1) any individual action brought by
        you in small claims court or your state’s equivalent court, unless such action is transferred, removed, or appealed to a different court; or (2) any disputes that are the
        subject of a class action filed in court that is pending as of the effective date of this Arbitration Provision in which you are alleged to be a member of the putative
        class for as long as such class action is pending.
      </p>

      <h3 style="text-decoration: underline;">Class Action Waiver</h3>
      <p>
        <strong>Notwithstanding any other provision of this Arbitration Provision, if either you or we elect to arbitrate a Claim, neither you nor we will have the right: (a) to
        participate in a class action, private attorney general action or other representative action in court or in arbitration, either as a class representative or class
        member, or (b) to join or consolidate Claims with Claims of any other persons. (Provided, however, that the Class Action Waiver does not apply to any lawsuit or
        administrative proceeding filed against us by a state or federal government agency even when such agency is seeking relief on behalf of a class of borrowers
        including you. This means that we will not have the right to compel arbitration of any claims brought by such an agency). An award in arbitration shall determine the
        rights and obligations of the named parties only, and only with respect to the Claim(s) in arbitration, and shall not (i) determine the rights, obligations, or interests
        of anyone other than a named party, or resolve any Claim of anyone other than a named party; nor (ii) make an award for the benefit of, or against, anyone other than a
        named party. No arbitration administrator or arbitrator shall have the power or authority to waive, modify, or fail to enforce this section, and any attempt to do so,
        whether by rule, policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any challenge to the validity of this Class Action Waiver shall be
	determined exclusively by a court and not by the administrator or any arbitrator.</strong>
      </p>
        <h3 style="text-decoration: underline;">Electing Arbitration</h3>
      <p>
        A party may elect arbitration of a Claim by sending the other party written notice by certified mail return receipt requested of their intent to arbitrate and setting
        forth the subject of the dispute along with the relief requested. Your notice must be sent to NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600,
        Chicago, IL 60604, and our notice must be sent to the most recent address for you in our files. If a lawsuit concerning the Claim has been filed, such notice can be
        provided by papers filed in the lawsuit, such as a motion to compel arbitration.
      </p>
      <h3 style="text-decoration: underline;">Administrator</h3>
      <p>
        Regardless of who demands arbitration, you shall have the right to select either of the following arbitration organizations to administer the arbitration: the American
        Arbitration Association, 120 Broadway, Floor 21, New York, NY 10271 (1-800-778-7879), <a href="http://www.adr.org" target="_blank">http://www.adr.org</a>; or JAMS, 8401 N. Central Expressway, Suite 610, Dallas, TX 75225
        (1-800-352-5267), <a href="http://jamsadr.com" target="_blank">http://jamsadr.com</a>. However, the parties may agree to a local arbitrator who is an attorney, retired judge, or arbitrator registered and in good standing
        with a bona fide arbitration association and arbitrate pursuant to the arbitrator’s rules. If the AAA and JAMS are unable or unwilling to serve as administrator, or the
        parties are unable to agree upon another administrator, a court with jurisdiction shall determine the administrator or arbitrator. The arbitration hearing will take place
        at a location reasonably convenient to where you reside.
      </p>
      <p>
        If you demand arbitration, you must inform us in your demand of the arbitration organization you have selected or whether you desire to select a local arbitrator. If related
        third parties or we demand arbitration, you must notify us within 20 days in writing by certified mail, return receipt requested, of your decision to select an arbitration
        organization or your desire to select a local arbitrator. Your notice must be sent to NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604.
        If you fail to notify us, then we have the right to select an arbitration organization. The arbitration will be governed by the rules and procedures of this arbitration
        organization applicable to individual consumer disputes. You may get a copy of the rules and procedures by contacting the arbitration organization listed above. In an event
        of a conflict between the provisions of the Arbitration Provision, on the one hand, and any applicable rules of the AAA or JAMS or other administrator used or any other terms
        of this Agreement, on the other hand, the provisions of this Arbitration Provision shall control. If a party files a lawsuit in court asserting Claim(s) that are subject to
        arbitration and the other party files a motion to compel arbitration with the court which is granted, it will be the responsibility of the party prosecuting the Claim(s) to
        select an arbitration administrator in accordance with this paragraph and commence the arbitration proceeding in accordance with the administrator’s rules and procedures.
      </p>
        <h3 style="text-decoration: underline;">Non-Waiver</h3>
      <p>
        Even if all parties have opted to litigate a Claim in court, you or we may elect arbitration with respect to any Claim made by a new party or any Claim later asserted by a party
        in that or any related or unrelated lawsuit (including a Claim initially asserted on an individual basis but modified to be asserted on a class, representative or multi-party basis).
        Nothing in that litigation shall constitute a waiver of any rights under this Arbitration Provision. For example, if we file a lawsuit against you in court to recover amounts due
        under the Agreement, you have the right to request arbitration, but if you do not elect to request arbitration, we reserve and do not waive the right to request arbitration of
        any Claim (including any counterclaim) you later assert against us in that or any related or unrelated lawsuit. This Arbitration Provision will apply to all Claims, even if the
        facts and circumstances giving rise to the Claims existed before the effective date of this Arbitration Provision.
      </p>
        <h3 style="text-decoration: underline;">Arbitrator and Award</h3>
      <p>
        Any arbitrator must be a practicing attorney with ten or more years of experience or a retired judge. The arbitrator will not be bound by judicial rules of procedure or evidence
        that would apply in a court, nor by state or local laws that relate to arbitration proceedings. The arbitrator will apply the same statutes of limitations and privileges that a
        court would apply if the matter were pending in court. The arbitrator may decide, with or without a hearing, any motion that is substantially similar to a motion to dismiss for
        failure to state a claim or a motion for summary judgment. In determining liability or awarding damages or other relief, the arbitrator will follow the applicable substantive law,
        consistent with the FAA, which would apply if the matter had been brought in court. The arbitrator may award any damages or other relief or remedies that would apply under applicable
        law to an individual action brought in court, including, without limitation, punitive damages (which shall be governed by the Constitutional standards employed by the courts) and
        injunctive, equitable and declaratory relief (but only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s
        individual claim). No arbitration award involving the parties will have any preclusive effect as to issues or claims in any dispute involving anyone who is not a party to the
        arbitration, nor will an arbitration award in prior disputes involving other parties have preclusive effect in an arbitration between the parties to this Arbitration Provision.
      </p>
        <h3 style="text-decoration: underline;">Fees and Costs</h3>
      <p>
        At your written request, we will pay all filing, hearing and/or other fees charged by the administrator and arbitrator to you for Claim(s) asserted by you in an individual
        arbitration after you have paid an amount equivalent to the fee, if any, for filing such Claim(s) in state or federal court (whichever is less) in the judicial district in
        which you reside. (If you have already paid a filing fee for asserting the Claim(s) in court, you will not be required to pay that amount again). We will not seek reimbursement
        of such fees from you even if we prevail in the arbitration. In addition, the administrator may have a procedure whereby you can seek a waiver of fees charged to you by the
        administrator and arbitrator. We will always pay any fees or expenses that we are required to pay by law or the administrator’s rules or that we are required to pay for this
        Arbitration Provision to be enforced. The arbitrator will have the authority to award fees and costs of attorneys, witnesses and experts to the extent permitted by this Agreement,
        the administrator’s rules or applicable law. With respect to Claim(s) asserted by you in an individual arbitration, we will pay your reasonable attorney, witness and expert fees
        and costs if and to the extent you prevail, if applicable law requires us to or if we must bear such fees and costs in order for this Arbitration Provision to be enforced.
        At the timely request of either party, the arbitrator shall write a brief explanation of the grounds for the decision.
      </p>
        <h3 style="text-decoration: underline;">Appeal</h3>
      <p>
        The arbitrator’s award shall be final and binding on all parties, except for any right of appeal provided by the FAA. However, if the amount in controversy exceeds $50,000,
        and if permitted by the Administrator’s rules, you or we can, within 14 days after the entry of the award by the arbitrator, appeal the award to a three-arbitrator panel
        administered by the Administrator. The panel shall reconsider anew any aspect of the initial award requested by the appealing party. The decision of the panel shall be by
        majority vote. Reference in this Arbitration Provision to “the arbitrator” shall mean the panel if an appeal of the arbitrator’s decision has been taken. The costs of such
        an appeal will be borne in accordance with the paragraph above titled “Fees and Costs.” Any final decision of the appeal is subject to judicial review only as provided
        under the FAA. A judgment on the award may be entered by any court having jurisdiction.
      </p>
        <h3 style="text-decoration: underline;">Notice and Cure; Special Payment</h3>
      <p>
        Prior to initiating a Claim, you may send us a written Claim Notice. In order for a Claim Notice to be valid and effective, it must: (a) state your name, address and
        loan number; (b) be signed by you; (c) describe the basis of your Claim and the amount you would accept to resolve the Claim; (d) state that you are exercising your
        rights under the “Notice and Cure” paragraph of the Arbitration Provision; and (e) be sent to us by certified mail, return receipt requested, at NetCredit, Attn: General Counsel,
        175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. This is the sole and only method by which you can submit a Claim Notice. Upon receipt of a Claim Notice, we will credit
        you for the standard cost of a certified letter. You must give us a reasonable opportunity, not less than 30 days, to resolve the Claim. If, and only if, (i) you submit a
        Claim Notice in accordance with this paragraph on your own behalf (and not on behalf of any other party); (ii) you cooperate with us by promptly providing the information
        we reasonably request; (iii) we refuse to provide you with the relief you request before an arbitrator is appointed; and (iv) the matter then proceeds to arbitration and
        the arbitrator subsequently determines that you were entitled to such relief (or greater relief), you will be entitled to a minimum award of at least $7,500 (not including
        any arbitration fees and attorneys’ fees and costs to which you will also be entitled). We encourage you to address all Claims you have in a single Claim Notice and/or a
        single arbitration. Accordingly, this $7,500 minimum award is a single award that applies to all Claims you have asserted or could have asserted in the arbitration, and
        multiple awards of $7,500 are not contemplated.
      </p>
        <h3 style="text-decoration: underline;">Binding Effect; Survival; Severability</h3>
      <p>
        This Arbitration Provision is binding upon and benefits you, your respective heirs, successors and assigns. The Arbitration Provision is binding upon and
        benefits us, our successors and assigns, and related third parties. The Arbitration Provision shall survive any bankruptcy to the extent consistent with
        applicable bankruptcy law. The Arbitration Provision survives any termination, amendment, expiration or performance of this Agreement and any transaction
        between you and us and continues in full force and effect unless you and we otherwise agree in writing. If any part of this Arbitration Provision is deemed
        or found to be unenforceable for any reason, the remainder shall be enforceable, except as follows:
        </p><ol type="A">
          <li>
            The parties to this Arbitration Provision acknowledge that the provision titled "Class Action Waiver" is material and essential to the arbitration of any
            disputes between the parties and is non-severable from this Arbitration Provision. If the Class Action Waiver is limited, voided or found unenforceable,
            then the parties' Arbitration Provision (except for this sentence) shall be null and void with respect to such proceeding, subject to the right to appeal
            the limitation or invalidation of the Class Action Waiver. The parties acknowledge and agree that under no circumstances will a class action be arbitrated.
          </li>
          <li>
            If a Claim is brought seeking public injunctive relief and a court determines that the restrictions in the provision titled "Class Action Waiver" prohibiting the
            arbitrator from awarding relief on behalf of third parties are unenforceable with respect to such Claim (and that determination becomes final after all appeals
            have been exhausted), the Claim for public injunctive relief will be determined in court and any individual Claims seeking monetary relief will be arbitrated.
            In such a case the parties will request that the court stay the Claim for public injunctive relief until the arbitration award pertaining to individual relief
            has been entered in court. In no event will a Claim for public injunctive relief be arbitrated.
          </li>
        </ol>
      <p></p>
      <h3 style="text-decoration: underline;">Opt-Out Process</h3>
      <p>
        You may choose to opt out of this Arbitration Provision but only by following the process set forth below. If you do not wish to be subject to this Arbitration Provision,
        then you must notify us in writing postmarked within sixty (60) calendar days of the date of this Agreement at the following address: NetCredit, Attn: General Counsel,
        175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. Your notice must be sent to us by certified mail, return receipt requested. Upon receipt of an Opt-Out Notice,
        we will credit you for the standard cost of a certified letter. Your Opt-Out Notice must include your name, address, Social Security number, the date of this Agreement,
        a statement that you wish to opt out of the Arbitration Provision and must not be sent with any other correspondence. Indicating your desire to opt out of this
        Arbitration Provision will not affect your other rights or responsibilities under this Agreement and applies only to this Arbitration Provision between you and us.
      </p>

      <h3>REASONABLE ACCOMMODATION.</h3>
      <p>
        Individuals with disabilities who require an accommodation to access our products or services should contact us via email at support@netcredit.com; by telephone
        at (877) 392-2014; or by mail at Republic Bank &amp; Trust Company, c/o NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604.
        Please note that we may need advance notice to provide certain accommodations.
      </p>

      <h3>IMPORTANT NOTICES.</h3>

      <h4 style="text-decoration: underline;">All Borrowers</h4>
      <p>
        You agree that we may obtain your credit report in connection with any transaction, or extension of credit, and on an ongoing basis, for the purpose of reviewing this
        Agreement, taking collection action on this Agreement, or for any other legitimate purposes associated with this Agreement. Upon your request, you will be informed of
        whether or not a consumer credit report was ordered, and if it was, you will be given the name and address of the consumer reporting agency that furnished the report.
        <strong>As required by law, you are hereby notified that a negative credit report reflecting on your credit record may be submitted to a credit reporting agency if you
        fail to fulfill the terms of your credit obligations.</strong>
      </p>

      <h4 style="text-decoration: underline;">All Borrowers</h4>
      <p>
        <strong>ORAL AGREEMENTS OR ORAL COMMITMENTS TO LOAN MONEY, EXTEND CREDIT, OR TO FORBEAR FROM ENFORCING REPAYMENT OF A DEBT INCLUDING PROMISES TO EXTEND OR RENEW SUCH DEBT
        MAY NOT BE ENFORCEABLE UNDER THE APPLICABLE LAW OF THE STATE WHERE YOU RESIDE. TO PROTECT YOU (AS THE BORROWER) AND US (AS THE LENDER) FROM MISUNDERSTANDING OR
        DISAPPOINTMENT, ANY AGREEMENTS WE REACH COVERING SUCH MATTERS CONTAINED IN THIS WRITING, WHICH IS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US,
        EXCEPT AS WE MAY LATER AGREE IN WRITING TO MODIFY IT. YOU (AS BORROWER) AND WE (AS LENDER) ACKNOWLEDGE AND AGREE THAT THERE ARE NO UNWRITTEN OR ORAL AGREEMENTS BETWEEN YOU
        AND US; (2) THAT THIS WRITTEN AGREEMENT IS A FINAL EXPRESSION OF THE AGREEMENT BETWEEN YOU AND US; AND (3) THAT THIS AGREEMENT MAY NOT BE CONTRADICTED BY EVIDENCE OF
        PRIOR, CONTEMPORANEOUS, OR SUBSEQUENT ORAL AGREEMENTS OF THE PARTIES. THE TERMS OF THIS AGREEMENT MAY NOT BE CHANGED EXCEPT IN A WRITING SIGNED BY YOU AND US. NO CHANGE
        SHALL RELEASE ANY PARTY FROM LIABILITY UNLESS OTHERWISE EXPRESSLY STATED IN WRITING.</strong>
      </p>
      <p>
        <strong>ALL OF OUR RIGHTS ARE CUMULATIVE. IF WE APPROVE THIS AGREEMENT, THEN YOU AGREE THAT THIS AGREEMENT WILL BE BINDING AND ENFORCEABLE AS TO BOTH PARTIES.</strong>
      </p>

      <h4 style="text-decoration: underline;">Florida Residents</h4>
      <p>
        Florida documentary stamp tax required by law in the amount of $14.00 has been paid or will be paid directly by us to the Department of Revenue. Certificate of Registration No. 61-8000075542-6.
      </p>

      <h4 style="text-decoration: underline;">Minnesota, Nebraska, Ohio, Oklahoma and Wyoming Residents</h4>
      <p>
        No provision(s) in this Agreement providing for the payment of attorneys’ fees, court costs or collection costs by you shall apply.
      </p>

      <h4 style="text-decoration: underline;">New Jersey Residents</h4>
      <p>
        (1) The section headings of the Agreement are a table of contents and not contract terms. (2) You agree to pay our reasonable attorney's fees, up to 20% of outstanding
        principal and interest, paid in the collection of this Account to an attorney who is not our employee. (3) Provisions of this Agreement that refer to acts or practices
        that apply as permitted by, or except as prohibited by, applicable law are applicable to New Jersey residents only to the extent that such acts or practices are permitted
        by New Jersey or Federal law.
      </p>

      <h4 style="text-decoration: underline;">Ohio Residents</h4>
      <p>
      Ohio laws against discrimination require that all creditors make credit equally available to all creditworthy customers and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights Commission administers compliance with this law.
      </p>

  <div class="article electronic-consent" id="contract-signature">
    <h2>BANK ACCOUNT INFORMATION, ELECTRONIC SIGNATURES AND CONSENTS.</h2>
    <p>By entering your bank account information, clicking the "I AGREE" button below and providing any additional information as may be requested below, you understand and acknowledge that you are taking the following four (4) separate actions:</p>

    <div class="section ach-authorization panel panel-default clearfix signature-form">
  <h3 class="panel-heading">1. SUBMISSION OF YOUR BANK ACCOUNT INFORMATION</h3>
  <div class="sig-form-copy panel-body bank-account-information">
     <p id="bank_information_script">Enter the personal checking account where the majority of your income
    is deposited. This is also the Deposit Account where your funds will be deposited upon final approval and
    your payments will be withdrawn on your due dates according to the terms and conditions of the
    <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>. Do not enter a checking account that is already
    associated with an outstanding NetCredit loan. No funds will be transferred to or from your Deposit Account
    if your Loan application is withdrawn or declined during loan processing. In some cases, an electronic bank
    statement or similar information will be required in order to process your Loan application and make a final
    approval decision.</p>
  </div>

  <div class="sig-form-data panel-body">
    <div id="signature_bank_form" class="form-fieldset">
      
        <label for="signature_bank_routing_number">
          9-Digit Routing Number
        </label>
        
        <input type="text" class="input-text form-control" id="signature_bank_routing_number" name="signature[bank_account][bank_routing_number]" size="4" maxlength="9" autocorrect="off">
      

      
        <label for="signature_bank_account_number">
          Checking Account Number
        </label>
        <input type="text" class="input-text form-control" id="signature_bank_account_number" name="signature[bank_account][bank_account_number]" size="4" maxlength="17" autocorrect="off">
      

      
        <label for="signature_bank_account_number_confirmation">
          Verify Your Account Number
        </label>
        <input type="text" class="input-text form-control" id="signature_bank_account_number_confirmation" name="signature[bank_account][bank_account_number_confirmation]" size="4" maxlength="17" autocorrect="off">
      
    </div>
  </div>
</div>


    <div class="section ach-authorization panel panel-default clearfix signature-form">
      <h3 class="panel-heading">
      2.
      
        PAYMENT AUTHORIZATION
      
      </h3>
      <div class="sig-form-copy panel-body">
        <p>
          By typing in the last four digits of your Social Security Number and clicking the “I Agree” button below, you are electronically signing the
	  
            Payment Authorization, and you certify that you have fully read and understood the sections of this Agreement titled <a href="#payment_authorization">“PAYMENT AUTHORIZATION”</a> and
	    <a href="#electronic_fund_transfer">“ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS”</a>, you agree to comply with, and be bound by,
	    their terms, and you agree and understand that you are authorizing us to credit your Deposit Account with the proceeds of your Loan and to debit the
	    Deposit Account for the amounts owed under this Agreement as set forth in those sections.
	  
        </p>
      </div>

      <div class="sig-form-data panel-body">
        <div class="form-fieldset">
        <div class="form-field text-field">
          <label for="signature_ssn_last4">
            Enter the last 4 digits of your Social Security Number
          </label>
          
            <div class="input-text">0755</div>
          
        </div>
        <div class="fieldset-copy">
          <p>Please note that we separately authenticate the above electronic signature when you click the "I Agree" button below</p>
        </div>
        </div>
      </div>
    </div>

    <div class="section agreement-terms panel panel-default clearfix signature-form">
      <h3 class="panel-heading">3. AGREEMENT TO INSTALLMENT LOAN AGREEMENT</h3>
      <div class="sig-form-copy panel-body">
        <p>By entering your name and Social Security Number and clicking the “I AGREE” button below, you are electronically signing this Agreement and acknowledging
           that (a) you understand that Republic Bank &amp; Trust Company is your Lender under the above Agreement; (b) the Agreement was completed
           before you signed it, there are no blank spaces, and that you have reviewed the entire Agreement, including the <a href="#federal_truth_in_lending_act">FEDERAL TRUTH-IN-LENDING ACT DISCLOSURES</a>;
           (c) you have reviewed and agree to Republic Bank &amp; Trust Company's <a href="https://www.netcredit.com/rbt-privacy-policy" target="_blank">PRIVACY NOTICE</a> and NetCredit’s <a href="https://www.netcredit.com/privacy-policy" target="_blank">PRIVACY POLICY</a> and <a href="https://www.netcredit.com/terms-of-use" target="_blank">TERMS OF USE</a>; (d) you acknowledge
           that your right to file suit against us for any claim or dispute regarding this Agreement is limited by the <a href="#waiver_of_jury_trial">WAIVER OF JURY TRIAL</a>, <a href="#class_action_waiver" target="_blank">CLASS ACTION WAIVER</a> and <a href="#arbitration_provision">ARBITRATION PROVISION</a> sections of this Agreement, if applicable; (e) you
           represent that you are not a debtor under any proceeding in bankruptcy and have no intention to file a petition for relief under any chapter of the United States
           Bankruptcy Code; and (f) you acknowledge that you have read and understood all of the terms of this Agreement (including the provisions mentioned above), and you agree
           to comply with, and be bound by, all of the terms of this Agreement. </p>
        <p>Please note that when you click the “I Agree” button below, we authenticate your electronic signature and we also separately authenticate your
           electronic signature for the
           
             <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>
           
           that you separately and voluntarily entered above.</p>
        <p>YOU SHOULD PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS. AN ELECTRONIC COPY WILL BE MAINTAINED ON THIS WEBSITE IN YOUR PASSWORD PROTECTED CUSTOMER HISTORY.</p>
        <p>
          <strong>Any comments or questions may be directed to our Customer Comment Line at the following toll-free number: (877) 392-2014 or via e-mail to
          <a href="mailto:support@netcredit.com">support@netcredit.com</a>.</strong>
        </p>
      </div>

      
        
      
    </div>

      <div class="section consent-to-communication panel panel-default clearfix">
        <h3 class="panel-heading">4. CONSENT TO ELECTRONIC SIGNATURES</h3>
        <p class="panel-body">
          By clicking the “I AGREE” button below, you have consented to sign this Agreement using an electronic signature, you intend: (i) your electronic
          signature to be an electronic signature under applicable federal and state law; (ii) any printout of an electronic record of this Agreement and
          related notices to be an original document; (iii) to conduct business with Lender by electronic records and electronic signatures; and (iv) this
          Agreement will not be governed by Article 3 of the Uniform Commercial Code.
        </p>
      </div>

    <div>
      <table class="finance-charge">
      <tbody><tr><th>
        <p><strong>CAUTION—IT IS IMPORTANT THAT YOU THOROUGHLY READ THE AGREEMENT BEFORE YOU SIGN IT.</strong></p>
      </th>
      <th>
        <p><strong>NOTICE TO BORROWER<br> (a) DO NOT SIGN THIS AGREEMENT BEFORE YOU READ THE WRITING ABOVE,
              EVEN IF OTHERWISE ADVISED.<br>
              (b) DO NOT SIGN THIS AGREEMENT IF IT CONTAINS ANY BLANK SPACES.<br>
              (c) YOU ARE ENTITLED TO AN EXACT COPY OF ANY AGREEMENT YOU SIGN.<br>
              (d) YOU HAVE THE RIGHT AT ANY TIME TO PAY IN ADVANCE THE REMAINING BALANCE DUE UNDER THIS AGREEMENT WITHOUT PENALTY.
            </strong></p></th>
            </tr></tbody></table>
    </div>
    <br>
    <div id="consent-to-credit-report-hard-pull">
      <p>
        <strong>BY CLICKING "I AGREE" BELOW, YOU UNDERSTAND AND AGREE THAT WE WILL OBTAIN YOUR CREDIT REPORT, WHICH MAY IMPACT YOUR CREDIT SCORE.</strong>
      </p>
    </div>
  </div>
</div>
</body></html>`;
