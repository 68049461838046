import React from 'react';

import { DropdownMenu as BootstrapDropdownMenu } from 'react-bootstrap';
import { AlignType } from 'react-bootstrap/types';

export type DropdownMenuPropsT = {
  align?: AlignType;
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  flip?: boolean;
  id?: string;
  show?: boolean;
};
export type DropdownMenuT = React.FC<DropdownMenuPropsT>;

export const DropdownMenu: DropdownMenuT = ({ children, ...props }) => (
  <BootstrapDropdownMenu {...props}>
    {children}
  </BootstrapDropdownMenu>);
