export type ModalConfigT = {
  body: JSX.Element | string;
  primaryText: string;
  show: boolean;
  title: string;
  modalOnClick?: () => void;
  secondaryText?: string;
};

export const initialModalConfig: ModalConfigT = {
  body: '',
  primaryText: '',
  secondaryText: '',
  show: false,
  title: '',
};

export const externalLinkModalConfig: ModalConfigT = {
  body: (
    <p>
      By clicking this link you will be leaving the NetCredit website — www.netcredit.com.
      You will be automatically directed to a website that is not directly controlled by NetCredit.
      By clicking OK, you acknowledge that you are leaving the NetCredit website.
    </p>
  ),
  primaryText: 'OK',
  secondaryText: 'Cancel',
  show: false,
  title: 'Leaving NetCredit.com',
};
