import React from 'react';

import { uiRoutes } from '@consumer-frontend/nc-api';

import { NcAlert } from '../NcAlert';

export type NcFeeSaverBannerT = React.FC;

export const NcFeeSaverBanner: NcFeeSaverBannerT = () => {
  return (
    <NcAlert
      dismissible={false}
      variant="info"
    >
      The fee table below is the original pricing when you first set up your account and is not reflective of the Fee
      Saver reductions you may have earned. Please click <a href={uiRoutes.feeSaverStatus}>here</a> to see your
      current fee table as of today. Your lender may cancel or make changes to the Fee Saver program at any time.
    </NcAlert>
  );
};
