import React, { useCallback } from 'react';

import { PrimitiveAtom, useAtom } from 'jotai';

import {
  PageValuesT,
  Pagination,
} from '@consumer-frontend/shared-ui';

import '@consumer-bootstrap/netcredit.scss';
// eslint-disable-next-line import/order
import '@consumer-bootstrap/brands/netcredit/pagination.scss';

export type NcPaginationPropsT = {
  atom: PrimitiveAtom<PageValuesT>;
  hideLabel?: boolean;
};

export type NcPaginationT = React.FC<NcPaginationPropsT>;

export const NcPagination: NcPaginationT = ({ atom, ...props }) => {
  const [paginationValues, setPaginationValues] = useAtom(atom);
  const onNext = useCallback(
    () => setPaginationValues((values) => ({
      ...values,
      // TEMP PAGINATION HACK: to be reverted once the total count is fixed.
      // offset: Math.min(values.offset + values.limit, values.total),
      offset: values.offset + values.limit,
    })),
    [setPaginationValues],
  );
  const onPrev = useCallback(
    () => setPaginationValues((values) => ({
      ...values,
      offset: Math.max(values.offset - values.limit, 0),
    })),
    [setPaginationValues],
  );

  return <Pagination
    {...props}
    className="nc-pagination"
    nextHandler={onNext}
    paginationValues={paginationValues}
    prevHandler={onPrev}
  />;
};
