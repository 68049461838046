import React from 'react';

import { AllowedIconNameT } from './allowedIcons';

export type IconPropsT = {
  iconName: AllowedIconNameT;
  className?: string;
  style?: React.CSSProperties;
};
export type IconT = React.FC<IconPropsT>;

export const Icon: IconT = ({
  className = '',
  iconName,
  ...props
}) => <i className={`bi bi-${iconName} ${className}`.trimEnd()} {...props} />;
