import React from 'react';

export type PageValuesT = {
  limit: number;
  offset: number;
  total: number;
};
export type PaginationLabelT = React.FC<PageValuesT>;

export const PaginationLabel: PaginationLabelT = ({
  offset,
  total,
}) => {
  const firstItem = Math.max(offset + 1, 1);
  // TEMP PAGINATION HACK: revert once the total count is fixed.
  // const lastItem = Math.min(offset + limit, total);
  const lastItem = offset + total;

  return (
    <span className="pagination-label m-2">
      {
        // TEMP PAGINATION HACK: uncomment "of {total}" line once the total count is fixed.
        (total ?? 0) > 0
          ? (
            <>
              {`(${firstItem}-${lastItem}`}
              {/* <span className="d-none d-sm-inline"> of {total}</span> */}
              )
            </>
          )
          : '(0)'
      }
    </span>
  );
};
