import { AxiosError } from 'axios';
import useSWR from 'swr';

import { apiEndpoints } from '../lib';
import { MessageT } from '../types';

export type UseMessagesPropsT = {
  id: string;
  limit?: number;
  offset?: number;
};
export type MessagesReturnT = {
  count: number;
  messages: MessageT[];
};

// eslint-disable-next-line @stylistic/object-curly-newline
export const useMessages = ({ id, limit, offset }: UseMessagesPropsT) => {
  const queryParams: Record<string, string> = {};

  if (limit) { queryParams.limit = limit.toString(); }
  if (offset) { queryParams.offset = offset.toString(); }

  // eslint-disable-next-line @stylistic/object-curly-newline
  const { data, error, isLoading } = useSWR<MessagesReturnT, AxiosError>(
    apiEndpoints.messages(id, queryParams),
    // Since the result of this endpoint is unlikely to change very frequently, don't re-make
    // the same request more than once a minute.
    { dedupingInterval: 60000 },
  );

  return {
    count: data?.count,
    error,
    isLoading,
    messages: data?.messages,
  };
};
