import React, { useEffect, useMemo } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';

import { uiRoutes, useMessages } from '@consumer-frontend/nc-api';
import {
  Container,
  Navbar,
  NavLink,
} from '@consumer-frontend/shared-ui';

import { accountIdAtom, messageDetailPaginationAtom } from '../../store';
import { NcLoadingSpinner } from '../NcLoadingSpinner';
import { NcMessageDetailHeader } from '../NcMessageDetailHeader';
import { NcMessageDetailIframe } from '../NcMessageDetailIframe';
import { MessageDetailPagination } from '../NcPagination';

import '@consumer-bootstrap/netcredit.scss';
// eslint-disable-next-line import/order
import '@consumer-bootstrap/brands/netcredit/message-detail.scss';

export type NcMessageDetailT = React.FC;

export const NcMessageDetail: NcMessageDetailT = () => {
  const [accountId] = useAtom(accountIdAtom);
  const [{ limit, offset }, setPaginationValues] = useAtom(messageDetailPaginationAtom);
  const {
    count: messageCount,
    messages,
    isLoading: messagesAreLoading,
  } = useMessages({
    id: accountId || '',
    limit,
    offset,
  });

  const message = useMemo(
    () => messages?.[0],
    [messages],
  );

  // Update the detail pagination total value when the messageCount changes.
  useEffect(
    () => {
      setPaginationValues((values) => {
        // Guard against unnecessary re-renders
        if (messagesAreLoading || messageCount === values.total) { return values; }

        return { ...values, total: messageCount || 0 };
      });
    },
    [
      messageCount,
      messagesAreLoading,
      setPaginationValues,
    ],
  );

  // Track page views in Datadog.
  useEffect(
    () => {
      if (messagesAreLoading) return;

      datadogRum.addAction(messageCount ? 'NcMessageDetailView' : 'NcMessageDetailEmptyView');
    },
    [messageCount, messagesAreLoading],
  );

  // TEMP PAGINATION HACK: revert once the total count is fixed.
  // if (!message && !messagesAreLoading) {
  //   return <Navigate to={uiRoutes.messages} replace />;
  // }

  return (
    <Container className="message-detail rounded" hasBoxShadow>
      <Navbar className="top-nav">
        <NavLink to={uiRoutes.messages}>
          <span className="ascii-chevron-left" aria-hidden>‹</span>
          <span className="inbox-link-text">Inbox</span>
        </NavLink>
        <MessageDetailPagination />
      </Navbar>

      {/* TEMP PAGINATION HACK: remove once total count is fixed. */}
      {!message && !messagesAreLoading && <p>End of messages.</p>}

      {/* Fetch still in progress */}
      {messagesAreLoading && <NcLoadingSpinner />}

      {/* Message content */}
      {message && (
        <>
          <NcMessageDetailHeader
            sender={message.from}
            subject={message.subject}
            timestamp={message.sent_at}
          />

          <hr />

          <NcMessageDetailIframe html={message.html_part} />

          <hr />

          <Navbar className="bottom-nav">
            <MessageDetailPagination />
          </Navbar>
        </>
      )}

    </Container>
  );
};
