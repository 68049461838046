import { datadogRum } from '@datadog/browser-rum';

if (import.meta.env.VITE_DATADOG_ENABLED === 'true') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: import.meta.env.VITE_ENV,
    service: 'netcredit-frontend',
    sessionReplaySampleRate: 0,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: import.meta.env.VITE_GIT_COMMIT,
  });
}
