export const getAdobeDataLayer200Response = () => ({
  accountChannel: 'leads',
  accountCreatedAt: '1',
  accountId: '42',
  addressState: 'VA',
  apr: '0.35',
  aprGroup: '36',
  customerType: 'New',
  disbursementAmount: '999.0',
  dmCampaignId: '123',
  eligibility: '',
  lenderName: 'netcredit_bank_partnership',
  loanAmount: '900.0',
  loanApplicationCreatedAt: '1',
  loanApplicationId: '42',
  loanApplicationStatus: 'underwriting_approved',
  loanCreatedAt: '1',
  loanId: '42',
  loanNumber: '2024MO1234',
  loanStatus: 'current',
  paidOffLoanFlag: 'false',
  product: 'netcredit',
  providerOnLastLeadApp: 'credit_karma',
  refinanceType: 'cash_out',
  requestedAmount: '1500.0',
  termLength: '666',
  underwriterName: 'OrganicUnderwriting',
});
