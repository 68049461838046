import React, { useEffect } from 'react';

import { useAtom } from 'jotai';
import { Outlet, useLocation } from 'react-router-dom';

import { useAdobeDataLayer } from '@consumer-frontend/nc-api';
import {
  accountIdAtom,
  NcFooter,
  NcHeader,
  NcModal,
  NcNav,
} from '@consumer-frontend/nc-components';
import { Container } from '@consumer-frontend/shared-ui';

import { analytics } from '../utils/adobe-analytics';

import '@consumer-bootstrap/netcredit.scss';

export const RootPage: React.FC = () => {
  const location = useLocation();
  const [accountId] = useAtom(accountIdAtom);
  const { adobeDataLayer } = useAdobeDataLayer(accountId);

  useEffect(() => {
    analytics.track('routingChange', {
      to_path: location.pathname,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (adobeDataLayer) {
      analytics.identify(adobeDataLayer.accountId, {
        ...adobeDataLayer,
      });
    }
  }, [adobeDataLayer]);

  return (
    <>
      <NcHeader />
      <Container className="root-container d-flex">
        <NcNav displayNone responsiveFlexClass="d-md-flex" hasBoxShadow />
        <Outlet />
      </Container>
      <NcFooter />
      <NcModal />
    </>
  );
};
