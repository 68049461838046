import React, { MouseEventHandler } from 'react';

import { NavLink as BootstrapNavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export type NavLinkPropsT = {
  to: string;
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  onClick?: MouseEventHandler;
  style?: React.CSSProperties;
};

export type NavLinkT = React.FC<NavLinkPropsT>;

export const NavLink: NavLinkT = ({
  children,
  to,
  ...props
}) => {
  // If `to` is passed in, render the BS NavLink as a React Router Link,
  // otherwise use default behavior which uses an `<a>` tag with an href value of `#` and a role of `button` for WCAG compliance.
  return (to
    ? (
      <BootstrapNavLink {...props} as={Link} to={to}>
        {children}
      </BootstrapNavLink>
    )
    : (
      <BootstrapNavLink {...props}>
        {children}
      </BootstrapNavLink>
    )
  );
};
