import Cookies from 'js-cookie';
import * as jwt from 'jsonwebtoken';
import { InvalidTokenError, jwtDecode } from 'jwt-decode';

export const ACCESS_TOKEN_KEY: string = import.meta.env.VITE_NC_ACCESS_TOKEN_KEY || 'netcredit_access_tokens';
export const REFRESH_TOKEN_KEY: string = import.meta.env.VITE_NC_REFRESH_TOKEN_KEY || 'netcredit_refresh_tokens';
export const NETCREDIT_SESSION_KEY = '_netcredit_session';

type LoginServerPayloadT = jwt.JwtPayload & {
  authorized?: boolean;
  client_id?: string;
  tenant_id?: string;
  user_id?: string;
};

// Cookies env var helpers
export const cookiesDomain = () => import.meta.env.VITE_NC_COOKIES_DOMAIN || '.netcredit.dev';
export const cookiesSecure = () => import.meta.env.VITE_NC_COOKIES_SECURE === 'true';
// This is needed when setting or deleting cookies. It's not needed when getting cookies.
export const defaultCookieAttrs = (): Cookies.CookieAttributes => ({
  domain: cookiesDomain(),
  path: '/',
  secure: cookiesSecure(),
});
export const defaultSessionAttrs = (): Cookies.CookieAttributes => ({
  domain: cookiesDomain(),
  httpOnly: true,
  path: '/',
  secure: false,
});
// Staging cookie domain edge case helpers
const isStagingEnv = () => cookiesDomain().includes('staging');
const additionalStagingDomain = () => cookiesDomain().replace('staging.', '');

export type CookieJarT = {
  expire: () => void;
  getAccessToken: () => string;
  getAccountId: () => [string | undefined, Error | undefined];
  getRefreshToken: () => string;
  isTokenExpired: () => boolean;
  saveTokens: (accessToken: string, refreshToken: string) => void;
};

// The JSON bits are necessary because of the way the tokens are created in account_home.
// Cf: https://git.enova.com/netcredit/account_home/blob/main/app/helpers/cookie_helper.rb
// The `window.` bits are necessary because otherwise TS/VSCode think you're trying to use the deprecated `btoa` and `atob`
// functions from Node rather than the non-deprecated versions from the browser.
export const decode = (token: string): string => {
  if (!token) return '';

  return JSON.parse(window.atob(token));
};

export const encode = (token: string): string => {
  if (!token) return '';

  return window.btoa(JSON.stringify(token));
};

export const decodeToken = (token: string): [LoginServerPayloadT | undefined, Error | undefined] => {
  try {
    const decodedToken = jwtDecode<jwt.JwtPayload>(token);

    return [decodedToken as LoginServerPayloadT, undefined];
  } catch (err) {
    return [undefined, new Error(`Error decoding token: ${(err as InvalidTokenError)?.message}`)];
  }
};

export const cookieJar: CookieJarT = {
  expire: () => {
    Cookies.remove(ACCESS_TOKEN_KEY, defaultCookieAttrs());
    Cookies.remove(REFRESH_TOKEN_KEY, defaultCookieAttrs());
    Cookies.remove(NETCREDIT_SESSION_KEY, defaultSessionAttrs());

    if (isStagingEnv()) {
      const secondDomain = additionalStagingDomain();

      Cookies.remove(ACCESS_TOKEN_KEY, { ...defaultCookieAttrs(), domain: secondDomain });
      Cookies.remove(REFRESH_TOKEN_KEY, { ...defaultCookieAttrs(), domain: secondDomain });
      Cookies.remove(NETCREDIT_SESSION_KEY, { ...defaultSessionAttrs(), domain: secondDomain });
    }
  },
  getAccessToken: () => decode(Cookies.get(ACCESS_TOKEN_KEY) ?? ''),
  getAccountId: () => {
    if (import.meta.env.VITE_MOCKING === 'true') return ['123456', undefined];

    const accessToken = cookieJar.getAccessToken();

    if (!accessToken) return [undefined, new Error('No access token found')];

    const [decodedToken, error] = decodeToken(accessToken);

    return [decodedToken?.user_id, error];
  },
  getRefreshToken: () => decode(Cookies.get(REFRESH_TOKEN_KEY) ?? ''),
  isTokenExpired: () => {
    const [decodedToken] = decodeToken(cookieJar.getAccessToken());

    if (!decodedToken) return true;

    // exp format: https://datatracker.ietf.org/doc/html/rfc7519#section-4.1.4
    return !decodedToken?.exp || Date.now() >= decodedToken.exp * 1000;
  },
  saveTokens: (accessToken: string, refreshToken: string) => {
    Cookies.set(ACCESS_TOKEN_KEY, encode(accessToken), defaultCookieAttrs());
    Cookies.set(REFRESH_TOKEN_KEY, encode(refreshToken), defaultCookieAttrs());
  },
};
