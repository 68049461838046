import React from 'react';

import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { DropDirection } from 'react-bootstrap/DropdownContext';
import { AlignType } from 'react-bootstrap/types';

export type DropdownPropsT = {
  align?: AlignType;
  children?: React.ReactNode;
  className?: string;
  drop?: DropDirection;
  id?: string;
  show?: boolean;
  style?: React.CSSProperties;
};
export type DropdownT = React.FC<DropdownPropsT>;

export const Dropdown: DropdownT = ({ children, ...props }) => (
  <BootstrapDropdown {...props}>
    {children}
  </BootstrapDropdown>);
