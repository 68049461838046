import React from 'react';

import { NavbarOffcanvas as BoostrapOffCanvas } from 'react-bootstrap';
import { OffcanvasPlacement } from 'react-bootstrap/Offcanvas';

export type NavbarOffcanvasPropsT = {
  children: React.ReactNode;
  'aria-labelledby'?: string;
  className?: string;
  id?: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placement?: OffcanvasPlacement;
  show?: boolean;
};
export type NavbarOffcanvasT = React.FC<NavbarOffcanvasPropsT>;

export const NavbarOffcanvas: NavbarOffcanvasT = ({
  children,
  ...props
}) => (
  <BoostrapOffCanvas {...props}>
    {children}
  </BoostrapOffCanvas>
);
