import React from 'react';

import { Container as BootstrapContainer } from 'react-bootstrap';

export type ContainerPropsT = {
  children?: React.ReactNode;
  className?: string;
  fluid?: true | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  hasBoxShadow?: boolean;
  style?: React.CSSProperties;
};
export type ContainerT = React.FC<ContainerPropsT>;

export const Container: ContainerT = ({
  children,
  className,
  hasBoxShadow,
  ...props
}) => {
  const classes = hasBoxShadow ? `${className} shadow-sm` : className;

  return (
    <BootstrapContainer className={classes} {...props}>
      {children}
    </BootstrapContainer>
  );
};
