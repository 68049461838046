import React from 'react';

import { accountHomeBaseUrl, externalLinks } from '@consumer-frontend/nc-api';
import {
  Icon,
  Image,
  Link,
} from '@consumer-frontend/shared-ui';

import { externalLinkModalConfig } from '../../store/constants';
import { NcModalAnchor } from '../NcModal/NcModalAnchor';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcSocialT = React.FC;

export const NcSocial: NcSocialT = () => {
  /* eslint-disable max-len */
  const fbIconURL = 'https://s3.us-east-2.amazonaws.com/enova-brand-icon-assets-prod.us-east-2/Brand+Icons/NetCredit/UI+Icons/SVG/fb_logo.svg';
  const xIconURL = 'https://s3.us-east-2.amazonaws.com/enova-brand-icon-assets-prod.us-east-2/Brand+Icons/NetCredit/UI+Icons/SVG/x-logo-white.svg';
  /* eslint-enable max-len */

  return (
    <div className="d-flex flex-column social">
      <Link to={accountHomeBaseUrl}>
        <Image
          src="/assets/images/NC_Inverse-standard_TV_tagline.svg"
          alt="NetCredit Footer Logo"
          style={{
            width: '12.8125rem',
          }}
        />
      </Link>
      <Link to="tel:+18773922014">
        <Icon iconName="telephone-fill" />
        (877) 392-2014
      </Link>
      <Link to="mailto:support@netcredit.com">
        <Icon iconName="envelope-fill" />
        support@netcredit.com
      </Link>
      <div className="d-flex flex-row social-links">
        <NcModalAnchor
          {...externalLinkModalConfig}
          modalOnClick={() => window.open(externalLinks.facebook, '_blank')}
        >
          <Link to={externalLinks.facebook}>
            <Image
              src={fbIconURL}
              alt="NetCredit on Facebook"
            />
          </Link>
        </NcModalAnchor>
        <NcModalAnchor
          {...externalLinkModalConfig}
          modalOnClick={() => window.open(externalLinks.x, '_blank')}
        >
          <Link to={externalLinks.x}>
            <Image
              src={xIconURL}
              alt="NetCredit on X (formerly Twitter)"
            />
          </Link>
        </NcModalAnchor>
      </div>
    </div>
  );
};
