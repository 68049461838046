import React from 'react';

import { uiRoutes } from '@consumer-frontend/nc-api';
import { Link, ListGroupItem } from '@consumer-frontend/shared-ui';

import { dateFromTimestamp } from '../../utils';

import '@consumer-bootstrap/brands/netcredit/message-center.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcMessageCenterLinkPropsT = {
  messageDate: string;
  messageTitle: string;
  onClick?: () => void;
};

export type NcMessageCenterLinkT = React.FC<NcMessageCenterLinkPropsT>;

export const NcMessageCenterLink: NcMessageCenterLinkT = ({
  messageTitle,
  messageDate,
  onClick,
}) => {
  return (
    <ListGroupItem>
      <Link
        className="nc-message-center-link"
        onClick={onClick}
        to={uiRoutes.messageDetail}
      >
        <p className="nc-message-title">{messageTitle}</p>
        <p className="nc-message-date" data-chromatic="ignore">{dateFromTimestamp(messageDate)}</p>
      </Link>
    </ListGroupItem>
  );
};
