// SIGNED contract mock from PORTFOLIO (STAGING) at 2025-03-18 17:37:28 -0500
export const installmentDirectALAchWithoutAccount = `<html><head></head><body><div class="loan-contract installment-contract">

  <div class="article loan-agreement">

    <h2 class="contract-title">ALABAMA CONSUMER INSTALLMENT LOAN AGREEMENT</h2>

    <div class="section summary table-responsive">
      <div class="entities">
            </div><table class="finance-charge table table-bordered">
        <tbody><tr>
          <td>
            <dl class="projected-disbursement-date"><dt>Projected Disbursement Date:</dt> <dd>Mar 19, 2025</dd></dl>
          </td>
          <td>
            <dl class="account-num"><dt>Loan #:</dt> <dd>2025AL564280893</dd></dl>
            <dl class="account-num"><dt>Account ID:</dt>56322136</dl>
          </td>
        </tr>

        <tr>
          <td>
              <div class="entity lender">
                <h3 class="party">Creditor:</h3>
                <div class="location">
                  <div class="company">NC Financial Solutions of Alabama, LLC, d/b/a NetCredit</div>
                  <address>
                    <span class="address-1">175 W. Jackson Blvd., </span><br>
                    
                      <span class="address-2">Suite 600 </span><br>
                    
                    <span class="city">Chicago, </span>
                    <span class="state">IL </span>
                    <span class="zip">60604 </span><br>
                  </address>
                  <div class="phone home">877-392-2014 </div>
                </div>
              </div>
            </td>

            <td>
              <div class="entity borrower">
                <h3 class="party">Borrower:</h3>
                <div class="location">
                  <div class="person">Squirrley McSquirrlington  </div>
                  <address>
                    <span class="address-1">leads_decision: staging_approved post_contract_mock: staging_approved ebs_uw_required:0, </span><br>
                    
                      <span class="address-2">1 </span><br>
                    
                    <span class="city">Bentonville, </span>
                    <span class="state">AL </span>
                    <span class="zip">24635 </span><br>
                  </address>
                  <div class="phone home">074-276-8921 </div>
                  <div class="phone work">880-747-3855 </div>
                </div>
              </div>
            </td>
          
        </tr>
      </tbody></table>
    </div>

    <div class="section terms">
      <p>
        This Consumer Installment Loan Agreement (hereinafter the “Agreement”) includes the disclosures above,
        the Federal Truth in Lending Act Disclosures immediately below (“TILA Disclosures”) and the Additional Terms.
      </p>
      <p>
        In this Agreement, the words "you," "your" and “I” mean the borrower who has electronically signed it and the words "we," "us" and "our" mean <strong>NC Financial Solutions of Alabama, LLC </strong>
        d/b/a NetCredit ("Lender"), licensed and regulated by the <strong>Alabama State Banking Department, who may be contacted at Alabama Banking
        Department, P.O. Box 4600, Montgomery, AL 36103-4600 or at (334) 242-3452</strong> (collectively, the parties).
      </p>
      <p><strong>This Agreement is made pursuant to Title 5, Chapter 19 of the Alabama Code.</strong></p>
      <p>
        Before we disburse Loan funds, we will seek to verify the information we have collected in connection with your request for a Loan.
        Such verification may require you to provide additional information to us. You agree that, in the event that we are unable to complete
        the verification to our satisfaction, we are not obligated to disburse Loan funds. If we do not disburse Loan funds, we will notify
        you at the e-mail address you provided on your application.
      </p>
      <p>
        Certain of the TILA Disclosures are based on our expectation that Loan funds will be disbursed to you on the Projected Disbursement Date
        set forth above. Banking delays or holidays, or delays in making contact with you to verify the information you have provided to us, may
        cause the Loan funds to be disbursed later. We will begin accruing interest on the date the Loan funds are actually disbursed by us.
      </p>
      <p>
        <strong>THIS AGREEMENT INCLUDES AN ARBITRATION PROVISION. UNLESS YOU PROPERLY REJECT THE ARBITRATION PROVISION OR ARE A MEMBER OF THE ARMED
        FORCES OR A DEPENDENT OF SUCH A MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT, THE ARBITRATION PROVISION WILL HAVE A SUBSTANTIAL EFFECT
        ON YOUR RIGHTS IN THE EVENT OF A DISPUTE WITH US. FOR EXAMPLE, IF WE ELECT TO REQUIRE YOU TO ARBITRATE ANY CLAIM, YOU WILL NOT HAVE THE RIGHT
        TO A JURY TRIAL OR THE RIGHT TO PARTICIPATE IN A CLASS ACTION IN COURT OR IN ARBITRATION.</strong>
      </p>
      <br>
    </div>

    <div class="section truth-in-lending panel panel-default" id="truth_in_lending">
     <h3>FEDERAL TRUTH IN LENDING ACT DISCLOSURES</h3>
     <div class="table-responsive">
       <table class="finance-charge table table-bordered">
         <tbody><tr>
           <th class="important">
             ANNUAL PERCENTAGE RATE
             <span>The cost of your credit as a yearly rate.</span>
           </th>
           <th class="important">
             FINANCE CHARGE
             <span>The dollar amount the credit will cost you.</span>
           </th>
           <th>
             Amount Financed
             <span>The amount of credit provided to you or on your behalf.</span>
           </th>
           <th>
             Total of Payments
             <span>The amount you will have paid after you have made all payments as scheduled</span>
           </th>
         </tr>
         <tr>
           <td class="cost-of-credit"><strong>35.54% (e)</strong></td>
           <td class="cost-of-credit"><strong>$7,966.36 (e)</strong></td>
           <td>$6,940.00</td>
           <td>$14,906.36 (e)</td>
         </tr>
       </tbody></table>
     </div>

     <h3>Payment Schedule (e):</h3>
       <p>Your payment schedule will be 130 payments due every two weeks on Fridays,       beginning Friday, Apr  4, 2025       and ending Friday, Mar 15, 2030, except that if any of these days is not a Business Day,       your payment will be due on the preceding Business Day.       Each payment will be in the amount of $114.69, except for your final payment which will be in the amount of $111.35.</p>

     
     <div class="clause federal-disclosure" id="fed_disclosure">
      
        <p><strong>Security: </strong>You are giving us a security interest in the Payment Authorization.</p>
      
       <p><strong>Prepayment: </strong>If you prepay in full before the cancellation deadline described below, you may be entitled to a refund of part of the finance charge.  If you pay in full or in part after this, you will not have to pay a penalty.</p>
       <p><strong>Contract Reference: </strong>See the Additional Terms for any additional information about nonpayment, default, our right to accelerate maturity of this Agreement and prepayment rebates and penalties.</p>
       <p>(e) means estimate.</p>
       <br>
     </div>
    </div>

    <div class="amount-itemization">
      <h3>ITEMIZATION OF AMOUNT FINANCED OF <span class="value">$6,940.00</span></h3>
      <div class="item">
        <span class="value">Amount given to you directly</span>
        <span class="value">$3,500.00</span>
        <br>
        
      </div>
      <br>
    </div>
    <h3 style="text-align: center; text-decoration: underline;">ADDITIONAL TERMS</h3>
    <h3>PROMISE TO PAY AND CALCULATION OF INTEREST.</h3>
      <p>
        You promise to pay us <strong>$6,940.00</strong>
        (the amount of the principal) plus interest from the date the Loan Funds are actually disbursed to you until
        the final installment due date set forth in the payment schedule above (“Payment Schedule”),
        or any adjusted payment schedule subsequently agreed to by you and us and confirmed by
        us in writing (“Maturity Date”). You also promise to pay any other charges provided
        for under this Agreement. The Finance Charge disclosure above represents
        the amount of interest you will pay under this Agreement assuming you pay all your scheduled installment
        payments when due according to the Payment Schedule (and assuming your Loan is disbursed on the
        Projected Disbursement Date). You promise to make the payments you owe by
        
          authorizing us to initiate electronic fund transfers from a deposit account of yours; or,
          in the event that we are unable to process electronic fund transfers,
        
        by making payment by another method acceptable to us. In order for your payment to be considered timely,
        it must be submitted by the cut-off time applicable to your method of payment, which can be found at
        <a href="https://www.netcredit.com/faq" target="_blank">https://www.netcredit.com/faq</a>.
         We charge
        interest under an adjusted daily simple interest method at the annual rate of
        35.68% (“Contract Rate”). Interest shall not be payable in
        advance or compounded. We apply the Contract Rate to the “Adjusted Principal Balance” from the actual disbursement date until the earlier of (i) the Maturity Date, (ii) prepayment in full, or (iii) acceleration pursuant to the default provisions. The term “Adjusted Principal Balance” (hereinafter the “APB”) means
        the loan principal balance that would be outstanding assuming you make each payment timely,
        less any additional prepayments of principal. “Timely” means making payment in accordance with
        the Payment Schedule or any adjusted payment schedule agreed to by the parties and confirmed by
        us in writing. The APB will equal the outstanding loan principal balance if you make all payments
        timely and no other payments. You will not be required to pay more total interest than the amount
        set forth in the Finance Charge box above (unless your Loan is disbursed before the Projected Disbursement Date).
      </p>

      <h3>PREPAYMENT.</h3>
      <p>
        You have the right to prepay in full or in part at any time. You will not incur an
        additional charge, fee or penalty for prepayment. Prepayments of principal may reduce
        the total amount of interest you pay under this Agreement. To process a partial or full
        prepayment or receive a payoff balance, you should call us at (877) 392-2014 or
        tell us of your intent to prepay in writing via fax at (855) 878-1068 or email at
        <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
        Please note that, unless otherwise specified, any payoff amount we provide will be calculated
        as of the date we expect to seek payment from your deposit account for the balance owing.
      </p>

      <h3>CANCELLATION.</h3>
      <p>
        You have the right to cancel this Loan until 06:00 PM Eastern Time on the next business
        day immediately following the day we disburse the proceeds of your Loan or, in the case of a refinancing,
        the third business day immediately following the day we apply your Loan proceeds to pay off another loan
        (as applicable, the “Cancellation Deadline”). To cancel, you must, no later than the Cancellation Deadline,
        inform us in writing via fax at (855) 878-1068 or email at
        <a href="mailto:support@netcredit.com">support@netcredit.com</a>
        of your intent to cancel, which will authorize us to debit for the principal amount of
        the Loan, as described below. If you seek to cancel and your cancellation payment is
        successful, all interest that has accrued will be waived and you will have no further
        payment obligations under this Agreement. If you seek to cancel but your cancellation
        payment is returned unpaid or is otherwise not successful, your Loan will not be cancelled
        and your obligations under this Agreement will continue just as if you had not attempted
        to cancel.
      </p>

      <h3>APPLICATION OF PAYMENTS.</h3>
      <p>
        Payments will be applied first to satisfy any past due amounts owing, and then to any currently scheduled installment amounts owing.
        If you are past due, we credit all payments received first to the oldest past due amounts owing by applying such first to
        accumulated but unpaid interest and then to past due principal owing. We repeat the process until all past due amounts
        have been paid in full. If you are not past due, we credit all payments to the current or upcoming scheduled installment by applying
        such first to interest and then to principal as of the date received. If an early payment is less than the amount
        of the next scheduled installment, then you must pay the difference on or before the corresponding installment due date. Making an
        installment payment in excess of what is due may reduce the total amount of interest you are scheduled to pay under this Agreement
        and your remaining payments (starting with your last payment and working backwards). However, you cannot generally rely on an excess
        payment made on or before one installment due date to satisfy the installment obligation associated with the next installment due date.
        For example, if you have a 12-installment loan and, on your second installment due date, you make a double payment, you will still owe
        a regular installment payment on your third due date (but that extra payment will likely reduce the amount of interest you pay and may
        reduce or eliminate your twelfth installment payment).
      </p>

      
      <div class="section authorization" id="authorization">
        <h2 id="authorization_agreement">ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS.</h2>
        <p>
          You authorize us to initiate an electronic fund transfer (“EFT”) to credit the checking or
          savings account specified in your Application/Data Verification form or any substitute
          account you may later provide us (the “Deposit Account”) with the proceeds of your
          Loan. In the event that we make an error in processing this EFT, you further authorize us
          to initiate an EFT to the Deposit Account to correct the error. You authorize us to initiate
          EFT credits to the Deposit Account as may be necessary to obtain an accurate balance
          under this Agreement.
        </p>

        <h2 id="payment_authorization">PAYMENT AUTHORIZATION.</h2>
        
          <h3>EFT Authorization for Installment Payments; Error Correction; and in the Event of Acceleration, the Loan Balance</h3>
        
        <p>
          You authorize us (which includes, for the purpose of this Payment Authorization, our
          servicers/service providers, successors and assigns and the servicers/service providers of
          our successors and assigns) to initiate an EFT through the ACH
          network (or another network of our choosing) from the Deposit Account for the
          following:
        </p>
        <ul>
          
            <li>
              Each of the installment payments due under the Agreement (or, if you make a partial
              installment payment, the amount of the installment less the amount of any such payment)
              on or after its due date, including any payment due on a non-banking day, which will be
              processed on the preceding business day;
            </li>
            <li>
              The final installment payment due, which may vary based upon payment history (including early
              payments or extra payments) during the course of the Agreement;
            </li>
          
          <li>
            In the event that you cancel the loan as prescribed in the Cancellation provision above, the entire
            loan principal 
            within two business days of timely cancellation;
          </li>
          <li>
            In the event that you default and we declare the entire outstanding balance (the “Accelerated Balance”),
            including all accrued and unpaid interest that you owe under this Agreement, due and payable,
            the Accelerated Balance on or after the date we accelerate; and
          </li>
          <li>
            The amount required to correct any error we make in processing an EFT on or after the date we make the error.
          </li>
        </ul>
        
          <p>
            If any payment cannot be obtained by EFT, you remain responsible for such payment under the Agreement.
            You agree that we may resubmit any returned EFT as permitted by law and network rules.
            The amount of any EFT for a scheduled installment payment may be reduced by any amount we receive
            in advance of the EFT that reduces the installment due.
            You acknowledge that the origination of EFT transactions to the Deposit Account must comply with the provisions of U.S. law.
          </p>
          <h3>Modified Charges</h3>
          <p>
            Instead of or in addition to any of the EFTs described above, you authorize us to process any EFTs
            you subsequently confirm by phone, fax, text message, email, or by logging into the online
            Loan account portal.
          </p>
        

        <h3>Right to Stop Payment and Procedure for Doing So</h3>
        <p>
          You may terminate one or all of the authorizations to initiate EFTs from the Deposit Account
          set forth above by calling us, c/o NetCredit, at (877) 392-2014,
          or writing us via fax at (855) 878-1068 or e-mail at <a href="mailto:support@netcredit.com">
          support@netcredit.com</a>. We will discontinue initiating any EFTs you specify as soon as we reasonably can and,
          in any event, within three business days after receiving your termination request.
        </p>
        
          <p>
            In the event that you terminate this authorization, you may make payments on your loan by calling us at
            (877) 392-2014 to initiate repayment by debit card, money order, Western Union, or MoneyGram or
            by sending us a check to NetCredit, P.O. Box 206766, Dallas, TX 75320-6766. The Individual account
            ID and Customer Name should be included with payment. No correspondence should be included.
          </p>
          <h3>Range of Varying Amounts</h3>
          <p>
            Your scheduled installment payments provided in the Payment Schedule shown above will vary if
            you make partial payments pursuant to the Agreement. The range of your scheduled installment payments will
            normally not vary by more than the amount necessary for such adjustments. If your scheduled installment
            payment exceeds the described range, we will send you notice at least ten (10) days in advance of the payment.
          </p>
          <p>
            You may elect to receive notice in advance of any payment amount that varies from that which is in your payment
            schedule or your most recent payment. To receive advance notice of all varying amount payments, you may call us,
            c/o NetCredit, at (877) 392-2014, or write us via fax at (855) 878-1068 or e-mail at
            <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
          </p>
        

        <h3>Your Deposit Account Information</h3>
        <p>
          If there is any missing or erroneous information concerning your Deposit Account or the financial institution
          that holds it, then you authorize us to verify and correct such information. You have given us certain information
          about your Deposit Account so that we can verify the account number and routing number and you acknowledge that
          your Deposit Account is a legitimate, open, and active account.
        </p>

        <h3>Fees Your Financial Institution May Charge You For Rejected Payments</h3>
        <p>
          You understand that the financial institution holding your Deposit Account may impose additional fees in
          connection with returned or rejected EFTs, and you agree that we do not have any liability regarding any
          such fees. <strong>IN THE EVENT THAT YOU DO NOT HAVE SUFFICIENT FUNDS IN YOUR DEPOSIT ACCOUNT TO COVER THE
          AMOUNT OF ANY EFT AUTHORIZED HERE, YOU SHOULD CONTACT US AT LEAST THREE BUSINESS DAYS IN ADVANCE OF OUR
          INITIATING THE EFT SO THAT WE CAN STOP THE EFT AND ARRANGE FOR AN ALTERNATIVE METHOD OF REPAYMENT.</strong>
        </p>

        <h3>Full Force and Effect</h3>
        <p>
          This Payment Authorization will remain in full force and effect until (i) we have received notification
          from you, as instructed above, of its termination in such time and in such manner as to afford us and the
          financial institution holding the Deposit Account a reasonable opportunity to act on it, or (ii) upon full
          and final payment of the amount you owe us under this Agreement.
        </p>

        
          <h3>Optional Nature of Authorization</h3>
          <p>
            Please note that you are not required to authorize us to initiate EFTs for your installment payments to enter into this Agreement.
            You have the option of arranging to make your installment payments by a method that does not involve EFTs.
            You agree that making your installment payments by EFT is for your convenience. By entering the last four digits of
            your Social Security Number and signing this Payment Authorization and clicking the “I AGREE” button below,
            you acknowledge that you are voluntarily choosing to make your installment payments by EFT.
          </p>
        
      </div> 

      
        <h3>SECURITY.</h3>
        <p>
          Pursuant to Comment 2(a)(25) of the Federal Reserve Board Official Staff Commentary to
          Regulation Z §1026.2, we have disclosed to you that our interest in the ACH Authorization
          is a security interest for Truth-in-Lending purposes only, because Federal and Alabama law do
          not clearly address whether our interest in the ACH Authorization is a "security interest."
        </p>
      

      
      <div class="waiver_of_jury_trial" id="trial_waiver">
        <h2 id="waiver_of_jury_trial">WAIVER OF JURY TRIAL.</h2>
        <p>
          Subject to the Arbitration Provision, if you sign this Agreement you waive your right to have a jury trial
          to resolve any dispute you may have against us or a related third party. This Waiver of Jury Trial provision
          does not apply to you if, at the time of entering into this Agreement, you are a member of the armed forces
          or a dependent of such a member covered by the Federal Military Lending Act.
        </p>

        <h3>DEFAULT AND ACCELERATION.</h3>
        <p>
          Subject to any applicable law which may require that we notify and/or give you a right to cure
          your default, we may declare you in default under this Agreement for any of the following reasons:
          (i) if you do not pay in full a payment in accordance with the Payment Schedule or any adjusted
          payment schedule subsequently agreed to by you and us and confirmed by us in writing; (ii) if you
          violate any promise you make to us in this Agreement; (iii) if you file for protection under the
          United States Bankruptcy Code, or become subject to a proceeding which seeks relief from debt;
          (iv) if you have made a false or misleading statement about an important matter in connection with
          this Agreement, including your application for credit; or (v) you die or become legally incompetent.
          In the event of default, we may (i) declare the entire outstanding balance that you owe under this
          Agreement, and if applicable, any other charges permitted by law, immediately due and payable; (ii)
          proceed to reduce our claim to a judgment in accordance with applicable law; and (iii) exercise any
          other rights we have under this Agreement or the law. To the extent permitted by applicable law, you
          agree to pay our court costs, reasonable attorneys’ fees and other collection costs related to the default.
        </p>

        <h3>NON-WAIVER OF OUR RIGHTS.</h3>
        <p>
          By choosing any one or more of these remedies listed above, we do not give up our right to use another
          remedy later. By deciding not to use any remedy should you be in default, we do not give up our right to
          consider the event a default if it happens again. We may delay or refrain from enforcing any of our rights
          under this Agreement without waiving such rights. You understand that any waiver of our rights will not be
          effective unless it is in writing signed by us.
        </p>

        <div class="callout marquee">
          <h3>CREDIT REPORTING.</h3>
          <p>
            <strong>We may report information about your Loan to credit bureaus. Late payments, missed payments or
            other defaults on your Loan may be reflected in your credit report.</strong>
          </p>
          <p>
            <strong>If you believe that any information about your Loan that we have furnished to a consumer reporting
            agency is inaccurate, or if you believe that you have been the victim of identity theft in connection
            with any Loan made by us, write to us at NC Financial Solutions of Alabama, LLC. Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. In your letter (i) provide
            your name and Loan number, (ii) identify the specific information that is being disputed, (iii) explain
            the basis for the dispute and (iv) provide any supporting documentation you have that substantiates the
            basis of the dispute. If you believe that you have been the victim of identity theft, submit an identity
            theft affidavit or identity theft report.</strong>
          </p>
        </div>

        <h3>GOVERNING LAW AND EXECUTION.</h3>
        <p>
          <strong>This Agreement (other than the Arbitration Provision) and all related disputes are governed by the laws of
          the United States and the State of Alabama. Except as provided in the <a href="#arbitration_provision">ARBITRATION PROVISION</a>,
          if any provision of this Agreement is determined to be void or unenforceable under any applicable law, rule or regulation,
          all other provisions of this Agreement will remain valid and enforceable. This Agreement is a final expression
          of the agreement between you and us and this Agreement may not be contradicted by evidence of any alleged oral
          agreement. The terms of this Agreement may not be changed except in a writing signed by you and us. No change
          shall release any party from liability unless otherwise expressly stated in writing. All of our rights are cumulative.
          If we approve this Agreement, then you agree that this Agreement will be binding and enforceable as to both parties.</strong>
        </p>

        <h3>CORRESPONDENCE.</h3>
        <p>
          All notices to us must be sent via fax at (855) 878-1068 or e-mail at <a href="mailto:support@netcredit.com">
          support@netcredit.com</a> (“the Notice Address”), with such attention as may be specified in this Agreement. To the
          extent permitted under applicable law, any notice you send us will not be effective until we receive and have had a reasonable
          opportunity to act on such notice. Any written or electronic correspondence we send to you will be effective and deemed delivered
          when sent in accordance with any authorization for electronic communications you execute or mailed to you at your mail address,
          as it appears on our records.
        </p>

        <h3>BANKRUPTCY.</h3>
        <p>
          All bankruptcy notices and related correspondence to us must be sent to the Notice Address, Attn: Bankruptcy Notice.
          You promise that you have no current intent to file any bankruptcy petition and have not consulted a bankruptcy
          attorney in the past six months.
        </p>

        <h3>CHANGE IN CIRCUMSTANCES.</h3>
        <p>
          You must notify us of any changes to your name, mailing or email address, or phone number (including your cell phone number)
          within fifteen (15) days by writing us at the Notice Address or going on www.netcredit.com (the “Website”).
          We will rely on your mail and email addresses as they appear on our records for any and all communications
          we send you by mail or email unless and until either you or, in the case of your mailing address, the U.S. Postal Service,
          notifies us of a change of address and we have had a reasonable opportunity to act on such notice.
        </p>

        <h3>PARTIAL PAYMENTS MARKED PAYMENT IN FULL; SETTLEMENTS.</h3>
        <p>
          Any check or other payment you send us for less than the total outstanding balance due under this Agreement that
          is marked “payment in full” or with any similar language or that you otherwise tender as full satisfaction of a
          disputed amount must be sent to NetCredit, P.O. Box 206766, Dallas, TX 75320-6766. We may deposit any such
          payment without such deposit effecting a satisfaction of the disputed amount. Any settlement of your Account balance
          for less than what is owed requires our written agreement.
        </p>

        <h3>INADVERTENT OVERCHARGES.</h3>
        <p>
          It is not our intention to charge any interest, fees or other amounts in excess of those permitted by applicable
          law or this Agreement. If any interest, fee or other amount is determined to be in excess of that permitted by
          applicable law or this Agreement, the excess amount will be applied to reduce the outstanding balance due under
          this Agreement or, if there is no outstanding balance, will be refunded to you.
        </p>

        <h3>CREDIT REPORTS; CREDIT HISTORY.</h3>
        <p>You authorize us to obtain credit and other reports about you from time to time.</p>

        <h3>CONTACTING YOU; PHONE AND TEXT MESSAGES; CALL RECORDING.</h3>
        <p>
          You authorize us and our assignees and the affiliates, agents, representatives, assigns and service providers of
          us and our assignees (collectively, the “Messaging Parties”) to contact you using automatic telephone dialing systems,
          artificial or prerecorded voice message systems, text messaging systems and automated email systems in order to
          provide you with information about this Agreement, including information about upcoming payment due dates, missed
          payments and returned payments. You authorize  the Messaging Parties to make such contacts using any telephone numbers
          (including wireless, landline and VOIP numbers) or email addresses you supply to the Messaging Parties in connection
          with this Agreement, the Messaging Parties' servicing and/or collection of amounts you owe the Messaging Parties or
          any other matter. You understand that anyone with access to your telephone or email account may listen to or read the
          messages the Messaging Parties leave or send you, and you agree that the Messaging Parties will have no liability for
          anyone accessing such messages. You further understand that, when you receive a telephone call, text message or email,
          you may incur a charge from the company that provides you with telecommunications, wireless and/or Internet services,
          and you agree that the Messaging Parties will have no liability for such charges. You expressly authorize the Messaging
          Parties to monitor and record your calls with the Messaging Parties.
        </p>

        <h3>ASSIGNMENT.</h3>
        <p>
          We may sell or otherwise transfer our rights under this Agreement and amounts owed by you to another creditor at any time.
          If we do, this Agreement will remain in effect. You may not transfer your rights under this Agreement. Any attempt to do
          so will be void.
        </p>

        <h3>MILITARY LENDING ACT.</h3>
        <h3 style="text-decoration: underline;">General</h3>
        <p>
          <strong>The Military Lending Act provides protections for certain members of the Armed
          Forces and their dependents (“Covered Borrowers”). The provisions of this section
          apply to Covered Borrowers. If you would like more information about whether you
          are a Covered Borrower and whether this section applies to you, please contact us at
          (877) 392-2014.</strong>
        </p>

        <h3 style="text-decoration: underline;">Statement of MAPR</h3>
        <p>
          <strong>Federal law provides important protections to members of the Armed Forces and their dependents relating to extensions
          of consumer credit. In general, the cost of consumer credit to a member of the Armed Forces and his or her dependent
          may not exceed an Annual Percentage Rate of 36 percent. This rate must include, as applicable to the credit transaction
          or account: (1) the costs associated with credit insurance premiums; (2) fees for ancillary products sold in connection
          with the credit transaction; (3) any application fee charged (other than certain application fees for specified credit
          transactions or accounts); and (4) any participation fee charged (other than certain participation fees for a credit
          card account).</strong>
        </p>

        <h3 style="text-decoration: underline;">Oral Disclosures</h3>
        <p>
          <strong>Before signing this Agreement, in order to hear important disclosures and payment information about
          this Agreement, please call (877) 392-2014.</strong>
        </p>

        <h3 style="text-decoration: underline;">Covered Borrowers Savings Clause</h3>
        <p>
          <strong>
            The provisions of this Paragraph apply only to a “Covered Borrower” as that term is defined by 32 C.F.R.
            § 232.3(g).  If any contract provision not identified herein is contrary to the rights and protections
            afforded to you by Federal law pursuant to 10 U.S.C. § 987 and its implementing regulations, including,
            but not limited to 32 C.F.R. § 232.8, then the conflicting provisions or proscribed terms are inoperative,
            and shall have no force and effect.  However, all remaining contract terms and provisions not proscribed
            or prohibited shall remain in full force and effect.
          </strong>
        </p>

        <h3>MISCELLANEOUS.</h3>
        <p>
          The section headings used in this Agreement are for convenience of reference only and do not in any way limit or
          define your or our rights or obligations hereunder. If any provision of this Agreement is invalid or unenforceable
          under any law, rule or regulation, it shall not affect the validity or enforceability of any other provision of this
          Agreement. Except as otherwise provided in this Agreement or as required by applicable law, we will not be responsible
          for any claim or defense you may have against any third party that arises out of or in connection with this Agreement.
        </p>

        <h3>CLASS ACTION WAIVER.</h3>
<p>
  <strong>
    Unless prohibited by applicable law, if you sign this Agreement, you waive your right to ask a court or an arbitrator
    to allow you to pursue any claims on a class action basis or in a representative capacity on behalf of the general
    public, other borrowers or other persons similarly situated. Furthermore, claims brought by you against us or by us
    against you may not be joined or consolidated with claims brought by or against someone other than you, unless
    otherwise agreed to in writing by all parties. In the event of a conflict between this Class Action Waiver provision
    and the Arbitration Provision, the Arbitration Provision shall control.
  </strong>
</p>

<h3 id="arbitration_provision">ARBITRATION PROVISION.</h3>
<p>
  By signing below, you agree to this Arbitration Provision ("Arbitration Provision"), unless you opt out by the
  process described below. The Arbitration Provision is in question and answer form to make it easier to
  understand. Even so, this Arbitration Provision is part of this Agreement and is legally binding.
</p>
<div class="table-responsive">
  <table class="arbitration-provision-table table table-bordered">
    <tbody><tr>
      <th>Question</th>
      <th>Short Answer</th>
      <th>Further Detail</th>
    </tr>
    <tr>
      <th colspan="3">
        Background and Scope.
      </th>
    </tr>
    <tr>
      <td>What is arbitration?</td>
      <td>An alternative to court</td>
      <td>In arbitration, a neutral third-party arbitrator ("Arbitrator") resolves Disputes in an informal hearing on an individual basis.</td>
    </tr>
    <tr>
      <td>Is it different from court and jury trials?</td>
      <td>Yes</td>
      <td>
        The hearing is private. There is no jury. It is usually less formal, faster and less expensive than a
        court lawsuit. Pre-hearing fact finding is limited. Appeals are limited. Courts rarely overturn arbitration awards.
      </td>
    </tr>
    <tr>
      <td>Can you opt-out of this Arbitration Provision?</td>
      <td>Yes, within 60 days</td>
      <td>
        If you do not want this Arbitration Provision to apply, you must send us a signed notice within 60
        calendar days after you sign your Agreement. You must send the notice in writing (and not electronically)
        to NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. Provide your
        name, address and account number. State that you "opt out" of the Arbitration Provision. Opting out will
        not affect the other provisions of this Agreement. If you opt out, it will apply only to that agreement.
      </td>
    </tr>
    <tr>
      <td>What is this Arbitration Provision about?</td>
      <td>The parties' agreement to arbitrate Disputes</td>
      <td>
        Unless prohibited by applicable law and unless you opt out, you and we agree that you or we may elect to
        arbitrate individually or require individual arbitration of any "Dispute" as defined below.
      </td>
    </tr>
    <tr>
      <td>Who does the Arbitration Provision cover?</td>
      <td>You, us and certain "Related Parties"</td>
      <td>
        This Arbitration Provision governs you and us, your heirs and our agents, successors, and assigns. It
        also covers certain "Related Parties": (1) our parents, subsidiaries, affiliates and their successors and
        assigns (and those of our agents); (2) our employees, directors, officers, shareholders, members and
        representatives (and those of our agents); and (3) any person or company that is involved in a Dispute you
        pursue at the same time you pursue a related Dispute with us.
      </td>
    </tr>
    <tr>
      <td>What Disputes does the Arbitration Provision cover?</td>
      <td>All Disputes (except certain Disputes about this Arbitration Provision)</td>
      <td>
        This Arbitration Provision governs all "Disputes" that would usually be decided in court and are between
        us (or any Related Party) and you. In this Arbitration Provision, the word "Disputes" has the broadest
        reasonable meaning. It includes all claims even indirectly related to your Line of Credit or this
        Agreement (including without limitation your application, your account, past agreements, information you
        previously gave us, extensions, renewals, refinancings, payment plans, collections, privacy and customer
        information) or the relationships between you and us and/or "Related Parties" resulting therefrom.
        "Disputes" includes, but is not limited to, initial claims, counterclaims, cross-claims and third-party
        claims, and claims based on any constitution, statute, regulation, ordinance, common law rule (including
        rules relating to contracts, torts, negligence, fraud or other intentional wrongs) and equity. It includes
        claims that seek relief of any type, including damages and/or injunctive, declaratory or other equitable
        relief. It includes claims related to the validity in general of this Agreement. However, it does not
        include disputes about the validity, coverage or scope of this Arbitration Provision or any part of this
        Arbitration Provision. (This includes a Dispute about the rules against class arbitration and against an
        Arbitrator issuing relief to anyone except you, us and Related Parties.) All such disputes are for a court
        and not an Arbitrator to decide. But a Dispute about this Agreement as a whole is for an Arbitrator, not a
        court, to decide.
      </td>
    </tr>
    <tr>
      <td>Who handles the arbitration?</td>
      <td>Usually AAA</td>
      <td>
        Arbitrations are conducted under this Arbitration Provision and the rules of the arbitration
        administrator in effect when the arbitration is started. The arbitration administrator will be: The
        American Arbitration Association ("AAA"), 120 Broadway, 21st Floor, New York, NY 10271,
        <a href="https://adr.org" target="_blank">www.adr.org</a>.
        <br>
        If AAA is unable or unwilling to serve as administrator, and the parties are unable to agree on a
        substitute arbitration forum, a court with jurisdiction will pick the administrator. No arbitration may be
        administered without our consent by any administrator that would permit a class arbitration under this
        Arbitration Provision. The Arbitrator will be selected under the administrator's rules. However, the
        Arbitrator must be a lawyer with at least ten years of experience or a retired judge unless you and we
        otherwise agree.
      </td>
    </tr>
    <tr>
      <td>Can Disputes be litigated?</td>
      <td>Sometimes</td>
      <td>
        Either party may bring a lawsuit if the other party does not demand arbitration. We will not demand
        arbitration of any lawsuit you bring as an individual action in small-claims court or an equivalent court,
        so long as the Dispute is pending only in that court. Both parties may use lawful self-help remedies. This
        includes set-off or repossession and sale of any collateral. Both parties may seek remedies which don't
        claim money damages. This includes pre-judgment seizure, injunctions, or equitable relief. Even if all
        parties have opted to litigate a Dispute in court, you or we may elect arbitration with respect to any
        Dispute made by a new party or any Dispute later asserted by a party in that or any related or unrelated
        lawsuit (including a Dispute initially asserted on an individual basis but modified to be asserted on a
        class, representative or multi-party basis). Nothing in that litigation shall constitute a waiver of any
        rights under this Arbitration Provision.
      </td>
    </tr>
    <tr>
      <td>Are you and we giving up any rights?</td>
      <td>Yes</td>
      <td>For Disputes that are arbitrated under this Arbitration Provision, you and we give up our rights to:
        <ol>
          <li>Have juries decide Disputes.</li>
          <li>Have courts, other than small-claims courts, decide Disputes.</li>
          <li>Serve as a private attorney general or in a representative capacity in court or in arbitration.</li>
          <li>Join a Dispute that you, we or Related Parties have with a dispute that others have.</li>
          <li>Bring or be a class member in a class action in court or in a class arbitration.</li>
        </ol>
        The arbitrator shall have no authority to conduct any arbitration inconsistent with this section or to
        issue any relief that applies to any person or entity except you or us or Related Parties individually.
      </td>
    </tr>
    <tr>
      <td>Are you waiving class action rights?</td>
      <td>Yes.</td>
      <td>
        COURTS AND ARBITRATORS WON'T ALLOW CLASS ACTIONS. You <u>waive</u> your rights to be in a class action in <u>court
        or in arbitration</u>, as a representative and a member. Only individual arbitration, or small-claims courts,
        will resolve Disputes. You waive your right to bring representative claims including private attorney
        general claims. Moreover, unless consented to in writing by all parties to the arbitration, no party to
        the arbitration may join, consolidate, or otherwise bring claims for or on behalf of two or more
        individuals or unrelated corporate entities in the same arbitration unless those persons are parties to a
        single transaction.
        <br>
        Unless consented to in writing by all parties to the arbitration, an award in arbitration shall determine
        the rights and obligations of the named parties only, and only with respect to the Dispute in arbitration,
        and shall not (i) determine the rights, obligations, or interests of anyone other than a named party, or
        resolve any Dispute of anyone other than a named party; nor (ii) make an award for the benefit of, or
        against, anyone other than a named party. No arbitration group or Arbitrator shall have the power or
        authority to waive, modify, or fail to enforce this section, and any attempt to do so, whether by rule,
        policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any challenge to the
        validity of this section shall be determined exclusively by a court and not by the administrator or any
        Arbitrator.
      </td>
    </tr>
    <tr>
      <td>Can you or another consumer start a class arbitration?</td>
      <td>No</td>
      <td>
        The Arbitrator is not allowed to handle any Dispute on a class or representative basis. All Disputes
        subject to this Arbitration Provision must be decided in an individual arbitration or an individual
        small-claims action. You may not pursue any type of collective action or class action against us in
        arbitration.
      </td>
    </tr>
    <tr>
      <td>What law applies?</td>
      <td>The Federal Arbitration Act ("FAA")</td>
      <td>
        This Agreement and the Line of Credit involve interstate commerce. Thus, the FAA governs this
        Arbitration Provision. The Arbitrator must apply substantive law consistent with the FAA. The Arbitrator
        must honor statutes of limitation and privilege rights. The Arbitrator is authorized to award all remedies
        permitted by applicable substantive law, including, without limitation, compensatory, statutory and
        punitive damages (subject to constitutional limits that would apply in court), declaratory, injunctive and
        other equitable relief, and attorneys' fees and costs. In the event of any conflict or inconsistency
        between this Arbitration Provision and the administrator's rules or the Agreement, this Arbitration
        Provision will govern.
      </td>
    </tr>
    <tr>
      <td>Will this Clause continue to govern?</td>
      <td>Yes, unless otherwise agreed.</td>
      <td>
        The Arbitration Provision stays effective unless the parties sign an agreement stating it doesn't or you
        timely opt out by the process described below. The Clause governs if you rescind the transaction. It
        governs if you default, renew, prepay, or pay. It governs if your contract is discharged through
        bankruptcy. The Arbitration Provision remains effective, despite a transaction's termination, amendment,
        expiration, or performance.
      </td>
    </tr>
    <tr>
      <th colspan="3">
        Process.
      </th>
    </tr>
    <tr>
      <td>What must a party do before starting a lawsuit or arbitration?</td>
      <td>Send a written Dispute notice and work to resolve the Dispute</td>
      <td>
        We can try to resolve Disputes if you call us at <a href="tel:8773922014">877-392-2014</a>. If this
        doesn't resolve the Dispute, before starting a lawsuit or arbitration, the complaining party must give the
        other party written notice of the Dispute. The notice must explain in reasonable detail the nature of the
        Dispute and any supporting facts. If you are the complaining party, you must send the notice in writing
        (and not electronically) to NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL
        60604. You or an attorney you have personally hired must sign the notice and must provide the account
        number and a phone number where you (or your attorney) can be reached. A letter from us to you will serve
        as our written notice of a Dispute. Once a Dispute notice is sent, the complaining party must give the
        other party a reasonable opportunity over the next 30 days to resolve the Dispute on an individual basis.
        During that period, either you or we may request an individualized discussion (by telephone or
        videoconference) regarding settlement (“Informal Settlement Conference”). The parties must work together
        in good faith to select a mutually agreeable time during business hours for the Informal Settlement
        Conference (which can be after the 30-day period). You and our representative must personally participate
        in the Informal Settlement Conference, unless otherwise agreed in writing. You and our attorneys (if any)
        also can participate.
        <br>
        Any applicable statute of limitations or contractual limitations periods will be tolled during the
        “Informal Resolution Period,” which is the period between when a Dispute notice is received by the other
        party and the later of (i) 30 days; or (ii) the date the Informal Settlement Conference is completed.
      </td>
    </tr>
    <tr>
      <td>How does an arbitration start?</td>
      <td>Mailing a notice</td>
      <td>
        If the parties do not reach an agreement to resolve the Dispute during the Informal Resolution Period,
        the complaining party may commence a lawsuit or an arbitration, subject to the terms of this Arbitration
        Provision. To start an arbitration, the complaining party picks the administrator and follows the
        administrator's rules. If one party begins or threatens a lawsuit, the other party can demand arbitration.
        This demand can be made in court papers, such as a motion to compel arbitration. Once an arbitration
        demand is made, no lawsuit can be brought and any existing lawsuit must stop unless a court rules otherwise.
      </td>
    </tr>
    <tr>
      <td>Will any hearing be held nearby?</td>
      <td>Yes</td>
      <td>The Arbitrator may decide that an in-person hearing is unnecessary and that he or she can resolve a
        Dispute based on written filings and/or a conference call. Each party shall have the right to file
        dispositive motions, such as motions to dismiss or for summary judgment. However, any in-person
        arbitration hearing shall take place either in Chicago, IL or in your county and state of residence.
      </td>
    </tr>
    <tr>
      <td>What about appeals?</td>
      <td>Very limited</td>
      <td>
        Appeal rights under the FAA are very limited. The Arbitrator's award will be final and binding. However,
        if the amount in controversy exceeds $50,000, and if permitted by the AAA’s rules, you or we can, within
        14 days after the entry of the award by the arbitrator, appeal the award to a three-arbitrator panel
        administered by the AAA. The panel shall reconsider anew any aspect of the initial award requested by the
        appealing party. The decision of the panel shall be by majority vote. Reference in this Arbitration
        Provision to “the arbitrator” shall mean the panel if an appeal of the arbitrator’s decision has been
        taken. The costs of such an appeal will be borne in accordance with the sections below regarding fees and
        costs. Any final decision of the appeal is subject to judicial review only as provided under the FAA.
        <br>
        Any appropriate court may enter judgment upon the Arbitrator's award. No arbitration award involving the
        parties will have any preclusive effect as to issues or claims in any dispute involving anyone who is not
        a party to the arbitration, nor will an arbitration award in prior disputes involving other parties have
        preclusive effect in an arbitration between the parties to this Arbitration Provision.
      </td>
    </tr>
    <tr>
      <th colspan="3">
        Arbitration Fees and Awards.
      </th>
    </tr>
    <tr>
      <td>Who bears arbitration fees?</td>
      <td>Usually, we do.</td>
      <td>
        We will pay all filing, administrative, hearing and Arbitrator fees if you act in good faith, cannot get a
        waiver of such fees and ask us to pay. We will always pay amounts required under applicable law or the
        administrator's rules or if payment is required to enforce this Arbitration Provision.
      </td>
    </tr>
    <tr>
      <td>When will we cover your legal fees and costs?</td>
      <td>If you win</td>
      <td>
        If you win an arbitration, we will pay the reasonable fees and costs for your attorneys, experts and
        witnesses. We will also pay these amounts if required under applicable law or the administrator's rules or
        if payment is required to enforce this Arbitration Provision. The Arbitrator shall not limit his or her
        award of these amounts because your Dispute is for a small amount.
      </td>
    </tr>
    <tr>
      <td>Will you ever owe us for arbitration or attorneys' fees?</td>
      <td>Only for bad faith</td>
      <td>
        The Arbitrator can require you to pay our fees if (and only if): (1) the Arbitrator finds that you have
        acted in bad faith (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)); and
        (2) this power does not make this Arbitration Provision invalid.
      </td>
    </tr>
    <tr>
      <td>Can an award be explained?</td>
      <td>Yes</td>
      <td>
        A party may request details from the Arbitrator within 14 days of the ruling. The Arbitrator will
        determine whether to grant such request.
      </td>
    </tr>
    <tr>
      <td>What happens if a part of this Arbitration Provision cannot be enforced?</td>
      <td>It depends</td>
      <td>
        If any portion of this Arbitration Provision cannot be enforced, the rest of the Arbitration Provision
        will continue to apply, except that (A) this Arbitration Provision (except for this sentence) will be void
        if a court rules that the Arbitrator can decide a Dispute on a class basis and the court's ruling is not
        reversed on appeal, and (B) if a claim is brought seeking public injunctive relief and a court determines
        that the restrictions in this Arbitration Provision prohibiting the arbitrator from awarding relief on
        behalf of third parties are unenforceable with respect to such claim (and that determination becomes final
        after all appeals have been exhausted), the claim for public injunctive relief will be determined in court
        and any individual claims seeking monetary relief will be arbitrated. In such a case the parties will
        request that the court stay the claim for public injunctive relief until the arbitration award pertaining
        to individual relief has been entered in court. In no event will a claim for public injunctive relief be
        arbitrated.
      </td>
    </tr>
  </tbody></table>
</div>

      </div> 
  </div>

  <div class="article electronic-consent" id="contract-signature">
    <h2>BANK ACCOUNT INFORMATION, ELECTRONIC SIGNATURES AND CONSENTS.</h2>
    <p>By entering your bank account information, clicking the "I AGREE" button below and providing any additional information as may be requested below, you understand and acknowledge that you are taking the following four (4) separate actions:</p>

    

<div class="section ach-authorization panel panel-default clearfix signature-form">
  <h3 class="panel-heading">1. BANK ACCOUNT INFORMATION</h3>
  <div class="sig-form-copy panel-body bank-account-information">
    <p id="bank_information_script">
      </p><p>
        Enter
        the personal checking account where the majority of your income is deposited. This is also the
        Deposit Account where your funds will be deposited upon final approval

        
          and your payments will be withdrawn on your due dates
        

        according to the terms and conditions of the <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>.

        
          Do not enter a checking account that is already associated with an outstanding NetCredit loan.
        

        No funds will be transferred to or from your Deposit Account if your Loan application is withdrawn
        or declined during loan processing. In some cases, an electronic bank statement or similar information
        will be required in order to process your Loan application and make a final approval decision.
      </p>
    <p></p>
  </div>

  <div class="sig-form-data panel-body">
    <div id="signature_bank_form" class="form-fieldset">

      
        
        <div id="bank-info-edit-view">
          <label for="signature_bank_routing_number">
            9-Digit Routing Number
          </label>
          
          <input type="text" id="signature_bank_routing_number" class="input-text form-control" name="signature[bank_account][bank_routing_number]" size="4" maxlength="9" autocorrect="off">

          <label for="signature_bank_account_number">
            Checking Account Number
          </label>
          <input type="text" id="signature_bank_account_number" class="input-text form-control" name="signature[bank_account][bank_account_number]" size="4" maxlength="17" autocorrect="off">

          <label for="signature_bank_account_number_confirmation">
            Verify Your Account Number
          </label>
          <input type="text" id="signature_bank_account_number_confirmation" class="input-text form-control" name="signature[bank_account][bank_account_number_confirmation]" size="4" maxlength="17" autocorrect="off">
        </div>
      
    </div>
  </div>
</div>


    <div class="section ach-authorization panel panel-default clearfix signature-form">
      <h3 class="panel-heading">2. PAYMENT AUTHORIZATION</h3>
      <div class="sig-form-copy panel-body">
        <p>
          By typing in the last four digits of your Social Security number below and clicking the “I Agree” button below,
          you are electronically signing the Payment Authorization and you certify that you have fully read and understood
          the sections of this Agreement captioned "<a href="#payment_authorization">PAYMENT AUTHORIZATION</a>" and
          "<a href="#authorization_agreement">ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS</a>", you agree to comply with, and be bound by,
          their terms and you agree and understand that you are authorizing us to credit your Deposit Account with the proceeds of your Loan
          
            and to seek payment from this Deposit Account for the amounts owed
          
          under this Agreement as set forth in those sections.
        </p>
      </div>

      <div class="sig-form-data panel-body">
        <div class="form-fieldset">
          <div class="form-field text-field">
            <label for="signature_ssn_last4">
              Enter the last 4 digits of your Social Security Number
            </label>
            
              <div class="input-text">0418</div>
            
          </div>
          <div class="fieldset-copy">
            <p>Please note that we separately authenticate the above electronic signature when you click the "I Agree" button below</p>
          </div>
        </div>
      </div>
    </div>


    <div class="section agreement-terms panel panel-default clearfix signature-form">
      <h3 class="panel-heading">
        3.
        AGREEMENT TO INSTALLMENT LOAN AGREEMENT
      </h3>
      <div class="sig-form-copy panel-body">
        <p>
          By entering your name and social security number below and clicking the “I AGREE” button below, you are electronically signing this Agreement
          and acknowledging that (a) you understand that NetCredit would be your lender under the above Agreement; (b) the Agreement was filled in
          before you signed it and that you have reviewed the entire Agreement, including the <a href="#truth_in_lending">FEDERAL TRUTH-IN-LENDING DISCLOSURES</a>;
          (c) you have reviewed and agree to NetCredit's <a href="https://www.netcredit.com/privacy-policy" target="_blank">PRIVACY POLICY</a>; (d) you
          acknowledge that your right to file suit against us for any claim or dispute regarding this Agreement is limited by the
          <a href="#waiver_of_jury_trial">WAIVER</a> and <a href="#arbitration_provision">ARBITRATION</a> provisions; (e) you represent that you are not
          a debtor under any proceeding in bankruptcy and have no intention to file a petition for relief under any chapter of the United States Bankruptcy
          Code; and (f) you acknowledge that you have read and understood all of the terms of this Agreement, including the provisions mentioned above and
          you agree to comply with, and be bound by, all of those terms.
        </p>
        <p>
          Please note that when you click the 'I Agree' button below, we authenticate your electronic signature and we also separately authenticate your
          electronic signature for the <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>
          that you separately and voluntarily entered above.
        </p>
        <p>
          YOU SHOULD PRINT AND RETAIN A COPY OF THIS
          AGREEMENT FOR YOUR RECORDS. AN ELECTRONIC COPY WILL BE MAINTAINED ON THIS WEBSITE IN YOUR PASSWORD PROTECTED CUSTOMER HISTORY.</p>
        <p>
          <strong>Any comments or questions may be directed to our Customer Comment Line at the following toll-free number: (877) 392-2014 or via e-mail to
          <a href="mailto:support@netcredit.com">support@netcredit.com</a>.</strong>
        </p>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="text-danger">
          <p><strong>CAUTION: IT IS IMPORTANT THAT YOU THOROUGHLY READ THE AGREEMENT BEFORE YOU SIGN IT.</strong></p><p>
        </p></div>
        
          <p>
            <strong>THIS AGREEMENT INCLUDES AN ARBITRATION PROVISION. UNLESS YOU PROPERLY REJECT THE ARBITRATION PROVISION OR ARE
            A MEMBER OF THE ARMED FORCES OR A DEPENDENT OF SUCH A MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT, THE
            ARBITRATION PROVISION WILL HAVE A SUBSTANTIAL EFFECT ON YOUR RIGHTS IN THE EVENT OF A DISPUTE WITH US. FOR EXAMPLE,
            IF WE ELECT TO REQUIRE YOU TO ARBITRATE ANY CLAIM, YOU WILL NOT HAVE THE RIGHT TO A JURY TRIAL OR THE RIGHT TO
            PARTICIPATE IN A CLASS ACTION IN COURT OR IN ARBITRATION.</strong>
          </p>
        
      </div>

      
        
      
    </div>

    <div class="section consent-to-communication panel panel-default clearfix">
      <h3 class="panel-heading">4. CONSENT TO ELECTRONIC SIGNATURES</h3>
      <p class="panel-body">
        By clicking the “I AGREE” button below, you have consented to sign this Agreement using an electronic signature, you intend:
        (i) your electronic signature to be an electronic signature under applicable federal and state law; (ii) any printout of an
        electronic record of this Agreement and related notices to be an original document; (iii) to conduct business with Lender by
        electronic records and electronic signatures; and (iv) this Agreement will not be governed by Article 3 of the Uniform Commercial Code.
      </p>
    </div>

    
      <div>
        <table class="finance-charge">
          <tbody><tr><th>
            <p><strong>CAUTION—IT IS IMPORTANT THAT YOU THOROUGHLY READ THE AGREEMENT BEFORE YOU SIGN IT.</strong></p>
          </th>
          <th>
            <p>
              <strong>NOTICE TO BORROWER<br>
              (a) DO NOT SIGN THIS AGREEMENT BEFORE YOU READ THE WRITING ABOVE, EVEN IF OTHERWISE ADVISED.<br>
              (b) DO NOT SIGN THIS AGREEMENT IF IT CONTAINS ANY BLANK SPACES.<br>
              (c) YOU ARE ENTITLED TO AN EXACT COPY OF ANY AGREEMENT YOU SIGN.<br>
              (d) YOU HAVE THE RIGHT AT ANY TIME TO PAY IN ADVANCE THE UNPAID BALANCE DUE UNDER THIS AGREEMENT WITHOUT PENALTY.</strong>
            </p>
          </th>
        </tr></tbody></table>
      </div>
      <br>
    

    

    <div id="consent-to-credit-report-hard-pull">
      <p>
        By clicking "I Agree" below, I understand that an inquiry will be
        run on my credit report, which may impact my credit score.
      </p>
    </div>
  </div>
</div>
</body></html>`;
