import React from 'react';

import { Container } from '@consumer-frontend/shared-ui';

import { dateFromTimestamp } from '../../utils';

import '@consumer-bootstrap/brands/netcredit/message-detail.scss';

/**
 * @property timestamp Timestamp comes in from the backend with this format: November 8, 2024 8:21 AM CST
 */
export type NcMessageDetailHeaderPropsT = {
  sender: string;
  subject: string;
  timestamp: string;
};

export type NcMessageDetailHeaderT = React.FC<NcMessageDetailHeaderPropsT>;

export const NcMessageDetailHeader: NcMessageDetailHeaderT = ({
  sender,
  subject,
  timestamp,
}) => {
  return (
    <Container className="detail-header">
      <span className="detail-header-label h6">Subject</span>
      <p className="detail-header-value" data-chromatic="ignore">{subject}</p>
      <span className="detail-header-label h6">Sent</span>
      <p className="detail-header-value" data-chromatic="ignore">{dateFromTimestamp(timestamp)}</p>
      <span className="detail-header-label h6">From</span>
      <p className="detail-header-value" data-chromatic="ignore">{sender}</p>
    </Container>
  );
};
