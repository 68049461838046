import React from 'react';

import { useAtom } from 'jotai';

import {
  useAccountInfo,
} from '@consumer-frontend/nc-api';
import {
  ListGroup,
  ListGroupItem,
  Nav,
} from '@consumer-frontend/shared-ui';

import { NavSelection } from './NavSelection';
import { accountIdAtom } from '../../store';
import { NcError } from '../NcError';

import '@consumer-bootstrap/brands/netcredit/nav.scss';
import '@consumer-bootstrap/netcredit.scss';

type NcNavPropsT = {
  responsiveFlexClass: 'd-flex' | 'd-md-flex';
  displayNone?: boolean;
  hasBoxShadow?: boolean;
};

export type NcNavT = React.FC<NcNavPropsT>;

export const NcNav: NcNavT = ({
  displayNone,
  responsiveFlexClass,
  hasBoxShadow,
}) => {
  const navClasses = [
    displayNone ? 'd-none' : '',
    responsiveFlexClass,
    'container',
    'flex-column',
    'justify-content-between',
    hasBoxShadow ? 'shadow-sm' : 'shadow-none',
    'rounded',
    'nc-nav',
  ].join(' ');

  const [accountId] = useAtom(accountIdAtom);

  const {
    accountInfo,
    error,
    isLoading,
  } = useAccountInfo(accountId);

  return (
    <Nav className={navClasses} id="nc-nav" navbarScroll>
      <ListGroup className="list-group" variant="flush">
        {isLoading && 'Loading...'}
        {error && <NcError message={error.message} />}
        {accountInfo && (
          <ListGroupItem className="list-group-item pb-5">
            <h3 className="mb-1">Hello, {`${accountInfo.first_name}`}</h3>
            <strong>Account # {accountInfo.account_id}</strong>
          </ListGroupItem>
        )}
        <NavSelection accountInfo={accountInfo} />
      </ListGroup>
    </Nav>
  );
};
