import React from 'react';

import { Image as BootstrapImage } from 'react-bootstrap';

type ImagePropsT = {
  alt: string;
  src: string;
  bsPrefix?: string;
  className?: string;
  fluid?: boolean;
  onContextMenu?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  rounded?: boolean;
  roundedCircle?: boolean;
  style?: React.CSSProperties;
  thumbnail?: boolean;
};
export type ImageT = React.FC<ImagePropsT>;

export const Image: ImageT = ({ ...props }: ImagePropsT) => (
  <BootstrapImage {...props} />
);
