import { useCallback } from 'react';

import { externalLinks } from '@consumer-frontend/nc-api';
import { Image, Link } from '@consumer-frontend/shared-ui';

import { externalLinkModalConfig } from '../../store/constants';
import { NcModalAnchor } from '../NcModal/NcModalAnchor';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

// eslint-disable-next-line max-len
export const mcafeeTrustedSiteAltText = 'McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams';

export const NcSecurityWidgets = () => {
  const preventCopying = useCallback(
    (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      ev.preventDefault();

      // eslint-disable-next-line no-alert
      window.alert('Copying Prohibited by Law - McAfee SECURE is a Trademark of McAfee, Inc.');

      return false;
    },
    [],
  );

  return (
    <div className="security-widgets">
      <NcModalAnchor
        {...externalLinkModalConfig}
        modalOnClick={() => window.open(externalLinks.mcafee, '_blank')}
      >
        <Link
          target="_blank"
          to={externalLinks.mcafee}
        >
          <Image
            src="//images.scanalert.com/meter/netcredit.com/13.gif"
            alt={mcafeeTrustedSiteAltText}
            onContextMenu={preventCopying}
          />
        </Link>
      </NcModalAnchor>
    </div>
  );
};
