import React from 'react';

import { Spinner } from 'react-bootstrap';

export type LoadingSpinnerPropsT = {
  size?: 'sm';
};

export type LoadingSpinnerT = React.FC<LoadingSpinnerPropsT>;

export const LoadingSpinner: LoadingSpinnerT = ({ ...props }) => (
  <Spinner
    {...props}
    animation="border"
    role="status"
  >
    <span className="visually-hidden-focusable">Loading...</span>
  </Spinner>
);
