import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
} from 'react';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

// @ts-expect-error: The Osano type is injected by the Osano script in the head
const openOsano = () => { if (typeof Osano !== 'undefined') { Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'); } };

export const onOsanoLoaded = (callback: () => void) => {
  const checkOsanoLoad = (): void | NodeJS.Timeout => {
    const scripts = document.querySelectorAll('div[class="osano-cm-window"]');

    return scripts.length > 0 ? callback() : setTimeout(checkOsanoLoad);
  };

  checkOsanoLoad();
};

export const NcOsano: React.FC = () => {
  const osanoKeyHandler: KeyboardEventHandler<HTMLParagraphElement> = useCallback(
    (ev) => ev.key === 'Enter' && openOsano(),
    [],
  );

  // Add Osano script to document head
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cmp.osano.com/AzqiyvTKkRwMZ3Fyr/dad45f30-3721-4c0a-aff0-615449fa0035/osano.js';
    script.async = true;

    document.head.appendChild(script);
  }, []);

  return (
    <p
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      className="as-link on-dark-bg"
      onClick={openOsano}
      // onKeyPress is deprecated but is needed for accessibility and onKeyUp/onKeyDown do not work the way we need them to.
      onKeyPress={osanoKeyHandler}
    >
      Cookie Preferences
    </p>
  );
};
