import { AxiosError } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { apiEndpoints } from '../lib';
import { LoanT } from '../types';

export const useLoan = (accountId: string, loanNumber: string) => {
  const {
    data,
    error,
    isLoading,
  } = useSWRImmutable<LoanT, AxiosError>(
    apiEndpoints.loanSummary(accountId, loanNumber),
  );

  return {
    error,
    isLoading,
    loan: data,
  };
};
