import { Modal as BSModal } from 'react-bootstrap';

import { useTrapFocus } from '../../hooks';

export type ModalPropsT = {
  body: React.ReactNode;
  footer: React.ReactNode;
  onHide: () => void;
  show: boolean;
  title: string;
  className?: string;
};
export type ModalT = React.FC<ModalPropsT>;

export const Modal: ModalT = ({
  body,
  footer,
  show,
  title,
  ...props
}) => {
  const { modalWrapper } = useTrapFocus(show);

  return show && (
    <BSModal {...props} show>
      <div ref={modalWrapper}>
        <BSModal.Header>
          <BSModal.Title>{title}</BSModal.Title>
        </BSModal.Header>
        <hr />
        <BSModal.Body>{body}</BSModal.Body>
        <BSModal.Footer>{footer}</BSModal.Footer>
      </div>
    </BSModal>
  );
};
