import React, { MouseEventHandler, useCallback } from 'react';

import { useAtom } from 'jotai/index';

import { cookieJar, uiRoutes } from '@consumer-frontend/nc-api';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  ListGroupItem,
} from '@consumer-frontend/shared-ui';

import { showMobileNavAtom } from '../../store';
import { NcNavLink } from '../NcNavLink';

import '@consumer-bootstrap/brands/netcredit/nav.scss';
import '@consumer-bootstrap/netcredit.scss';

export type InstallmentNavPropsT = {
  showContract: boolean;
};

export type InstallmentNavT = React.FC<InstallmentNavPropsT>;

export const InstallmentNav: InstallmentNavT = ({ showContract }) => {
  const [, setShowMobileNav] = useAtom(showMobileNavAtom);

  const closeMobileNav: MouseEventHandler = useCallback(
    (ev) => {
      (ev?.currentTarget as HTMLElement)?.blur();

      setShowMobileNav(false);
    },
    [setShowMobileNav],
  );

  const handleSignout = useCallback(
    () => {
      cookieJar.expire();
      setShowMobileNav(false);
    },
    [setShowMobileNav],
  );

  return (
    <>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.accountSummary} onClick={closeMobileNav}>
          <Icon iconName="house-door" />
          <span>Account Home</span>
        </NcNavLink>
      </ListGroupItem>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.makePayment} onClick={closeMobileNav}>
          <Icon iconName="currency-dollar" />
          <span>Make a Payment</span>
        </NcNavLink>
        <Dropdown>
          <DropdownToggle as="button" className="nav-link">
            <Icon iconName="person" />
            <span>My Info</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.personalInfo}
              onClick={closeMobileNav}
            >
              <span>Personal Info</span>
            </DropdownItem>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.bankInfo}
              onClick={closeMobileNav}
            >
              <span>Bank Info</span>
            </DropdownItem>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.employmentInfo}
              onClick={closeMobileNav}
            >
              <span>Employment Info</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ListGroupItem>
      <ListGroupItem className="list-group-item">
        {showContract && (
          <NcNavLink to={uiRoutes.contract} onClick={closeMobileNav}>
            <Icon iconName="file-earmark-text" />
            <span>View Contract</span>
          </NcNavLink>
        )}
        <NcNavLink to={uiRoutes.messages} onClick={closeMobileNav}>
          <Icon iconName="envelope" />
          <span>Message Center</span>
        </NcNavLink>
        <NcNavLink to={uiRoutes.contactUs} onClick={closeMobileNav}>
          <Icon iconName="chat-left" />
          <span>Contact Us</span>
        </NcNavLink>
      </ListGroupItem>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.logOut} onClick={handleSignout}>
          <Icon iconName="box-arrow-right" />
          <span>Sign Out</span>
        </NcNavLink>
      </ListGroupItem>
    </>
  );
};
