import React from 'react';

import { NavbarToggle as BootstrapNavBarToggle } from 'react-bootstrap';

export type NavbarTogglePropsT = {
  'aria-controls'?: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: () => void;
};

export type NavbarToggleT = React.FC<NavbarTogglePropsT>;

export const NavbarToggle: NavbarToggleT = ({
  children,
  ...props
}) => (
  <BootstrapNavBarToggle {...props}>
    {children}
  </BootstrapNavBarToggle>
);
