import React from 'react';

import { ProductTypeT } from '@consumer-frontend/nc-api';

import { useParseContract } from './useParseContract';

import '@consumer-bootstrap/brands/netcredit/contract/contract.scss';

export type NcContractHTMLPropsT = {
  body: string;
  brandSignature: string;
  customerSignature: string;
  productType: ProductTypeT;
};
export type NcContractHTMLT = React.FC<NcContractHTMLPropsT>;

export const NcContractHTML: NcContractHTMLT = ({
  body,
  brandSignature,
  customerSignature,
  productType,
}) => {
  const safeHTML = useParseContract(body, productType);

  return (
    <div id="contract">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: safeHTML }} />
      <pre id="contract-signature">
        {customerSignature}
      </pre>
      {brandSignature && (
        <pre id="brand-signature">
          {brandSignature}
        </pre>
      )}
    </div>
  );
};
