import { SWRConfiguration } from 'swr';

import { apiClient } from './axiosConfig';

export const swrConfig: SWRConfiguration = {
  fetcher: (url: string) => apiClient.get(url).then((res) => res.data),
  onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
    // Only retry up to 3 times.
    if (retryCount >= 3) { return error; }

    // Retry immediately.
    return revalidate({ retryCount });
  },
  revalidateOnFocus: false,
};
