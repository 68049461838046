import React from 'react';

import { NcError } from '@consumer-frontend/nc-components';
import { Container } from '@consumer-frontend/shared-ui';

export type ErrorPagePropsT = {
  message: string;
};
export type ErrorPageT = React.FC<ErrorPagePropsT>;

export const ErrorPage: ErrorPageT = ({ message }) => (
  <Container className="nc-error p-5 d-flex align-items-center justify-content-center flex-column rounded" hasBoxShadow>
    <NcError displaySupportText message={message} />
  </Container>
);
