import { http, HttpResponse } from 'msw';

import { apiEndpoints } from '../../lib';

export const tokenHandlerFail = {
  token: http.post(apiEndpoints.token, () => new HttpResponse(null, {
    status: 500,
    statusText: 'Token refresh failure',
  })),
};
