import React, { MouseEventHandler } from 'react';

import { DropdownItem as BootstrapDropdownItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export type DropdownItemPropsT = {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  onClick?: MouseEventHandler;
  to?: string;
};

export type DropdownItemT = React.FC<DropdownItemPropsT>;

export const DropdownItem: DropdownItemT = ({
  children,
  to,
  ...props
}) => (
  <BootstrapDropdownItem as={Link} {...props} to={to || '#'}>
    {children}
  </BootstrapDropdownItem>
);
