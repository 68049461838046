import React, { MouseEventHandler, useCallback } from 'react';

import { useAtom } from 'jotai/index';

import { cookieJar, uiRoutes } from '@consumer-frontend/nc-api';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  ListGroupItem,
} from '@consumer-frontend/shared-ui';

import { showMobileNavAtom } from '../../store';
import { NcNavLink } from '../NcNavLink';

import '@consumer-bootstrap/brands/netcredit/nav.scss';
import '@consumer-bootstrap/netcredit.scss';

export type LocNavPropsT = {
  openLoanNumber: string;
  showContract: boolean;
  showFeeSaverStatus: boolean;
};

export type LocNavT = React.FC<LocNavPropsT>;

export const LocNav: LocNavT = ({
  openLoanNumber, showFeeSaverStatus, showContract,
}) => {
  const [, setShowMobileNav] = useAtom(showMobileNavAtom);

  const closeMobileNav: MouseEventHandler = useCallback(
    (ev) => {
      (ev?.currentTarget as HTMLElement)?.blur();

      setShowMobileNav(false);
    },
    [setShowMobileNav],
  );

  const handleSignout = useCallback(
    () => {
      cookieJar.expire();
      setShowMobileNav(false);
    },
    [setShowMobileNav],
  );

  const contractsLink = showContract ? uiRoutes.contract : uiRoutes.legacyContract(openLoanNumber);

  return (
    <>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.accountSummary} onClick={closeMobileNav}>
          <Icon iconName="house-door" />
          <span data-text="My Account">My Account</span>
        </NcNavLink>
      </ListGroupItem>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.makePayment} onClick={closeMobileNav}>
          <Icon iconName="currency-dollar" />
          <span>Make a Payment</span>
        </NcNavLink>
        <NcNavLink to={uiRoutes.cashAdvance(openLoanNumber)} onClick={closeMobileNav}>
          <Icon iconName="cash" />
          <span>Request a Cash Advance</span>
        </NcNavLink>
        <NcNavLink to={uiRoutes.loanDetails} onClick={closeMobileNav}>
          <Icon iconName="calculator" />
          <span>Minimum Payment Estimator</span>
        </NcNavLink>
        {showFeeSaverStatus && (
          <NcNavLink to={uiRoutes.feeSaverStatus(openLoanNumber)} onClick={closeMobileNav}>
            <Icon iconName="coin" />
            <span>Fee Saver Status</span>
          </NcNavLink>
        )}
      </ListGroupItem>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.statements(openLoanNumber)} onClick={closeMobileNav}>
          <Icon iconName="file-ruled" />
          <span>View Statements</span>
        </NcNavLink>
        <NcNavLink to={contractsLink} onClick={closeMobileNav}>
          <Icon iconName="file-earmark-text" />
          <span>View Contract</span>
        </NcNavLink>
      </ListGroupItem>
      <ListGroupItem>
        <Dropdown>
          <DropdownToggle as="button" className="nav-link">
            <Icon iconName="person" />
            <span>My Info</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.personalInfo}
              onClick={closeMobileNav}
            >
              <span>Personal Info</span>
            </DropdownItem>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.bankInfo}
              onClick={closeMobileNav}
            >
              <span>Bank Info</span>
            </DropdownItem>
            <DropdownItem
              className="nav-link"
              to={uiRoutes.employmentInfo}
              onClick={closeMobileNav}
            >
              <span>Employment Info</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NcNavLink to={uiRoutes.messages} onClick={closeMobileNav}>
          <Icon iconName="envelope" />
          <span>Message Center</span>
        </NcNavLink>
        <NcNavLink to={uiRoutes.contactUs} onClick={closeMobileNav}>
          <Icon iconName="chat-left" />
          <span>Contact Us</span>
        </NcNavLink>
      </ListGroupItem>
      <ListGroupItem>
        <NcNavLink to={uiRoutes.logOut} onClick={handleSignout}>
          <Icon iconName="box-arrow-right" />
          <span>Sign Out</span>
        </NcNavLink>
      </ListGroupItem>
    </>
  );
};
