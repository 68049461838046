import React from 'react';

import {
  Button as BsButton,
  ButtonProps as BsButtonProps,
} from 'react-bootstrap';

import { LoadingSpinner } from '../LoadingSpinner';

import '@consumer-bootstrap/components/buttons.scss';

export const variantsToDisplay = ['primary', 'outline-primary'] as const;
export type ButtonVariantT = typeof variantsToDisplay[number];

export const buttonSize = {
  Default: '',
  Large: 'lg',
  Small: 'sm',
};

export type ButtonPropsT = BsButtonProps & {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  size?: 'sm' | 'lg';
  variant?: ButtonVariantT;
};
export type ButtonT = React.FC<ButtonPropsT>;

export const Button: ButtonT = ({
  children,
  loading,
  ...props
}) => (
  <BsButton {...props}>
    {loading
      ? (
        <span className="btn-loading-container">
          <LoadingSpinner size="sm" />
          <span className="btn-loading-text">{children}</span>
        </span>
      )
      : children}
  </BsButton>
);
