import {
  http,
  HttpHandler,
  HttpResponse,
} from 'msw';

import { apiEndpoints } from '../../lib';
import * as contractMocks from '../contracts';
import fakePDF from '../contracts/fake-contract.pdf';

export type LenderT = 'CCB' | 'RBT' | 'TAB' | 'direct';
export type StateT = 'AL' | 'CA' | 'DE' | 'FL' | 'GA' | 'ID' | 'IL' | 'LA' | 'ND' | 'NM' | 'SC' | 'SD' | 'UT' | 'VA' | 'WI';
export type RepaymentTypeT = 'ach' | 'paper_check';
export type MockDataArgsT = {
  filename: string;
  hasBrandSignature?: boolean;
};

export type StatesByLenderT = Record<LenderT, StateT[]>;
export const statesByLender: StatesByLenderT = {
  CCB: ['SC'],
  direct: [
    'AL',
    'CA',
    'DE',
    'GA',
    'ID',
    'IL',
    'LA',
    'ND',
    'NM',
    'SD',
    'UT',
    'WI',
  ],
  RBT: ['FL'],
  TAB: ['VA'],
};

/* eslint-disable-next-line max-len */
export const customerSignature = 'Signature block:\n\nSigned At:\n\t11:52 AM on October  9, 2024\n\nConnected From:\n\t127.0.0.1\n\nSigned By:\n\tAzucena Trantow xxx-xx-8460\n\nSigned As:\n\tAzucena Trantow xxx-xx-8460\n\nContract: {SHA} aabb401aef415bb6691b61c8f25ef12ffaad1ce0\nACH Authorization: {SHA} 88597bd4265b132c55113e2ecdf338ce90b4c4c4';

export type FilenamePropsT = {
  bankAccountOnFile: boolean;
  lender: LenderT;
  repaymentType: RepaymentTypeT;
  state: StateT;
};

export const filenameFromProps: (props: FilenamePropsT) => string = ({
  bankAccountOnFile,
  lender,
  repaymentType,
  state,
}) => {
  const bankAccountStr = bankAccountOnFile ? 'with-account' : 'without-account';

  return `installment-${lender}-${state}-${repaymentType}-${bankAccountStr}`;
};

export const toCamelCase: (filename: string) => string = (filename) => {
  const [first, ...rest] = filename.split(/[_-]/);

  return [first, ...rest.map((str) => str.charAt(0).toUpperCase() + str.slice(1))].join('');
};

export const allContractFilenames: string[] = [];

// Installment contracts
Object.entries(statesByLender).forEach(([lender, states]) => (
  states.forEach((state) => (
    ['ach', 'paper_check'].forEach((repaymentType) => (
      [true, false].forEach((bankAccountOnFile) => {
        allContractFilenames.push(
          toCamelCase(
            filenameFromProps({
              bankAccountOnFile,
              lender: lender as LenderT,
              repaymentType: repaymentType as RepaymentTypeT,
              state,
            }),
          ),
        );
      })
    ))
  ))
));

// LOC contracts
// TODO: add LOC contract filenames to allContractFilenames
// LOC states:
//   NC_LOC:         AL, DE, ID, LA, UT, WI
//   NC_BP_LOC(RBT): AK, AZ, FL, HI, IN, KS, KY, MI, MN, MS, MO, MT, NE, NJ, OH, OK, OR, RI, TN, TX, WY
//   NC_BP_TAB_LOC:  AL, AZ, AR, FL, HI, IN, KS, KY, MI, MN, MS, MT, NE, NJ, OH, OK, OR, RI, SC, TN, TX, VA, WY
//   NC_BP_CCB_LOC:  SC, VA

const mockData = ({ filename, hasBrandSignature }: MockDataArgsT) => {
  console.info('mockData', toCamelCase(filename), contractMocks);

  return {
    body: contractMocks[toCamelCase(filename) as keyof typeof contractMocks],
    brandSignature: hasBrandSignature ? 'Brand Signature' : '',
    customerSignature,
  };
};

const allContractHandlers: Record<string, HttpHandler> = {};

allContractFilenames.forEach((filename) => {
  allContractHandlers[filename] = http.get(
    apiEndpoints.contract(':accountId', ':loanNumber'),
    () => HttpResponse.json(
      mockData({ filename, hasBrandSignature: true }),
      { status: 200 },
    ),
  );
});

console.info(allContractHandlers);

export const contractHandlers = {
  default: http.get(
    apiEndpoints.contract(':accountId', ':loanNumber'),
    () => HttpResponse.json(
      mockData({ filename: allContractFilenames[4], hasBrandSignature: true }),
      { status: 200 },
    ),
  ),
  noBrandSignature: http.get(
    apiEndpoints.contract(':accountId', ':loanNumber'),
    () => HttpResponse.json(mockData({ filename: allContractFilenames[4] }), { status: 200 }),
  ),
  ...allContractHandlers,
};

export const contractPDFHandlers = {
  default: http.get(apiEndpoints.contractPDF(':accountId', ':loanNumber'), async () => {
    const response = await fetch(fakePDF);
    const blob = await response.blob();

    return new HttpResponse(blob, { status: 200 });
  }),
  error: http.get(apiEndpoints.contractPDF(':accountId', ':loanNumber'), () => HttpResponse.error()),
  loading: http.get(apiEndpoints.contractPDF(':accountId', ':loanNumber'), () => new Promise(
    (resolve) => { setTimeout(() => resolve(new HttpResponse(undefined, { status: 200 })), 30000); },
  )),
};
