import { JSX, ReactNode } from 'react';

import { AlertVariantT } from '@consumer-frontend/shared-ui';

export type AlertBannerPropsT = {
  dismissible: boolean;
  heading: string;
  message: ReactNode;
  show: boolean;
  variant: AlertVariantT;
};

export const initialAlertBannerProps: AlertBannerPropsT = {
  dismissible: false,
  heading: '',
  message: '',
  show: false,
  variant: 'info',
};

export type ModalConfigT = {
  body: JSX.Element | string;
  primaryText: string;
  show: boolean;
  title: string;
  modalOnClick?: () => void;
  secondaryText?: string;
};

export const initialModalConfig: ModalConfigT = {
  body: '',
  primaryText: '',
  secondaryText: '',
  show: false,
  title: '',
};

export const externalLinkModalConfig: ModalConfigT = {
  body: (
    <p>
      By clicking this link you will be leaving the NetCredit website — www.netcredit.com.
      You will be automatically directed to a website that is not directly controlled by NetCredit.
      By clicking OK, you acknowledge that you are leaving the NetCredit website.
    </p>
  ),
  primaryText: 'OK',
  secondaryText: 'Cancel',
  show: false,
  title: 'Leaving NetCredit.com',
};

export const originalPricingTextFirstHalf = 'The fee table below is the original pricing when you first set up your account '
+ 'and is not reflective of the Fee Saver reductions you may have earned. Please click';
export const originalPricingTextLastHalf = ' to see your current fee table as of today. '
+ 'Your lender may cancel or make changes to the Fee Saver program at any time.';
