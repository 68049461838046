export const lineofcreditRBTFLAchWithAccount = `<div class='bloc-contract bloc-contract-modal'>
  <div class='header'>
    <h4 class='center-text strong-text' id='terms_and_conditions'>
      <br>
      REPUBLIC BANK & TRUST COMPANY
      </br>
      <br>
      OPEN-END LINE OF CREDIT
      AGREEMENT
      </br>
    </h4>
  </div>
  <p class='strong-text'>
    This Open-End Line of Credit Agreement consists of the
    following sections:
  </p>
  <ol type='I'>
    <li>
      Account Opening Disclosures
    </li>
    <li>
      Definitions
    </li>
    <li>
      Your Line of Credit Account
    </li>
    <li>
      Fees and Statements
    </li>
    <li>
      Payments
    </li>
    <li>
      Additional
      Terms & Conditions
    </li>
    <li>
      Your Billing Rights
    </li>
    <li>
      Waiver of Jury Trial and Class Action Waiver
    </li>
    <li>
      ARBITRATION PROVISION
    </li>
    <li>
      Important Notices
    </li>
  </ol>
  <p>
    Read each section carefully.
    You will be asked to agree to the terms of this Agreement.
  </p>
  <p style='padding:6px; border: black 1px solid'>
    <u>
      HIGH COST CREDIT DISCLOSURE.
    </u>
    THIS IS AN EXPENSIVE FORM OF CREDIT. IT SHOULD BE USED FOR
    SHORT-TERM
    FINANCIAL NEEDS, NOT AS A LONG-TERM FINANCIAL SOLUTION.
    OTHER CREDIT OPTIONS MAY BE LESS EXPENSIVE.
  </p>
  <div class='bloc-contract'>
    <p class='strong-text sub-title' id='account_disclosures'>
      I. Account Opening Disclosures
    </p>
    <div class='table-responsive'>
      <table class='table table-bordered'>
        <tbody>
          <tr>
            <td class='black-row' colspan='5'>
              Fees
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              <p class='strong-text'>
                Transaction Fee
              </p>
              <p>
                ●    
                <span>Cash Advance Fee</span>
              </p>
            </td>
            <td colspan='3'>
              <strong>10%</strong>
              <span>of
                the amount of each Cash Advance.</span>
            </td>
          </tr>
          <tr>
            <td class='black-row' colspan='5'>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td rowspan='72'>
              <p class='strong-text'>
                Statement Balance Fee*
              </p>
            </td>
            <td>
              <p class='strong-text'>
                Level
              </p>
            </td>
            <td>
              <p class='strong-text'>
                For each Statement
                that shows a Cash Advance Balance of:
              </p>
            </td>
            <td>
              <p class='strong-text'>
                If you pay Bi-weekly
                or Semi-monthly:
              </p>
            </td>
            <td>
              <p class='strong-text'>
                If you pay Monthly:
              </p>
            </td>
          </tr>
          <tr>
            <td>0</td>
            <td>$0.00 - $25.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
          </tr>
          <tr>
            <td>1</td>
            <td>$25.01 - $100.00</td>
            <td>$5.00</td>
            <td>$10.00</td>
          </tr>
          <tr>
            <td>2</td>
            <td>$100.01 - $200.00</td>
            <td>$10.00</td>
            <td>$20.00</td>
          </tr>
          <tr>
            <td>3</td>
            <td>$200.01 - $300.00</td>
            <td>$15.00</td>
            <td>$30.00</td>
          </tr>
          <tr>
            <td>4</td>
            <td>$300.01 - $400.00</td>
            <td>$20.00</td>
            <td>$40.00</td>
          </tr>
          <tr>
            <td>5</td>
            <td>$400.01 - $500.00</td>
            <td>$25.00</td>
            <td>$50.00</td>
          </tr>
          <tr>
            <td>6</td>
            <td>$500.01 - $600.00</td>
            <td>$30.00</td>
            <td>$60.00</td>
          </tr>
          <tr>
            <td>7</td>
            <td>$600.01 - $700.00</td>
            <td>$35.00</td>
            <td>$70.00</td>
          </tr>
          <tr>
            <td>8</td>
            <td>$700.01 - $800.00</td>
            <td>$40.00</td>
            <td>$80.00</td>
          </tr>
          <tr>
            <td>9</td>
            <td>$800.01 - $900.00</td>
            <td>$45.00</td>
            <td>$90.00</td>
          </tr>
          <tr>
            <td>10</td>
            <td>$900.01 - $1,000.00</td>
            <td>$50.00</td>
            <td>$100.00</td>
          </tr>
          <tr>
            <td>11</td>
            <td>$1,000.01 - $1,100.00</td>
            <td>$55.00</td>
            <td>$110.00</td>
          </tr>
          <tr>
            <td>12</td>
            <td>$1,100.01 - $1,200.00</td>
            <td>$60.00</td>
            <td>$120.00</td>
          </tr>
          <tr>
            <td>13</td>
            <td>$1,200.01 - $1,300.00</td>
            <td>$65.00</td>
            <td>$130.00</td>
          </tr>
          <tr>
            <td>14</td>
            <td>$1,300.01 - $1,400.00</td>
            <td>$70.00</td>
            <td>$140.00</td>
          </tr>
          <tr>
            <td>15</td>
            <td>$1,400.01 - $1,500.00</td>
            <td>$75.00</td>
            <td>$150.00</td>
          </tr>
          <tr>
            <td>16</td>
            <td>$1,500.01 - $1,600.00</td>
            <td>$80.00</td>
            <td>$160.00</td>
          </tr>
          <tr>
            <td>17</td>
            <td>$1,600.01 - $1,700.00</td>
            <td>$85.00</td>
            <td>$170.00</td>
          </tr>
          <tr>
            <td>18</td>
            <td>$1,700.01 - $1,800.00</td>
            <td>$90.00</td>
            <td>$180.00</td>
          </tr>
          <tr>
            <td>19</td>
            <td>$1,800.01 - $1,900.00</td>
            <td>$95.00</td>
            <td>$190.00</td>
          </tr>
          <tr>
            <td>20</td>
            <td>$1,900.01 - $2,000.00</td>
            <td>$100.00</td>
            <td>$200.00</td>
          </tr>
          <tr>
            <td>21</td>
            <td>$2,000.01 - $2,100.00</td>
            <td>$105.00</td>
            <td>$210.00</td>
          </tr>
          <tr>
            <td>22</td>
            <td>$2,100.01 - $2,200.00</td>
            <td>$110.00</td>
            <td>$220.00</td>
          </tr>
          <tr>
            <td>23</td>
            <td>$2,200.01 - $2,300.00</td>
            <td>$115.00</td>
            <td>$230.00</td>
          </tr>
          <tr>
            <td>24</td>
            <td>$2,300.01 - $2,400.00</td>
            <td>$120.00</td>
            <td>$240.00</td>
          </tr>
          <tr>
            <td>25</td>
            <td>$2,400.01 - $2,500.00</td>
            <td>$125.00</td>
            <td>$250.00</td>
          </tr>
          <tr>
            <td>26</td>
            <td>$2,500.01 - $2,600.00</td>
            <td>$130.00</td>
            <td>$260.00</td>
          </tr>
          <tr>
            <td>27</td>
            <td>$2,600.01 - $2,700.00</td>
            <td>$135.00</td>
            <td>$270.00</td>
          </tr>
          <tr>
            <td>28</td>
            <td>$2,700.01 - $2,800.00</td>
            <td>$140.00</td>
            <td>$280.00</td>
          </tr>
          <tr>
            <td>29</td>
            <td>$2,800.01 - $2,900.00</td>
            <td>$145.00</td>
            <td>$290.00</td>
          </tr>
          <tr>
            <td>30</td>
            <td>$2,900.01 - $3,000.00</td>
            <td>$150.00</td>
            <td>$300.00</td>
          </tr>
          <tr>
            <td>31</td>
            <td>$3,000.01 - $3,100.00</td>
            <td>$155.00</td>
            <td>$310.00</td>
          </tr>
          <tr>
            <td>32</td>
            <td>$3,100.01 - $3,200.00</td>
            <td>$160.00</td>
            <td>$320.00</td>
          </tr>
          <tr>
            <td>33</td>
            <td>$3,200.01 - $3,300.00</td>
            <td>$165.00</td>
            <td>$330.00</td>
          </tr>
          <tr>
            <td>34</td>
            <td>$3,300.01 - $3,400.00</td>
            <td>$170.00</td>
            <td>$340.00</td>
          </tr>
          <tr>
            <td>35</td>
            <td>$3,400.01 - $3,500.00</td>
            <td>$175.00</td>
            <td>$350.00</td>
          </tr>
          <tr>
            <td>36</td>
            <td>$3,500.01 - $3,600.00</td>
            <td>$180.00</td>
            <td>$360.00</td>
          </tr>
          <tr>
            <td>37</td>
            <td>$3,600.01 - $3,700.00</td>
            <td>$185.00</td>
            <td>$370.00</td>
          </tr>
          <tr>
            <td>38</td>
            <td>$3,700.01 - $3,800.00</td>
            <td>$190.00</td>
            <td>$380.00</td>
          </tr>
          <tr>
            <td>39</td>
            <td>$3,800.01 - $3,900.00</td>
            <td>$195.00</td>
            <td>$390.00</td>
          </tr>
          <tr>
            <td>40</td>
            <td>$3,900.01 - $4,000.00</td>
            <td>$200.00</td>
            <td>$400.00</td>
          </tr>
          <tr>
            <td>41</td>
            <td>$4,000.01 - $4,100.00</td>
            <td>$205.00</td>
            <td>$410.00</td>
          </tr>
          <tr>
            <td>42</td>
            <td>$4,100.01 - $4,200.00</td>
            <td>$210.00</td>
            <td>$420.00</td>
          </tr>
          <tr>
            <td>43</td>
            <td>$4,200.01 - $4,300.00</td>
            <td>$215.00</td>
            <td>$430.00</td>
          </tr>
          <tr>
            <td>44</td>
            <td>$4,300.01 - $4,400.00</td>
            <td>$220.00</td>
            <td>$440.00</td>
          </tr>
          <tr>
            <td>45</td>
            <td>$4,400.01 - $4,500.00</td>
            <td>$225.00</td>
            <td>$450.00</td>
          </tr>
          <tr>
            <td>46</td>
            <td>$4,500.01 - $4,600.00</td>
            <td>$230.00</td>
            <td>$460.00</td>
          </tr>
          <tr>
            <td>47</td>
            <td>$4,600.01 - $4,700.00</td>
            <td>$235.00</td>
            <td>$470.00</td>
          </tr>
          <tr>
            <td>48</td>
            <td>$4,700.01 - $4,800.00</td>
            <td>$240.00</td>
            <td>$480.00</td>
          </tr>
          <tr>
            <td>49</td>
            <td>$4,800.01 - $4,900.00</td>
            <td>$245.00</td>
            <td>$490.00</td>
          </tr>
          <tr>
            <td>50</td>
            <td>$4,900.01 - $5,000.00</td>
            <td>$250.00</td>
            <td>$500.00</td>
          </tr>
          <tr>
            <td>51</td>
            <td>$5,000.01 - $5,100.00</td>
            <td>$255.00</td>
            <td>$510.00</td>
          </tr>
          <tr>
            <td>52</td>
            <td>$5,100.01 - $5,200.00</td>
            <td>$260.00</td>
            <td>$520.00</td>
          </tr>
          <tr>
            <td>53</td>
            <td>$5,200.01 - $5,300.00</td>
            <td>$265.00</td>
            <td>$530.00</td>
          </tr>
          <tr>
            <td>54</td>
            <td>$5,300.01 - $5,400.00</td>
            <td>$270.00</td>
            <td>$540.00</td>
          </tr>
          <tr>
            <td>55</td>
            <td>$5,400.01 - $5,500.00</td>
            <td>$275.00</td>
            <td>$550.00</td>
          </tr>
          <tr>
            <td>56</td>
            <td>$5,500.01 - $5,600.00</td>
            <td>$280.00</td>
            <td>$560.00</td>
          </tr>
          <tr>
            <td>57</td>
            <td>$5,600.01 - $5,700.00</td>
            <td>$285.00</td>
            <td>$570.00</td>
          </tr>
          <tr>
            <td>58</td>
            <td>$5,700.01 - $5,800.00</td>
            <td>$290.00</td>
            <td>$580.00</td>
          </tr>
          <tr>
            <td>59</td>
            <td>$5,800.01 - $5,900.00</td>
            <td>$295.00</td>
            <td>$590.00</td>
          </tr>
          <tr>
            <td>60</td>
            <td>$5,900.01 - $6,000.00</td>
            <td>$300.00</td>
            <td>$600.00</td>
          </tr>
          <tr>
            <td>61</td>
            <td>$6,000.01 - $6,100.00</td>
            <td>$305.00</td>
            <td>$610.00</td>
          </tr>
          <tr>
            <td>62</td>
            <td>$6,100.01 - $6,200.00</td>
            <td>$310.00</td>
            <td>$620.00</td>
          </tr>
          <tr>
            <td>63</td>
            <td>$6,200.01 - $6,300.00</td>
            <td>$315.00</td>
            <td>$630.00</td>
          </tr>
          <tr>
            <td>64</td>
            <td>$6,300.01 - $6,400.00</td>
            <td>$320.00</td>
            <td>$640.00</td>
          </tr>
          <tr>
            <td>65</td>
            <td>$6,400.01 - $6,500.00</td>
            <td>$325.00</td>
            <td>$650.00</td>
          </tr>
          <tr>
            <td>66</td>
            <td>$6,500.01 - $6,600.00</td>
            <td>$330.00</td>
            <td>$660.00</td>
          </tr>
          <tr>
            <td>67</td>
            <td>$6,600.01 - $6,700.00</td>
            <td>$335.00</td>
            <td>$670.00</td>
          </tr>
          <tr>
            <td>68</td>
            <td>$6,700.01 - $6,800.00</td>
            <td>$340.00</td>
            <td>$680.00</td>
          </tr>
          <tr>
            <td>69</td>
            <td>$6,800.01 - $6,900.00</td>
            <td>$345.00</td>
            <td>$690.00</td>
          </tr>
          <tr>
            <td>70</td>
            <td>$6,900.01 - $7,000.00</td>
            <td>$350.00</td>
            <td>$700.00</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <span class='strong-text underlined-text'>
        How We Will Calculate Your Cash
        Advance Balance:
      </span>
      <span>
        To calculate your Cash Advance Balance for a Billing Cycle, we take the prior
        Billing Cycle’s ending
        Account Balance (labeled “Previous Balance” on your current Statement), and add the
        amount of each Cash
        Advance that posts during the current Billing Cycle, and subtract past due amounts, any
        payments or
        credits that post during the current Billing Cycle, and any unpaid non-past due amounts
        from
        previous Billing Cycles. We do not include any Statement Balance Fees or past due amounts in your
        Cash Advance
        Balance. See Section IV(B) for details.
      </span>
    </p>
    <p>
      <span class='strong-text underlined-text'>
        Billing
        Rights:
      </span>
      <span>
        Information on your rights to dispute transactions and how to exercise those rights is
        provided in
        Section VII below labeled “Your Billing Rights.”
      </span>
    </p>
    <p>
      <span class='strong-text'>
        Grace Period:
      </span>
      <span>
        There is no grace period within which you will not be
        charged a Finance Charge after you take a
        Cash Advance. If you take a Cash Advance, you cannot avoid paying a
        Cash Advance Fee but you will
        not be assessed a Statement Balance Fee in any Billing Cycle if, at the end of
        such Billing Cycle,
        your Cash Advance Balance is $25 or less.
      </span>
    </p>
    <p>
      * If you consistently make
      on-time payments, you may qualify for Fee Saver reductions that will entitle
      you to a reduced Statement Balance
      Fee on your Line of Credit. See Section IV(C) for details.
    </p>
  </div>
  <p class='strong-text sub-title'>
    II.\tDefinitions
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Account
      </i>
    </span>
    <span>
      is the open-end line
      of credit you establish under this Agreement with Republic Bank & Trust Company located at 4030 Smith Road,
      Cincinnati, OH 45209.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Account
        Balance
      </i>
    </span>
    <span>
      is the total amount outstanding on your Account.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Account Home
      </i>
    </span>
    <span>
      refers to the secure portal used to access your
      Account information, including the Agreement, your Available Credit, your Statements, your Cash Advances, and your
      payment history.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Advance Cancellation
        Deadline
      </i>
    </span>
    <span>
      means the two (2) Business Days after you requested a Cash Advance during which
      you may cancel the requested Cash Advance without incurring any fees.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Advance Proceeds
      </i>
    </span>
    <span>
      is the amount you receive by electronic fund
      transfer when you request a Cash Advance. It is the Cash Advance minus the Cash Advance Fee.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Agreement
      </i>
    </span>
    <span>
      is this Open-End Line of Credit
      Agreement.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Arbitration
        Provision
      </i>
    </span>
    <span>
      means the Arbitration Provision in Section IX.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Autopay
      </i>
    </span>
    <span>
      is an optional preauthorized recurring automatic
      payment to us from your Checking Account for your Minimum Payments on or after your Statement due date. See
      Section V(C) for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Available
        Credit
      </i>
    </span>
    <span>
      is the amount of credit that is currently available to you for Cash Advances. See
      Section III(B) for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Billing
        Cycle
      </i>
    </span>
    <span>
      is the period of time between Statements. Your Billing Cycle may be Bi-weekly,
      Semi-monthly, or Monthly.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Bi-weekly
      </i>
    </span>
    <span>
      means once every two weeks.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Business Day
      </i>
    </span>
    <span>
      is any day except Saturday, Sunday, and
      federal holidays.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Cash Advance
      </i>
    </span>
    <span>
      is
      the amount of the advance you request and must repay. It is the total of the Advance Proceeds
    </span>
    <span class='underlined-text'>
      plus
    </span>
    <span>
      the Cash Advance Fee. We deduct the Cash Advance Fee from the
      amount of the Cash Advance you request
      and disburse the remainder, i.e., the Advance Proceeds, to you by
      electronic fund transfer into your Checking Account.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Cash
        Advance Balance
      </i>
    </span>
    <span>
      is the amount used to determine the Statement Balance Fee charged for
      each Billing Cycle.
      It is calculated as shown in the Account Opening Disclosures in Section I. See Section IV(B)
      for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Cash Advance Fee
      </i>
    </span>
    <span>
      is the
      Finance Charge charged for each Cash Advance at the time you request a Cash Advance,
      as disclosed in the Account
      Opening Disclosures in Section I. The Cash Advance Fee is 10% of the amount of your Cash Advance.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Checking Account
      </i>
    </span>
    <span>
      is the bank account that you specified
      in your application, or any substitute account you or your
      financial institution may later provide us. You must
      maintain the Checking Account in order to obtain Cash Advances.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Credit Limit
      </i>
    </span>
    <span>
      is the maximum amount you can borrow under your
      Account. See Section III(B) for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Fee
        Saver
      </i>
    </span>
    <span>
      is a reduction of the Statement Balance Fee earned when you successfully make a
      Qualifying String of Payments. See Section IV(C) for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Finance Charge
      </i>
    </span>
    <span>
      is any Cash Advance Fee and any Statement
      Balance Fee that may be charged under this Agreement.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Line
        Cancellation Deadline
      </i>
    </span>
    <span>
      is two (2) Business Days immediately following the day you are
      notified via email of final approval of your Account.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Messaging Parties
      </i>
    </span>
    <span>
      refers to us, our assignees and the
      affiliate, agents, representative, assigns and servicers/service providers of ours or our assignees.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Minimum Payment
      </i>
    </span>
    <span>
      is the amount you agree to pay
      each Billing Cycle. If your account is past due, you will need to pay the past due amount in addition to
      the
      Minimum Payment for your account to become current. See Section V(B) for details.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Monthly
      </i>
    </span>
    <span>
      means once a month.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        NetCredit
      </i>
    </span>
    <span>
      refers to NetCredit Loan Services, LLC, the current
      Account servicer.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Qualifying String of
        Payments
      </i>
    </span>
    <span>
      occurs when you successfully make six (6) consecutive Monthly or 12
      consecutive
      Bi-weekly/Semi-monthly payments of at least the amount of your Minimum Payment
      specified in your
      Statement by the due date shown on such Statement. In the event
      that a payment is made more than ten (10) days
      after its due date or is in an amount
      that falls short of the required Minimum Payment due, the number of
      consecutive payments
      counting toward a Qualifying String of Payments resets to zero. A payment made pursuant
      to
      any reduced or negotiated payment arrangement does not count toward a Qualifying String of Payments.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Semi-monthly
      </i>
    </span>
    <span>
      means twice a month.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Statement
      </i>
    </span>
    <span>
      is the periodic statement issued for each
      Billing Cycle that describes,
      among other things: your Account Balance, payments, credits, debits, Cash Advance
      Balance,
      Statement Balance Fees, Cash Advances, Cash Advance Fees, past due amounts, your Minimum
      Payment and
      payment due date.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        Statement Balance
        Fee
      </i>
    </span>
    <span>
      is the Finance Charge assessed for each Billing Cycle depending on the amount
      of
      your Cash Advance Balance for that Billing Cycle displayed on your Statement, and as disclosed
      in the Account
      Opening Disclosures in Section I. See Section IV(B) for details.
    </span>
  </p>
  <p><i class='strong-text'>We</i>,&nbsp;<i class='strong-text'>us</i>,&nbsp;and&nbsp;<i
      class='strong-text'>our</i><span>
      mean Republic Bank & Trust Company, its successors and assigns, and, for
      purposes of the
      Arbitration Provision, Section IX, the other persons identified in the Arbitration
      Provision.
    </span>
  </p>
  <p>
    <span class='strong-text'>
      <i>
        You
      </i>
    </span>
    <span>
      and
    </span>
    <span class='strong-text'>
      <i>
        your
      </i>
    </span>
    <span>
      mean the account holder who signs this
      Agreement.
    </span>
  </p>
  <p class='strong-text sub-title'>
    III. YOUR LINE OF CREDIT ACCOUNT
  </p>
  <p>
    By
    signing this Agreement electronically, you agree to abide by the following terms the same as
    if you had signed this
    Agreement in ink. This Agreement will become effective only after we have
    granted final approval of your
    application. We will notify you via email of such approval and your Account will then be open.
  </p>
  <p style='padding:6px; border: black 1px solid'>
    <span class='strong-text'>
      IMPORTANT INFORMATION ABOUT PROCEDURES
      FOR OPENING A NEW ACCOUNT
    </span>
    <br>
    <br>
    <span>
      To help the government fight the funding of terrorism and
      money laundering activities, Federal
      law requires all financial institutions to obtain, verify, and record
      information that identified
      each person who opens an account. What this means for you: When you open an account,
      we will ask
      for your name, address, date of birth, government ID number, or other information that will allow
      us
      to identify you. We may also ask to see your driver's license or other identifying documents.
    </span>
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Account
    </li>
  </ol>
  <p>
    Pursuant to this Agreement, we will
    establish an open-end line of credit for you in the amount of
    your Credit Limit. You may take Cash Advances from
    this Account from time to time, up to the Credit
    Limit and subject to the terms of this Agreement. Each Billing
    Cycle you must pay at least the Minimum
    Payment amount specified on your Statement by the payment due date
    specified on your Statement.
    The Minimum Payment will be a combination of the Statement Balance Fee plus a
    percentage of your Cash
    Advance Balance. See Section V(B), captioned “Calculating the Minimum Payment” for
    details.
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Credit Limit; Available Credit
    </li>
  </ol>
  <p>
    We will assign you a Credit Limit on your Account that we may increase or decrease from time to time.
    After you
    make a payment, we may delay in replenishing your Available Credit to reflect that payment until after such payment
    settles.
  </p>
  <p>
    Your Available Credit is the amount of credit that is currently available to you for Cash
    Advances.
    Available Credit is determined by subtracting the total amount of Cash Advances outstanding from your
    Credit Limit,
    or the minimum Cash Advance amount described in Section III, Paragraph 3 below.
    If your Account has
    a past due amount, or is otherwise suspended, or your available credit is below the minimum Cash Advance,
    your
    Available Credit is zero.
  </p>
  <p>
    To obtain your Credit Limit and your Available Credit, either (a) log into
    your Account Home at
    <a href='https://www.netcredit.com'>www.netcredit.com</a>;
    (b) call Customer Support at (877)
    392-2014; or (c) email support@netcredit.com.
  </p>
  <ol class='strong-text' start='3' type='A'>
    <li>
      Requesting
      Cash Advances
    </li>
  </ol>
  <p>
    You may obtain Cash Advances by requesting them in your Account Home at
    <a href='https://www.netcredit.com'>www.netcredit.com</a>.
    Please call (877) 392-2014 if you need assistance with
    this Cash Advance request process. The minimum Cash Advance you may request may
    vary depending on your state of
    residence.
  </p>
  <p>
    For Minnesota residents, the minimum Cash Advance you may request is $1,450.
    For residents
    of all other states, the minimum Cash Advance you may request is $50.
  </p>
  <p>
    The amount of any new Cash Advance
    will be
    added to the Account Balance and your Cash Advance Balance. Cash Advances cannot exceed your Credit Limit
    or Available Credit.
  </p>
  <p class='strong-text'>
    IMPORTANT: The Advance Proceeds you receive are less than the
    total amount of the Cash
    Advance you request. We will deduct the Cash Advance Fee from the amount of the
    Cash
    Advance you request and deliver only the Advance Proceeds to you.
  </p>
  <p>
    We will disburse the Advance
    Proceeds by electronic funds transfer to your Checking Account.
    If we receive your Cash Advance request by 5:00 PM
    CT on a Business Day, we will use commercially
    reasonable efforts to disburse the Advance Proceeds to your Checking
    Account on or before the
    next Business Day. It may take additional time for your bank to post the Advance Proceeds
    to
    your Checking Account. Always check with your bank to learn when the Advance Proceeds will be available for
    use.
  </p>
  <ol class='strong-text' start='4' type='A'>
    <li>
      Denying Cash Advance Requests, Freezing Account, and
      Closing Account
    </li>
  </ol>
  <p>
    We may refuse to allow you to obtain a Cash Advance and/or freeze your Account
    at any time for
    any reason permitted by law whatsoever, including, but not limited to, if the Account is past
    due;
    the transaction would cause you to exceed your Available Credit or Credit Limit; you made a payment
    on the
    Account that has not yet settled; we do not have valid Checking Account information on file
    for you; we suspect
    that the Cash Advance request is fraudulent; any of the contact information
    we have for you is no longer valid; you
    withdraw your consent to receive electronic communications;
    the Account has become the subject of litigation,
    bankruptcy, or a payment plan; or any other reason in our sole discretion.
  </p>
  <p>
    In the event that your
    Account is deemed inactive because you do not have an Account Balance for a period of 365
    consecutive days it may
    be closed. We will provide you notice that your Account may be closed at least 30 days prior
    to closing it for
    inactivity. If you do not take a Cash Advance within 30 days of notification, the account will be closed
    and you
    will no longer be able to request Cash Advances.
  </p>
  <p>
    You may request to close your Account at any time by
    notifying us in writing that you wish for your Account
    to be closed and by paying your Account Balance in full. We
    may refuse to close your Account for any reason
    permitted by law, including but not limited to, you fail to pay
    your entire Account Balance in full, a transaction
    is pending on your Account, or there is a dispute or billing
    error pending resolution.
  </p>
  <p>
    We may close your Account at any time and for any reason except as prohibited
    by law, even if you are not in default.
    If your Account has an Account Balance at the time it is closed, you will
    still be required to make all Minimum Payments
    as scheduled and Statement Balance Fees will continue to accrue
    until you have repaid the Account Balance in full.
  </p>
  <ol class='strong-text' start='5' type='A'>
    <li>
      Cancelling the Line of Credit Account
    </li>
  </ol>
  <p>
    You may cancel or rescind the Account at any time
    before the Line Cancellation Deadline, and, if you successfully do so,
    you will owe no fees or charges and the
    tradeline for this Account will be removed from your credit report.
    To cancel, you must inform us of your intent to
    cancel in writing no later than the Line Cancellation Deadline
    via fax at (855) 878-1068 or email at
    support@netcredit.com. If you have requested a Cash Advance, you must
    follow the procedure below in Section III(F),
    captioned “Cancelling Requested Cash Advances”, to cancel the Cash
    Advance prior to your Account being cancelled.
    At any time after this, you may close your Account by following the
    procedures set forth in Section III(D),
    captioned “Denying Cash Advance Requests, Freezing Account, and Closing Account.”
  </p>
  <ol class='strong-text' start='6' type='A'>
    <li>
      Cancelling Requested Cash Advances
    </li>
  </ol>
  <p>
    You may cancel any requested Cash
    Advance at any time before the Advance Cancellation Deadline. You must notify us,
    Republic Bank & Trust Company c/o
    NetCredit, in writing via fax at (855) 878-1068 or email at support@netcredit.com of
    your desire to cancel. If the
    Advance Proceeds have not been disbursed to your Checking Account, you will not owe a Cash
    Advance Fee on such
    advance and the advance will not be included in your Account Balance.
  </p>
  <p>
    <span>
      If the Cash Advance has
      been disbursed to your Checking Account, you agree that we may initiate a debit entry to your
      Checking Account
      for the amount of the Advance Proceeds. If we receive the full amount of the Advance Proceeds from that
      debit
      entry, you will not owe a Cash Advance Fee on such advance and such advance will not be included in your
      Account
      Balance and will not be considered for the purpose of calculating your Cash Advance Balance that
      determines the Statement Balance Fee.
    </span>
    <span class='underlined-text strong-text'>
      If we do not receive
      the full amount of the Advance Proceeds from that debit entry, the Cash Advance will be included
      in your Account
      Balance and will be considered for the purpose of calculating your Cash Advance Balance that determines the
      Statement Balance Fee.
    </span>
  </p>
  <ol class='strong-text' start='7' type='A'>
    <li>
      Default and
      Acceleration
    </li>
  </ol>
  <p>
    We may declare you to be in default under this Agreement if: (i) any payment is
    past due; (ii) you withdraw your consent
    to receive electronic communications; (iii) you have violated any term or
    condition of this Agreement; (iv) we determine
    that any information in your application was incomplete or
    inaccurate; or (v) we determine in our sole discretion that the
    prospect of payment or performance is significantly
    impaired. If you default under this Agreement, subject to any notice
    and cure right you may have, we may: (a)
    declare all amounts you owe under this Agreement immediately due and payable; (b) close
    your Account; and (c)
    exercise all other rights, powers, and remedies permitted by applicable law. To the extent permitted by
    applicable
    law, you agree to pay our court costs, reasonable attorneys' fees and other collection costs related to the
    default.
    PLEASE NOTE: Residents of Minnesota, Nebraska, Ohio, Oklahoma and Wyoming should see important terms
    regarding court
    costs, attorneys' fees and other collection costs below under \"IMPORTANT NOTICES.\"
  </p>
  <p>
    By
    choosing any one or more of these default remedies listed above, we do not give up our right to use another remedy
    later.
    By deciding not to use any remedy should you be in default, we do not give up our right to consider the
    event a default if
    it happens again. We may delay or refrain from enforcing any of our rights under this Agreement
    without waiving such rights.
    You understand that any waiver of our rights will not be effective unless it is in
    writing signed by us.
  </p>
  <ol class='strong-text' start='8' type='A'>
    <li>
      Servicing
    </li>
  </ol>
  <p>
    Any
    rights granted to us and duties we have under this Agreement may be exercised or undertaken by servicers or other
    agents
    we designate. Unless and until we notify you otherwise, this Account will be serviced by NetCredit.
  </p>
  <p class='strong-text sub-title'>
    IV. FEES AND STATEMENTS
  </p>
  <p>
    Your Account does not have an interest rate
    like other traditional credit products. Instead, we assess a Cash Advance Fee
    for each Cash Advance and a Statement
    Balance Fee at the end of each Billing Cycle in which you have a Cash Advance
    Balance greater than $25 on your
    Statement, as described in the Account Opening Disclosures.
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Cash Advance Fee
    </li>
  </ol>
  <p>
    <span>
      When you obtain a Cash Advance, we will impose a Cash Advance Fee
      of 10% of the amount of your Cash Advance. The Cash Advance Fee is a
    </span>
    <span class='underlined-text strong-text'>
      FINANCE CHARGE.
    </span>
    <span>
      We will deduct the Cash Advance Fee from
      the amount of the Cash Advance you request, and deliver only the Advance Proceeds to you.
      The Advance Proceeds
      you receive from a Cash Advance request will be less than the Cash Advance you must repay. For example,
      if you
      obtain a $1,500 Cash Advance, you will be charged a $150 Cash Advance Fee and you will receive Advance Proceeds of
      $1,350.
      You must repay the full $1,500 Cash Advance and the $1,500 will be added to your Account Balance and Cash
      Advance Balance.
    </span>
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Statement Balance Fee
    </li>
  </ol>
  <p>
    <span>
      We charge a Statement Balance Fee as described in the Account Opening Disclosures in Section I.
      The Statement Balance Fee is a
    </span>
    <span class='underlined-text strong-text'>
      FINANCE
      CHARGE.
    </span>
    <span>
      We determine the Statement Balance Fee based on the length of your Billing Cycle, your
      Cash Advance Balance, and any earned Fee Saver reduction.
    </span>
  </p>
  <p>
    To get the Cash Advance Balance for
    a Billing Cycle, we take the prior Billing cycle’s ending Account Balance (labeled “Previous Balance”
    on your
    current Statement), and add the amount of each Cash Advance that posts during the current Billing Cycle, and
    subtract past due
    amounts, any payments or credits that post during the current Billing Cycle, and any unpaid
    non-past due amounts from
    previous Billing Cycles. We do not include any Statement Balance Fees or past due amounts
    in your Cash Advance Balance.
  </p>
  <p>
    If your Cash Advance Balance is $25 or less, you will not incur a
    Statement Balance Fee for that Billing Cycle. For Cash Advance
    Balances of $25.01 and greater, you will incur the
    Statement Balance Fee disclosed in the Account Opening Disclosures in Section I.
  </p>
  <ol class='strong-text' start='3' type='A'>
    <li>
      Fee Saver
    </li>
  </ol>
  <p>
    Fee Saver reductions lower the Statement Balance Fee you
    must pay each Billing Cycle. You earn a Fee Saver reduction each
    time you successfully make a Qualifying String of
    Payments, as described in Section II. Once you achieve a particular Fee
    Saver reduction, you will not forfeit that
    reduction due to late or missed payments (but we reserve the right to close or
    freeze the Account due to missed
    payments or any other reason). You cannot earn Fee Saver reductions if your Account is
    closed, frozen or in past
    due status. We will notify you via email you when you have earned a Fee Saver reduction.
    Your new Statement Balance
    Fee table will be available in Account Home.
  </p>
  <p>
    Statement Balance Fees with Fee Saver reductions applied
    are available at
    <a href='/fee_saver_status'>https://www.netcredit.com/fee_saver_status</a>.
    Fee Saver reductions
    are calculated as follows:
  </p>
  <p class='underlined-text'>
    <i>
      Monthly Billing Cycles:
    </i>
  </p>
  <p>
    Each
    time you make a Qualifying String of Payments your Statement Balance Fee will be reduced by $0.80 per Level listed
    in the
    Account Opening Disclosures in Section I. The Statement Balance Fee reduction is equal to:
  </p>
  <p class='strong-text center-text'>
    $0.80&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;Level
    Number&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;Number of Qualifying Strings of Payments
  </p>
  <p>
    For example, if you
    have an Cash Advance Balance between $25.01 - $100 (Level 1) and have two (2) Qualifying Strings of Payments,
    your
    Statement Balance Fee will be $8.40 (or $10 less $1.60), and the reduction is calculated as follows:
    $0.80 x 1 x 2
    = $1.60. The maximum Fee Saver reduction you can earn is $6 per Level (achieved after 8
    Qualifying Strings of
    Payment). This will result in a Statement Balance Fee of $4 per Level.
  </p>
  <p class='underlined-text'>
    <i>
      Semi-monthly or Bi-weekly Billing Cycles:
    </i>
  </p>
  <p>
    Each time you make a Qualifying String of
    Payments your Statement Balance Fee will be reduced by $0.40 per Level listed
    in the Account Opening Disclosures in
    Section I. The Statement Balance Fee reduction is equal to:
  </p>
  <p class='strong-text center-text'>
    $0.40&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;Level Number&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;Number of Qualifying
    Strings of Payments
  </p>
  <p>
    For example, if you have an Cash Advance Balance between $25.01 - $100 (Level 1) and
    have two (2) Qualifying Strings of Payments,
    your Statement Balance Fee will be $4.20 (or $5.00 less $0.80), and
    the reduction is calculated as follows: $0.40 x 1 x 2 = $0.80.
    The maximum Fee Saver reduction you can earn is $3
    per Level (achieved after 8 Qualifying Strings of Payment).
    This will result in a Statement Balance Fee of $2 per
    Level.
  </p>
  <ol class='strong-text' start='4' type='A'>
    <li>
      Billing Cycle
    </li>
  </ol>
  <p>
    The length of
    your Billing Cycle depends on how often you receive income as identified in your application. If you receive
    income
    weekly or every other week, your Billing Cycles will be Bi-weekly and they will be generally be fourteen
    (14) days long. If you
    receive income twice a month, your Billing Cycles will be Semi-monthly and they will
    generally be fifteen (15) days long.
    If you receive income once a month, your Billing Cycles will be Monthly.
  </p>
  <p>
    If the frequency with which you receive income changes, you may request to change the length of your Billing
    Cycle and your payment
    due dates so that your payment due dates better align with the dates you receive income.
    Such request will only be considered if the
    frequency with which you receive income changes. To request to change
    your payment due dates, contact us via email at
    support@netcredit.com or by telephone at (877) 392-2014. Approval
    of any such request is at our discretion. In the event we approve such a request, after
    providing you with any
    notice and waiting any period required by applicable law, your payment due dates, the Statement Balance Fee,
    and
    Minimum Payment will change. See Section I, captioned “Account Opening Disclosures”, and Section V, captioned
    “Payments”, for details.
  </p>
  <ol class='strong-text' start='5' type='A'>
    <li>
      Statements
    </li>
  </ol>
  <p>
    You will receive a Statement for each Billing Cycle for which there is an Account Balance, or as otherwise
    required by law. Your Statement
    will be generated at least 14 days prior to your payment due date and we will email
    you a notification that your Statement is available in
    Account Home at the end of each Billing Cycle. Under certain
    circumstances, the law may not require us to send or make available to you a
    Statement, or may prohibit us from
    doing so.
  </p>
  <ol class='strong-text' start='6' type='A'>
    <li>
      Inadvertent Excessive Charges or Collection of
      Payments
    </li>
  </ol>
  <p>
    If a law which applies to this Agreement and which sets maximum charges is finally
    interpreted so that any Finance Charge collected or to
    be collected in connection with this Agreement exceed the
    permitted limits, then: (i) any such Finance Charge will automatically be reduced
    to the maximum permitted limit,
    retroactively effective as of the date of this Agreement, and as though this Agreement originally provided
    for the
    reduced Finance Charge; and (ii) any sums already collected from you which exceeded permitted limits will be
    refunded to you. Also,
    if we inadvertently collect more payments than permitted by this Agreement, we will refund
    to you any such excess payments. We may choose
    to make any refunds described in this section by reducing the amount
    you owe under this Agreement or by making a direct payment to you.
  </p>
  <p class='strong-text sub-title'>
    V.
    PAYMENTS
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Promise to Pay
    </li>
  </ol>
  <p>
    You promise to
    pay us for all amounts owed to us under this Agreement. You must pay us at least the Minimum Payment amount
    specified on your
    Statement by the payment due date specified on your Statement. All amounts in this Agreement are
    U.S. dollars and all payments must be made in U.S. dollars.
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Calculating the Minimum Payment
    </li>
  </ol>
  <p>
    Your Minimum Payment will be calculated at the close of each
    Billing Cycle, and will vary based on your Cash Advance Balance and whether your Billing Cycle
    is Monthly,
    Semi-monthly or Bi-weekly. Your Minimum Payment does not include any past due amounts. You can pay off your Account
    Balance faster by paying more
    than the Minimum Payment or paying the entire Account Balance in full.
  </p>
  <p>
    The Minimum Payment is calculated as follows:
  </p>
  <p class='underlined-text'>
    <i>
      Monthly Billing
      Cycles:
    </i>
  </p>
  <ul style='list-style-type: disc'>
    <li>
      <span>
        <i>
          If your Account Balance is less than
          $100:
        </i>
      </span>
      <span>
        your Minimum Payment is equal to your Account Balance.
      </span>
    </li>
    <li>
      <span>
        <i>
          If your Account Balance is more than $100:
        </i>
      </span>
      <span>
        your Minimum Payment is the
        greater of $100 or:
      </span>
      <ul style='list-style-type: circle'>
        <li>
          <span>
            5% of your Cash Advance
            Balance
          </span>
          <span class='strong-text'>
            plus
          </span>
        </li>
        <li>
          Statement Balance Fee
        </li>
      </ul>
    </li>
  </ul>
  <p class='underlined-text'>
    <i>
      Semi-monthly or Bi-weekly Billing Cycles:
    </i>
  </p>
  <ul style='list-style-type: disc'>
    <li>
      <span>
        <i>
          If your Account Balance is less than
          $50:
        </i>
      </span>
      <span>
        your Minimum Payment is equal to your Account Balance.
      </span>
    </li>
    <li>
      <span>
        <i>
          If your Account Balance is more than $50:
        </i>
      </span>
      <span>
        your Minimum Payment is the
        greater of $50 or:
      </span>
      <ul style='list-style-type: circle'>
        <li>
          <span>
            2.5% of your Cash Advance
            Balance
          </span>
          <span class='strong-text'>
            plus
          </span>
        </li>
        <li>
          Statement Balance Fee
        </li>
      </ul>
    </li>
  </ul>
  <p>
    You may pay more frequently than specified on any Statement, pay more than the Minimum
    Payment specified or pay your Account Balance in full at any time without penalty.
    If you make extra payments or
    larger payments in any Billing Cycle, you will still be required to pay at least the Minimum Payment each subsequent
    Billing Cycle, unless
    you have paid your Account Balance. If you pay only the Minimum Payment, you will generally
    pay more Statement Balance Fees and it will generally take you longer to pay
    off your Account Balance than if you
    make payments in excess of the Minimum Payment. Your Minimum Payment will increase if you take additional Cash
    Advances.
  </p>
  <ol class='strong-text' start='3' type='A'>
    <li>
      How to Make a Payment
    </li>
  </ol>
  <p>
    You
    may pay your Account Balance in full at any time without penalty. We also accept partial payments.
    You may make
    payments using the following payment methods currently available:
  </p>
  <ul style='list-style-type: disc'>
    <li>
      <span class='underlined-text'>
        AutoPay:
      </span>
      <span>
        You may authorize us to initiate recurring electronic
        fund transfers for your Minimum Payment amounts to be debited from your Checking Account.
      </span>
      <span class='strong-text'>
        You are not required to authorize us to initiate electronic fund transfers for your
        Minimum Payments
        in order to qualify for an open-end line of credit. If you selected AutoPay as your repayment
        method,
        see your Electronic Fund Transfer Authorization for details.
      </span>
    </li>
    <li>
      <span class='underlined-text'>
        One-time EFT (ACH):
      </span>
      <span>
        You may authorize us to initiate a one-time
        electronic fund transfer through the ACH network debited
        from your Checking Account via your Account Home or by
        calling us at (877) 392-2014.
        One-time electronic fund transfer payments must be authorized by 1 PM Eastern
        Time on
        the day before your payment due date to be considered timely. One-time electronic fund
        transfer
        payments authorized by 1PM Eastern Time will be credited as of the next Business Day, except
        as
        otherwise provided by this Agreement.
      </span>
    </li>
    <li>
      <span class='underlined-text'>
        Check or Money
        Order:
      </span>
      <span>
        By mailing us a check or money order to Republic Bank & Trust Company, c/o NetCredit,
        P.O. Box 206766,
        Dallas, TX 75320-6766. The individual account ID and customer name must be included with
        mailed payments.
        No correspondence should be included with any payment via mail. Payments must be made in U.S.
        dollars.
        All mailed payments, except disputed payments, must be mailed or delivered to the payment address
        shown
        on your Statement. Any mailed payment received after 5:00 PM ET will be credited as of the next
        Business
        Day. However, credit to your Account may be delayed for up to five (5) Business Days if we accept
        a payment
        that is: (i) not received by mail or messenger service at the payment address shown on your
        Statement; (ii) not
        made in U.S. dollars by a check or other negotiable instrument drawn on a financial
        institution located in the
        U.S. or by money order; or (iii) does not include your Account number on the
        memo line or elsewhere on the
        front of the check. Delayed crediting may cause you to incur additional
        fees and finance charges, subject to
        applicable law, and such payment may not count toward a Qualifying
        String of Payments. You may not postdate a
        check to us or include any restrictive endorsements on the
        check, such as \"paid in full\" or other restrictive
        terms. No payment will be deemed an accord and
        satisfaction without the prior written approval of one of our
        senior officers. We can accept partial
        payments, late payments, or payments with restrictive endorsements
        without losing any of our rights under this Agreement.
      </span>
    </li>
    <li>
      <span class='underlined-text'>
        Debit Cards and Prepaid Cards:
      </span>
      <span>
        Debits cards and prepaid cards are
        accepted for one-time payments via Account Home or by calling Customer Support. Please see
        <a href='https://www.netcredit.com/faq#repayment'>www.netcredit.com/faq#repayment</a>
        for details.
      </span>
    </li>
    <li>
      <span class='underlined-text'>
        Other Payment Methods:
      </span>
      <span>
        Additional options include
        sending us a MoneyGram or Western Union payment.
        Please see
        <a href='https://www.netcredit.com/faq#repayment'>www.netcredit.com/faq#repayment</a>
        for details.
      </span>
    </li>
  </ul>
  <p>
    We reserve the right to add or remove accepted payment methods at any time and will
    notify you
    of changes via email. For more information on the available payment methods,
    please see
    <a href='https://www.netcredit.com/faq'>www.netcredit.com/faq</a>
    under \"Repayment.\"
  </p>
  <p>
    You must make
    each Minimum Payment by the date it is due, according to your Statements.
    In order for your payment to be
    considered timely, it must be received by the cut-off
    time applicable to your method of payment. Any payment not
    actually received by the
    cut-off time applicable to your method of payment will not be considered timely.
    We
    reserve the right to change the cut-off time for any payment method at any time
    and we will notify you of changes
    via email. For the cut-off time applicable to
    your method of payment and any changes to these cutoff times, please
    visit
    <a href='https://www.netcredit.com/faq'>www.netcredit.com/faq</a>
    and review the \"How can I repay my
    personal loan or line of credit?\" section.
  </p>
  <ol class='strong-text' start='4' type='A'>
    <li>
      How We Apply
      Your Payments
    </li>
  </ol>
  <p>
    We apply payments to any outstanding Minimum Payment for which a Statement has
    been issued,
    in order of their payment due dates, beginning with the earliest due date. Payments are
    applied to
    each Minimum Payment in the following order: Statement Balance Fees, if any,
    and then to the remaining portion of
    the Minimum Payment. Once all Minimum Payments have
    been satisfied, payments are applied to any Account Balance
    that remains unpaid.
  </p>
  <p>
    If your Account is current, you have setup AutoPay, and we receive an early payment
    at
    least two (2) Business Days before your next due date, the payment will be applied to
    your currently due
    Minimum Payment. If any such early payment is less than the amount
    of that Minimum Payment, then you must pay the
    difference on or before your due date.
    If you have setup AutoPay, we will debit the difference between the amount
    paid and
    the Minimum Payment on your due date. If your payment is greater than the currently
    due Minimum Payment,
    the excess amount will be applied to the Account Balance.
    If we receive a payment within two (2) Business Days of a
    Minimum Payment due date
    or on the due date, we may be unable to credit the amount to your currently due
    Minimum
    Payment, and we may instead debit the entire Minimum Payment on that due date.
  </p>
  <p>
    If you have elected a
    payment method other than AutoPay and we receive an early
    payment before your next due date, the payment will be
    applied to your currently
    due Minimum Payment. If any such early payment is less than the amount of that
    Minimum
    Payment, then you must pay the difference on or before your due date.
  </p>
  <p>
    For more details, please visit
    <a
      href='https://www.netcredit.com/faq#how-will-my-payment-be-applied'>https://www.netcredit.com/faq#how-will-my-payment-be-applied</a>.
  </p>
  <p class='strong-text sub-title'>
    VI. ADDITIONAL TERMS & CONDITIONS
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Credit Reporting
    </li>
  </ol>
  <p class='strong-text'>
    You authorize and instruct us or our
    service providers, including NetCredit,
    to obtain credit and other reports about you from time to time for any
    permissible purpose,
    including, but not limited to, the opening, monitoring and maintenance of your Account.
  </p>
  <p class='strong-text'>
    We may report information about your Account to credit bureaus.
    Late payments, missed
    payments, or other defaults on your Account may be reflected in your credit report.
  </p>
  <p class='strong-text'>
    You have the right to dispute the accuracy of information we have reported.
    If you believe that any information
    about your Account that we have furnished
    to a consumer reporting agency is inaccurate, or if you believe that you
    have
    been the victim of identity theft in connection with any extension of credit
    made by us, write to Republic
    Bank & Trust Company, c/o NetCredit, Attn: General
    Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. In
    your
    letter (i) provide your name and Account number, (ii) identify the specific
    information that is being
    disputed, (iii) explain the basis for the dispute
    and (iv) provide any supporting documentation you have that
    substantiates the
    basis of the dispute. If you believe that you have been the victim of identity
    theft, submit an
    identity theft police report to the address specified above.
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Our Right to Request Income Information and Your Duty to Tell Us About Changes in Your Circumstances
    </li>
  </ol>
  <p>
    The Credit Limit established for your Account is based in part upon your income.
    We may require you to
    provide information about your current income from time to time.
    You agree that if we contact you via mail or
    e-mail using the information you have
    provided us, we will be deemed to have provided such communication to you,
    regardless
    of whether such communication is returned undeliverable. You further agree to immediately
    inform us of
    any significant change in your circumstances, including changes in your
    income, income frequency, employment,
    Checking Account, home address, e-mail address,
    or telephone number. You can update your information by logging
    into your Account Home at
    <a href='https://www.netcredit.com'>www.netcredit.com</a>
    or calling Customer Support at
    (877) 392-2014.
  </p>
  <ol class='strong-text' start='3' type='A'>
    <li>
      Contacting You; Phone and Text Messages;
      Call Recording
    </li>
  </ol>
  <p>
    In exchange for us providing you with the Account, you authorize the Messaging
    Parties
    to contact you using automatic telephone dialing systems, artificial or prerecorded
    voice message systems,
    text messaging systems and automated e-mail systems to discuss
    issues related to this Agreement or the Account,
    including information about upcoming
    payment due dates, missed payments and returned payments. You authorize the
    Messaging
    Parties to make such contacts using any telephone numbers (including wireless, landline
    and VOIP
    numbers) or e-mail addresses you supply to the Messaging Parties in connection
    with this Agreement, the Messaging
    Parties' servicing of the Account or collection of
    amounts you owe the Messaging Parties, or any other matter. You
    understand that anyone
    with access to your telephone or e-mail account may listen to or read the messages
    the
    Messaging Parties leave or send you, and you agree that the Messaging Parties will have
    no liability for
    anyone accessing such messages. You further understand that, when you
    receive a telephone call, text message or
    e-mail, you may incur a charge from the company
    that provides you with telecommunications, wireless and/or Internet
    services, and you
    agree that the Messaging Parties will have no liability for such charges. You
    expressly
    authorize the Messaging Parties to monitor and record your calls with the Messaging Parties.
    You agree
    that this consent forms part of a bargained for exchange. If you have the right
    to revoke text messages and calls
    to your cell phone or to receive artificial or prerecorded
    voice message system calls, you may exercise this right
    by calling the Messaging Parties
    at (877) 392-2014; or, to stop text messages, by replying \"STOP\" to any text
    message the
    Messaging Parties send you.
  </p>
  <ol class='strong-text' start='4' type='A'>
    <li>
      Correspondence
    </li>
  </ol>
  <p>
    To the extent permitted under applicable law, any written or electronic correspondence
    you send
    to us will not be effective until we receive and have had a reasonable opportunity
    to act on such correspondence.
    Any written or electronic correspondence we send to you will,
    however, be effective and deemed delivered when
    mailed to you at your mail address (or your
    email address if you have authorized electronic communications) as it
    appears on our records.
  </p>
  <ol class='strong-text' start='5' type='A'>
    <li>
      Bankruptcy Notification
    </li>
  </ol>
  <p>
    All bankruptcy notices and related correspondence to us should be sent to Republic
    Bank & Trust Company,
    c/o NetCredit, Attn: Bankruptcy Department, 175 W. Jackson
    Blvd., Suite 600, Chicago, IL 60604.
  </p>
  <ol class='strong-text' start='6' type='A'>
    <li>
      Amendments
    </li>
  </ol>
  <p>
    You agree that we may change any of
    the terms of this Agreement, including the
    amount or method of computing all Finance Charges and Fee Saver. Any
    change to
    Statement Balance Fees will only apply to Cash Advances taken after the effective
    date of the change. We
    may also reduce your Credit Limit. We will tell you if we
    do so. If we change the terms of this Agreement, we will
    provide to you notice of
    the change in accordance with the federal Truth in Lending Act, Ohio's law and
    this
    Agreement. Unless Ohio law or federal law otherwise requires, we may provide notice
    to you in writing or by
    email.
  </p>
  <ol class='strong-text' start='7' type='A'>
    <li>
      No Waiver of Our Rights
    </li>
  </ol>
  <p>
    You
    agree that if we grant any waiver, modification or other indulgence of any kind
    at any time, it shall apply only to
    the specific instance involved and will not act
    as a waiver, modification or indulgence for any other or future
    act, event or condition.
    We may delay enforcing any of our rights under this Agreement without losing them.
  </p>
  <ol class='strong-text' start='8' type='A'>
    <li>
      Entire Agreement
    </li>
  </ol>
  <p>
    This Agreement constitutes the
    entire agreement between the parties and no other agreements,
    representations or warranties other than those stated
    herein shall be binding unless reduced
    in writing and signed by all parties.
  </p>
  <ol class='strong-text' start='9' type='A'>
    <li>
      Additional Representations and Warranties
    </li>
  </ol>
  <p>
    You represent and warrant
    that you have the right to enter into this Agreement, and that you are at least 18 years of age.
  </p>
  <ol class='strong-text' start='10' type='A'>
    <li>
      Governing Law
    </li>
  </ol>
  <p class='strong-text'>
    Except as
    otherwise expressly provided in this Agreement, this Agreement (other than the Arbitration Provision),
    your
    Account, and all related disputes are governed by applicable federal law and the laws of the State of Ohio,
    without
    regard to its conflict of law provisions.
  </p>
  <p class='strong-text'>
    Except as provided in the Arbitration
    Provision, if any provision of this Agreement is determined to be void,
    invalid or unenforceable under any
    applicable law, rule or regulation, it shall not affect the validity or
    enforceability of any other provision of
    this Agreement and such other provisions will remain valid and enforceable.
  </p>
  <p class='strong-text'>
    Kansas,
    Maine, and Wyoming Residents Only:
  </p>
  <p class='strong-text'>
    If you are a resident of Kansas, Maine or
    Wyoming, this Agreement is governed by the laws of Kansas, Maine,
    or Wyoming, respectively, except to the extent
    preempted by federal law.
  </p>
  <ol class='strong-text' start='11' type='A'>
    <li>
      Assignment
    </li>
  </ol>
  <p>
    We may sell, assign or otherwise transfer your Account and our rights under this Agreement and amounts owed
    by
    you to another creditor at any time without notice to you unless required by law. If we do so, this
    Agreement will
    remain in effect and will be binding on and will inure to the benefit of our assign(s).
    You may not transfer your
    rights under this Agreement. Any attempt to do so will be void.
  </p>
  <ol class='strong-text' start='12' type='A'>
    <li>
      Reasonable Accommodation
    </li>
  </ol>
  <p>
    Individuals with disabilities who require an accommodation to
    access our products or services should contact
    us via email at support@netcredit.com; by telephone at (877)
    392-2014; or by mail at Republic Bank & Trust
    Company, c/o NetCredit, Attn: General Counsel, 175 W. Jackson Blvd.,
    Suite 600, Chicago, IL 60604.
    Please note that we may need advance notice to provide certain accommodations.
  </p>
  <ol class='strong-text' start='13' type='A'>
    <li>
      Miscellaneous
    </li>
  </ol>
  <p>
    The section headings used in
    this Agreement are for convenience of reference only and do not in any way
    limit or define your or our rights or
    obligations hereunder. Except as otherwise provided in this Agreement
    or as required by applicable law, we will not
    be responsible for any claim or defense you may have against
    any third party that arises out of or in connection
    with this Agreement.
  </p>
  <p class='strong-text sub-title'>
    VII. Your Billing Rights
  </p>
  <p>
    NOTE: Federal
    law provides the following rights if you communicate with us in writing. You may either send us a letter,
    as
    described below, or an email. The email should be sent to support@netcredit.com with the subject
    designated “LOC
    Billing Error”.
  </p>
  <p>
    We will treat an email as a sufficient notice of your billing rights inquiry as long as
    the email
    contains all of the information requested below. You may also call us, but doing so will not
    preserve
    your rights under federal law.
  </p>
  <p class='strong-text'>
    Your Billing Rights: Keep This Document For
    Future Use
  </p>
  <p>
    This notice tells you about your rights and our responsibilities under the Fair Credit
    Billing Act.
  </p>
  <p class='strong-text'>
    What To Do If You Find A Mistake On Your Statement
  </p>
  <p>
    If you
    think there is an error on your statement, write to us at:
  </p>
  <p class='statement-mail-address'>
    Republic Bank
    & Trust Company, c/o NetCredit
    <br>
    Attn: LOC Department Manager
    <br>
    4030 Smith Road
    <br>
    Cincinnati, OH
    45209
    <br>
    <br>
    or
    <br>
    <br>
    support@netcredit.com
    <br>
    Subject: LOC Billing Error
  </p>
  <p>
    In your
    letter or email, give us the following information:
  </p>
  <ul class='statement-mail-address' style='list-style-type: disc'>
    <li>
      <i>
        Account information:
      </i>
      Your name and Account number.
    </li>
    <li>
      <i>
        Dollar amount:
      </i>
      The dollar amount of the suspected error.
    </li>
    <li>
      <i>
        Description of
        problem:
      </i>
      If you think there is an error on your bill, describe what you believe is wrong and why you
      believe it is a mistake.
    </li>
  </ul>
  <p>
    You must contact us:
  </p>
  <ul class='statement-mail-address' style='list-style-type: disc'>
    <li>
      Within sixty (60) days after the error appeared on your statement.
    </li>
    <li>
      At least three (3) Business Days before an AutoPay is scheduled,
      if you want to stop payment on the amount
      you think is wrong.
    </li>
  </ul>
  <p>
    You must notify us of any potential errors
    <i>
      in writing or
      electronically.
    </i>
    You may call us, but if you do we are not required to investigate any potential
    errors and
    you may have to pay the amount in question.
  </p>
  <p class='strong-text'>
    What Will Happen After We Receive Your
    Letter or Email
  </p>
  <p>
    When we receive your letter or email, we must do two things:
  </p>
  <ol class='statement-mail-address'>
    <li>
      Within thirty (30) days of receiving your letter or email, we must tell you
      that we
      received your letter or email. We will also tell you if we have already corrected the error.
    </li>
    <li>
      Within ninety (90) days of receiving your letter or email,
      we must either correct the error or explain to you
      why we believe the statement is correct.
    </li>
  </ol>
  <p>
    While we investigate whether or not there has been an
    error:
  </p>
  <ul class='statement-mail-address' style='list-style-type: disc'>
    <li>
      We cannot try to collect the
      amount in question, or report you as delinquent on that amount.
    </li>
    <li>
      The charge in question may remain on
      your statement, and we may continue to charge you fees on that amount.
    </li>
    <li>
      While you do not have to pay
      the amount in question, you are responsible for the remainder of your Account Balance.
    </li>
    <li>
      We can apply
      any unpaid amount against your Credit Limit and Available Credit.
    </li>
  </ul>
  <p>
    After we finish our
    investigation, one of two things will happen:
  </p>
  <ul class='statement-mail-address' style='list-style-type: disc'>
    <li>
      <i>
        If we made a mistake:
      </i>
      You will not have to pay the amount in
      question or any fees related to that amount.
    </li>
    <li>
      <i>
        If we do not believe there was a
        mistake:
      </i>
      You will have to pay the amount in question, along with applicable fees.
      We will send you a
      statement of the amount you owe and the date payment is due.
      We may then report you as delinquent if you do not
      pay the amount that we think you owe.
    </li>
  </ul>
  <p>
    If you receive our explanation but still believe your
    statement is wrong, you must write or email us within
    <i>
      ten (10) days
    </i>
    telling us that you still refuse to
    pay.
  </p>
  <p>
    If you do so, we cannot report you as delinquent without also reporting that you are questioning
    your statement.
    We must tell you the name of anyone to whom we reported you as delinquent, and we must let those
    organizations
    know when the matter has been settled between us.
  </p>
  <p>
    If we do not follow all of the rules
    above, you do not have to pay the first $50 of the amount you question even if your statement is correct.
  </p>
  <p class='strong-text sub-title' id='waiver_provision'>
    VIII. WAIVER OF JURY TRIAL AND CLASS ACTION WAIVER
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Waiver of Jury Trial
    </li>
  </ol>
  <p>
    Unless prohibited by
    applicable law, if you sign this Agreement, you waive your right to have a jury
    trial to resolve any dispute you
    may have against us or a related third party. This Waiver of Jury
    Trial provision does not apply to you if, at the
    time of entering into this Agreement, you are a resident of Arkansas.
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Class Action Waiver
    </li>
  </ol>
  <p>
    Unless prohibited by applicable law, if you sign this
    Agreement, you waive your right to ask a
    court or an arbitrator to allow you to pursue any claims on a class action
    basis or in a
    representative capacity on behalf of the general public, other borrowers or other persons
    similarly
    situated. Furthermore, claims brought by you against us or by us against you
    may not be joined or consolidated with
    claims brought by or against someone other than you,
    unless otherwise agreed to in writing by all parties. For
    purposes of this Class Action Waiver, the terms \"<strong>we</strong>\", \"<strong>us</strong>\" and
    \"<strong>our</strong>\" shall also include Republic Bank & Trust Company and each of its employees,
    directors,
    officers, shareholders, governors, managers, members, parent companies, affiliated entities,
    successors
    or assignees, the servicer of your Account (including but not limited to NetCredit) and any third
    party
    who is named as a co-defendant with us in a claim asserted by you. In the event of a conflict
    between this Class
    Action Waiver provision and the Arbitration Provision, the Arbitration Provision shall control.
  </p>
  <p class='strong-text sub-title' id='arbitration_provision'>
    IX. ARBITRATION PROVISION
  </p>
  <p>
    Arbitration is a
    process in which persons with a dispute(s): (a) agree to submit their
    dispute(s) to a neutral third person (an
    \"<strong>arbitrator</strong>\") for a decision; and (b) waive their rights to file a lawsuit in court to
    resolve
    their dispute(s). Each party to the dispute(s) has an opportunity to present some evidence
    to the
    arbitrator. Pre-arbitration discovery may be limited. Arbitration proceedings are
    private and less formal than
    court trials. The arbitrator will issue a final and binding
    decision resolving the dispute(s), which may be
    enforced as a court judgment. A court rarely
    overturns an arbitrator's decision.
  </p>
  <p class='strong-text'>
    THIS ARBITRATION PROVISION APPLIES UNLESS YOU HAVE EXERCISED OR EXERCISE YOUR RIGHT TO REJECT ARBITRATION.
  </p>
  <p class='strong-text'>
    READ THIS ARBITRATION PROVISION CAREFULLY AS IT WILL HAVE A SUBSTANTIAL IMPACT ON HOW LEGAL
    CLAIMS YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED.
  </p>
  <p class='strong-text'>
    YOU HAVE THE RIGHT TO
    OPT-OUT OF (NOT BE BOUND BY) THIS ARBITRATION PROVISION AS DESCRIBED BELOW. IF YOU DO NOT DO SO:
  </p>
  <ol type='i'>
    <li>
      <span class='strong-text'>
        YOU ARE WAIVING YOUR RIGHT TO HAVE A JURY OR A COURT, OTHER THAN A
        SMALL CLAIMS COURT OR SIMILAR COURT OF LIMITED
        JURISDICTION, RESOLVE ANY CLAIM THAT IS SUBJECT TO ARBITRATION;
        and
      </span>
    </li>
    <li>
      <span class='strong-text'>
        YOU ARE WAIVING YOUR RIGHT TO SERVE AS A REPRESENTATIVE,
        AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER
        REPRESENTATIVE CAPACITY, AND/OR TO PARTICIPATE AS A MEMBER OF A
        CLASS OF CLAIMANTS, IN COURT OR IN ARBITRATION
        WITH RESPECT TO ANY CLAIM THAT IS SUBJECT TO
        ARBITRATION.
      </span>
    </li>
  </ol>
  <p>
    THEREFORE, YOU ACKNOWLEDGE AND AGREE AS FOLLOWS:
  </p>
  <ol class='strong-text' start='1' type='A'>
    <li>
      Interstate Commerce
    </li>
  </ol>
  <p>
    This Arbitration Provision is
    made pursuant to a transaction involving interstate commerce and shall be governed by the Federal Arbitration Act
    (\"<strong>FAA</strong>\"), 9 U.S.C. &sect; 1 et seq., and not by any state arbitration law.
  </p>
  <ol class='strong-text' start='2' type='A'>
    <li>
      Scope
    </li>
  </ol>
  <p>
    For purposes of this Arbitration Provision,
    the word \"<strong>Claim</strong>\" has the broadest possible meaning and includes, without limitation (a) all
    federal or state law claims, disputes or controversies,
    whether preexisting, present or future, arising from or
    relating directly or indirectly to this Agreement, the relationship between
    you and us, the information you gave us
    before entering into this Agreement, including your application, and/or any past agreement
    or agreements between
    you and us, any loan or credit product or related product or services obtained from us and any
    advice,
    recommendations, solicitations, communications, disclosures, promotions or advertisements concerning the
    same; (b) all initial claims,
    counterclaims, cross-claims and third-party claims and claims which arose before the
    effective date of this Arbitration
    Provision; (c) all common law claims based upon contract, tort, fraud, or other
    intentional torts; (d) all claims based upon a
    violation of any local, state or federal constitution, statute,
    ordinance or regulation, including without limitation all claims
    alleging unfair, deceptive or unconscionable trade
    practices; (e) all claims asserted by us against you, including claims for money
    damages to collect any sum we
    claim you owe us; (f) all claims asserted by you individually against us and/or any of our employees,
    directors,
    officers, shareholders, governors, managers, members, parent company or affiliated entities, or assignees or against
    the
    servicer of your Loan (including but not limited to NetCredit) (hereinafter collectively referred to as
    \"<strong>related third parties</strong>\"), including claims for money damages, restitution and/or equitable or
    injunctive relief; (g) claims asserted by you against other
    persons and entities if you assert a Claim against such
    other persons and entities in connection with a Claim you assert against us
    or related third parties; (h) all data
    breach or privacy claims arising from or relating directly or indirectly to the disclosure by
    us or related third
    parties of any non-public personal information about you; and (i) any claims seeking restitution pursuant
    to
    California Business and Professions Code &sect;&sect; 17200 et seq. Notwithstanding the foregoing, the word
    \"Claim\" does not
    include: (i) any dispute or controversy about the validity, enforceability, coverage or scope of
    the provision titled \"Class Action
    Waiver\" set forth below or (ii) the specific components of a dispute or
    controversy seeking public injunctive relief pursuant to
    California Business and Professions Code &sect;&sect;
    17200 et seq., all such disputes or controversies are for a court and not an
    arbitrator to decide. However, any
    dispute or controversy that concerns the validity or enforceability of the Agreement as a whole
    is for the
    arbitrator, not a court, to decide. In addition, this Arbitration Provision will not apply to (1) any individual
    action
    brought by you in small claims court or your state's equivalent court, unless such action is transferred,
    removed, or appealed to
    a different court; or (2) any disputes that are the subject of a class action filed in
    court that is pending as of the effective
    date of this Arbitration Provision in which you are alleged to be a
    member of the putative class for as long as such class action is pending.
  </p>
  <p>
    Any dispute or controversy
    regarding the enforceability of the provision titled “Class Action Waiver” must be decided by a court of
    competent
    jurisdiction as a threshold matter prior to arbitrating the merits of any Claim. The specific components of any
    dispute or
    controversy seeking public injunctive relief pursuant to California Business and Professions Code
    &sect;&sect; 17200 et seq. may only be
    decided after a ruling on all Claims by the arbitrator.
  </p>
  <ol class='strong-text' start='3' type='A'>
    <li>
      Class Action Waiver
    </li>
  </ol>
  <p class='strong-text'>
    Notwithstanding any other provision of this Arbitration Provision, if either you or we elect to arbitrate a Claim,
    neither you
    nor we will have the right: (a) to participate in a class action, private attorney general action or
    other representative
    action in court or in arbitration, either as a class representative or class member, or (b) to
    join or consolidate Claims
    with Claims of any other persons. (Provided, however, that the Class Action Waiver does
    not apply to any lawsuit or
    administrative proceeding filed against us by a state or federal government agency even
    when such agency is seeking relief
    on behalf of a class of borrowers including you. This means that we will not
    have the right to compel arbitration of any
    claims brought by such an agency). An award in arbitration shall
    determine the rights and obligations of the named parties
    only, and only with respect to the Claim(s) in
    arbitration, and shall not (i) determine the rights, obligations, or interests
    of anyone other than a named party,
    or resolve any Claim of anyone other than a named party; nor (ii) make an award for the
    benefit of, or against,
    anyone other than a named party. No arbitration administrator or arbitrator shall have the power or
    authority to
    waive, modify, or fail to enforce this section, and any attempt to do so, whether by rule, policy,
    arbitration
    decision or otherwise, shall be invalid and unenforceable. Any challenge to the validity of this Class
    Action Waiver shall
    be determined exclusively by a court and not by the administrator or any arbitrator.
  </p>
  <ol class='strong-text' start='4' type='A'>
    <li>
      Electing Arbitration
    </li>
  </ol>
  <p>
    A party may elect
    arbitration of a Claim by sending the other party written notice by certified mail return receipt requested
    of
    their intent to arbitrate and setting forth the subject of the dispute along with the relief requested. Your notice
    must
    be sent to Republic Bank & Trust Company, c/o NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite
    600, Chicago,
    IL 60604, and our notice must be sent to the most recent address for you in our files. If a lawsuit
    concerning the Claim has
    been filed, such notice can be provided by papers filed in the lawsuit, such as a motion
    to compel arbitration.
  </p>
  <ol class='strong-text' start='5' type='A'>
    <li>
      Administrator
    </li>
  </ol>
  <p>
    Regardless of who demands arbitration, you shall have the right to select either of the following arbitration
    organizations to administer the arbitration: the American Arbitration Association, 120 Broadway, Floor 21, New York,
    NY 10271 (1-800-778-7879), <u>http://www.adr.org</u>; or JAMS, 8401 N. Central Expressway, Suite 610, Dallas, TX
    75225 (1-800-352-5267), <u>http://jamsadr.com</u>. However, the parties may agree to a local arbitrator who is an
    attorney, retired judge, or arbitrator registered and in good standing with a bona fide arbitration association and
    arbitrate pursuant to the arbitrator's rules. If the AAA and JAMS are unable or unwilling to serve as administrator,
    or the parties are unable to agree upon another administrator, a court with jurisdiction shall determine the
    administrator or arbitrator. The arbitration hearing will take place at a location reasonably convenient to where
    you reside.
  </p>
  <p>
    If you demand arbitration, you must inform us in your demand of the arbitration organization
    you have selected or whether
    you desire to select a local arbitrator. If related third parties or we demand
    arbitration, you must notify us within 20
    days in writing by certified mail, return receipt requested, of your
    decision to select an arbitration organization or
    your desire to select a local arbitrator. Your notice must be
    sent to NetCredit, Attn: General Counsel, 175 W. Jackson
    Blvd., Suite 600, Chicago, IL 60604. If you fail to notify
    us, then we have the right to select an arbitration organization.
    The arbitration will be governed by the rules and
    procedures of this arbitration organization applicable to individual
    consumer disputes. You may get a copy of the
    rules and procedures by contacting the arbitration organization listed above.
    In an event of a conflict between the
    provisions of the Arbitration Provision, on the one hand, and any applicable rules
    of the AAA or JAMS or other
    administrator used or any other terms of this Agreement, on the other hand, the provisions
    of this Arbitration
    Provision shall control. If a party files a lawsuit in court asserting Claim(s) that are subject to
    arbitration and
    the other party files a motion to compel arbitration with the court which is granted, it will be the
    responsibility
    of the party prosecuting the Claim(s) to select an arbitration administrator in accordance with this
    paragraph and
    commence the arbitration proceeding in accordance with the administrator's rules and procedures.
  </p>
  <ol class='strong-text' start='6' type='A'>
    <li>
      Non-Waiver
    </li>
  </ol>
  <p>
    Even if all parties have opted to
    litigate a Claim in court, you or we may elect arbitration with respect to any Claim
    made by a new party or any
    Claim later asserted by a party in that or any related or unrelated lawsuit (including a
    Claim initially asserted
    on an individual basis but modified to be asserted on a class, representative or
    multi-party basis). Nothing in
    that litigation shall constitute a waiver of any rights under this Arbitration Provision.
    For example, if we file a
    lawsuit against you in court to recover amounts due under the Agreement, you have the right to
    request arbitration,
    but if you do not elect to request arbitration, we reserve and do not waive the right to request
    arbitration of any
    Claim (including any counterclaim) you later assert against us in that or any related or unrelated
    lawsuit. This
    Arbitration Provision will apply to all Claims, even if the facts and circumstances giving rise to the
    Claims
    existed before the effective date of this Arbitration Provision.
  </p>
  <ol class='strong-text' start='7' type='A'>
    <li>
      Arbitrator and Award
    </li>
  </ol>
  <p>
    Any arbitrator must be a practicing attorney with ten or more years
    of experience or a retired judge. The arbitrator
    will not be bound by judicial rules of procedure or evidence that
    would apply in a court, nor by state or local laws
    that relate to arbitration proceedings. The arbitrator will
    apply the same statutes of limitations and privileges
    that a court would apply if the matter were pending in court.
    The arbitrator may decide, with or without a hearing,
    any motion that is substantially similar to a motion to
    dismiss for failure to state a claim or a motion for summary
    judgment. In determining liability or awarding damages
    or other relief, the arbitrator will follow the applicable
    substantive law, consistent with the FAA, which would
    apply if the matter had been brought in court. The arbitrator
    may award any damages or other relief or remedies
    that would apply under applicable law to an individual action
    brought in court, including, without limitation,
    punitive damages (which shall be governed by the Constitutional
    standards employed by the courts) and private
    injunctive relief as allowed by law. No arbitration award involving
    the parties will have any preclusive effect as
    to issues or claims in any dispute involving anyone who is not a
    party to the arbitration, nor will an arbitration
    award in prior disputes involving other parties have preclusive
    effect in an arbitration between the parties to
    this Arbitration Provision. Nothing contained in this Arbitration
    Provision shall preclude either of the parties
    from pursuing provisional pre-judgment relief in court, including
    pre-judgment collection remedies and preliminary
    injunctive relief.
  </p>
  <ol class='strong-text' start='8' type='A'>
    <li>
      Fees and Costs
    </li>
  </ol>
  <p>
    At
    your written request, we will pay all filing, hearing and/or other fees charged by the administrator and
    arbitrator
    to you for Claim(s) asserted by you in an individual arbitration after you have paid an amount
    equivalent to the fee,
    if any, for filing such Claim(s) in state or federal court (whichever is less) in the
    judicial district in which you
    reside. (If you have already paid a filing fee for asserting the Claim(s) in court,
    you will not be required to pay that
    amount again). We will not seek reimbursement of such fees from you even if we
    prevail in the arbitration. In addition,
    the administrator may have a procedure whereby you can seek a waiver of
    fees charged to you by the administrator and
    arbitrator. We will always pay any fees or expenses that we are
    required to pay by law or the administrator's rules
    or that we are required to pay for this Arbitration Provision
    to be enforced. The arbitrator will have the authority
    to award fees and costs of attorneys, witnesses and experts
    to the extent permitted by this Agreement, the administrator's
    rules or applicable law. With respect to Claim(s)
    asserted by you in an individual arbitration, we will pay your reasonable
    attorney, witness and expert fees and
    costs if and to the extent you prevail, if applicable law requires us to or if
    we must bear such fees and costs in
    order for this Arbitration Provision to be enforced. At the timely request of
    either party, the arbitrator shall
    write a brief explanation of the grounds for the decision.
  </p>
  <ol class='strong-text' start='9' type='A'>
    <li>
      Appeal
    </li>
  </ol>
  <p>
    The arbitrator's award shall be final and binding on all parties, except as
    follows:&nbsp; (1) either party can appeal
    or seek review of the award in any appropriate forum as provided for
    under the FAA; (2) we can appeal or seek review
    of any part of the award in any appropriate forum that provides for
    injunctive relief; and (3) if the amount in
    controversy exceeds $50,000, and if permitted by the Administrator's
    rules, you or we can, within 14 days after the
    entry of the award by the arbitrator, appeal the entire award to a
    three-arbitrator panel administered by the
    Administrator. The panel shall reconsider anew any aspect of the initial
    award requested by the appealing party.
    The decision of the panel shall be by majority vote. Reference in this
    Arbitration Provision to \"the arbitrator\"
    shall mean the panel if an appeal of the arbitrator's decision has been
    taken. The costs of such an appeal will
    be borne in accordance with the paragraph above titled \"Fees and Costs.\"
    Any final decision of the appeal is
    subject to judicial review only as provided under the FAA. A judgment on the
    award may be entered by any court having jurisdiction.
  </p>
  <ol class='strong-text' start='10' type='A'>
    <li>
      Notice and Cure; Special Payment
    </li>
  </ol>
  <p>
    Prior to initiating a Claim, you may send us a written
    Claim Notice. In order for a Claim Notice to be valid and effective,
    it must: (a) state your name, address and loan
    number; (b) be signed by you; (c) describe the basis of your Claim and the
    amount you would accept to resolve the
    Claim; (d) state that you are exercising your rights under the \"Notice and Cure\"
    paragraph of the Arbitration
    Provision; and (e) be sent to us by certified mail, return receipt requested, at Republic
    Bank & Trust Company, c/o
    NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. This
    is the sole and only
    method by which you can submit a Claim Notice. Upon receipt of a Claim Notice, we will credit you
    for the standard
    cost of a certified letter. You must give us a reasonable opportunity, not less than 30 days, to
    resolve the Claim.
    If, and only if, (i) you submit a Claim Notice in accordance with this paragraph on your own
    behalf (and not on
    behalf of any other party); (ii) you cooperate with us by promptly providing the information we
    reasonably request;
    (iii) we refuse to provide you with the relief you request before an arbitrator is appointed;
    and (iv) the matter
    then proceeds to arbitration and the arbitrator subsequently determines that you were entitled
    to such relief (or
    greater relief), you will be entitled to a minimum award of at least $7,500 (not including any
    arbitration fees and
    attorneys' fees and costs to which you will also be entitled). We encourage you to address all
    Claims you have in a
    single Claim Notice and/or a single arbitration. Accordingly, this $7,500 minimum award is a single
    award that
    applies to all Claims you have asserted or could have asserted in the arbitration, and multiple awards
    of $7,500
    are not contemplated.
  </p>
  <ol class='strong-text' start='11' type='A'>
    <li>
      Binding Effect; Survival;
      Severability
    </li>
  </ol>
  <p>
    This Arbitration Provision is binding upon and benefits you, your respective
    heirs, successors and assigns.
    The Arbitration Provision is binding upon and benefits us, our successors and
    assigns, and related third parties.
    The Arbitration Provision shall survive any bankruptcy to the extent consistent
    with applicable bankruptcy law.
    The Arbitration Provision survives any termination, amendment, expiration or
    performance of this Agreement and
    any transaction between you and us and continues in full force and effect unless
    you and we otherwise agree in writing.
    If any part of this Arbitration Provision is deemed or found to be
    unenforceable for any reason,
    the remainder shall be enforceable, except as follows:
  </p>
  <p class='statement-mail-address'>
    <i>
      The parties to this Arbitration Provision acknowledge that the provision
      titled \"Class Action Waiver\"
      is material and essential to the arbitration of any disputes between the parties
      and is non-severable
      from this Arbitration Provision. If the Class Action Waiver is limited, voided or found
      unenforceable,
      then the parties' Arbitration Provision (except for this sentence) shall be null and void with
      respect
      to such proceeding, subject to the right to appeal the limitation or invalidation of the Class
      Action
      Waiver. The parties acknowledge and agree that under no circumstances will a class action be
      arbitrated.
    </i>
  </p>
  <ol class='strong-text' start='12' type='A'>
    <li>
      Opt-Out Process
    </li>
  </ol>
  <p>
    You may choose to opt out of this Arbitration Provision but only by following the process set forth below.
    If you
    do not wish to be subject to this Arbitration Provision, then you must notify us in writing
    postmarked within sixty
    (60) calendar days of the date of this Agreement at the following address:
    Republic Bank & Trust Company, c/o
    NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600,
    Chicago, IL 60604. Your notice must be sent to
    us by certified mail, return receipt requested. Upon
    receipt of an Opt-Out Notice, we will credit you for the
    standard cost of a certified letter. Your
    Opt-Out Notice must include your name, address, Social Security number,
    the date of this Agreement,
    a statement that you wish to opt out of the Arbitration Provision and must not be sent
    with any other
    correspondence. Indicating your desire to opt out of this Arbitration Provision will not affect
    your
    other rights or responsibilities under this Agreement, and applies only to this Arbitration Provision between
    you and us.
  </p>
  <p class='strong-text sub-title'>
    X. Important Notices
  </p>
  <p>
    <span class='strong-text underlined-text'>
      All Borrowers
    </span>
    <br>
    <span>
      You agree that we may access your
      credit report in connection with any transaction, or extension of credit,
      and on an ongoing basis, for the
      purpose of reviewing this Agreement, taking collection action on this Agreement,
      or for any other legitimate
      purposes associated with this Agreement. Upon your request, you will be informed
      of whether or not a consumer
      credit report was ordered, and if it was, you will be given the name and address
      of the consumer reporting agency
      that furnished the report.
    </span>
    <span class='strong-text'>
      As required by law, you are hereby notified that
      a negative credit report reflecting on your credit record may
      be submitted to a credit reporting agency if you
      fail to fulfill the terms of your credit obligations.
    </span>
  </p>
  <p>
    <span class='strong-text underlined-text'>
      All Borrowers
    </span>
    <br>
    <span class='strong-text'>
      ORAL AGREEMENTS OR
      ORAL COMMITMENTS TO LOAN MONEY, EXTEND CREDIT, OR TO FORBEAR FROM ENFORCING REPAYMENT OF
      A DEBT INCLUDING
      PROMISES TO EXTEND OR RENEW SUCH DEBT MAY NOT BE ENFORCEABLE UNDER THE APPLICABLE LAW OF THE
      STATE WHERE YOU
      RESIDE. TO PROTECT YOU (AS THE BORROWER) AND US (AS THE LENDER) FROM MISUNDERSTANDING OR
      DISAPPOINTMENT, ANY
      AGREEMENTS WE REACH COVERING SUCH MATTERS CONTAINED IN THIS WRITING, WHICH IS THE COMPLETE
      AND EXCLUSIVE
      STATEMENT OF THE AGREEMENT BETWEEN US, EXCEPT AS WE MAY LATER AGREE IN WRITING TO MODIFY IT.
      YOU (AS BORROWER)
      AND WE (AS LENDER) ACKNOWLEDGE AND AGREE THAT THERE ARE NO UNWRITTEN OR ORAL AGREEMENTS BETWEEN
      YOU AND US; (2)
      THAT THIS WRITTEN AGREEMENT IS A FINAL EXPRESSION OF THE AGREEMENT BETWEEN YOU AND US; AND (3)
      THAT THIS
      AGREEMENT MAY NOT BE CONTRADICTED BY EVIDENCE OF PRIOR, CONTEMPORANEOUS, OR SUBSEQUENT ORAL AGREEMENTS
      OF THE
      PARTIES. THE TERMS OF THIS AGREEMENT MAY NOT BE CHANGED EXCEPT IN A WRITING SIGNED BY YOU AND US. NO CHANGE
      SHALL
      RELEASE ANY PARTY FROM LIABILITY UNLESS OTHERWISE EXPRESSLY STATED IN WRITING.
    </span>
  </p>
  <p class='strong-text'>
    ALL OF OUR RIGHTS ARE CUMULATIVE. IF WE APPROVE THIS AGREEMENT, THEN YOU AGREE THAT THIS
    AGREEMENT WILL BE BINDING AND ENFORCEABLE AS TO BOTH PARTIES.
  </p>
  <p>
    <span class='strong-text underlined-text'>
      Maine, Minnesota, Nebraska, Ohio, Oklahoma, and Wyoming
      Residents
    </span>
    <br>
    <span>
      No provision(s) in this Agreement providing for the payment of attorneys' fees,
      court costs or collection costs by you shall apply.
    </span>
  </p>
  <p>
    <span class='strong-text underlined-text'>
      New Jersey Residents
    </span>
    <br>
    <span>
      (1) The section headings of the
      Agreement are a table of contents and not contract terms. (2) You agree
      to pay our reasonable attorney's fees, up
      to 20% of outstanding principal and fees, paid in the collection
      of this Account to an attorney who is not our
      employee. (3) Provisions of this Agreement that refer to acts
      or practices that apply as permitted by, or except
      as prohibited by, applicable law are applicable to New
      Jersey residents only to the extent that such acts or
      practices are permitted by New Jersey or Federal law.
    </span>
  </p>
  <p>
    <span class='strong-text underlined-text'>
      Ohio Residents
    </span>
    <br>
    <span>
      Ohio laws against discrimination
      require that all creditors make credit equally available to all creditworthy
      customers and that credit reporting
      agencies maintain separate credit histories on each individual upon request.
      The Ohio Civil Rights Commission
      administers compliance with this law.
    </span>
  </p>
</div>
<br>
<div class='panel-heading'>
  <li>
    Electronic Signatures
  </li>
</div>
<div class='panel-body'>
  <div class='hidden' data-triggers-hidden='#loan_agreement_repayment_method_ach'
    data-triggers-visible='#loan_agreement_repayment_method_paper_check'>
    <p class='strong-text'>
      By clicking the \"I
      Agree\" button below and providing any additional information as may be requested below,
      you understand and
      acknowledge you are taking the following action:
    </p>
  </div>
  <div data-triggers-hidden='#loan_agreement_repayment_method_paper_check'
    data-triggers-visible='#loan_agreement_repayment_method_ach'>
    <p>
      By clicking the \"I Agree\" button below and
      providing any additional
      information as may be requested below, you understand and acknowledge
      that you are
      taking the following two (2) separate actions:
    </p>

    <hr>
    <p class='strong-text'></p>
    PAYMENT AUTHORIZATION
    <div class='section form-control-consent-checkbox'>
      <label class='control-label'>
        <input class='form-control-consent-checkbox__input hidden' id='bloc_ach_authorization'
          name='bloc[ach_authorization]' required type='checkbox' value='1'>
        <label class='form-control-consent-checkbox__label' for='bloc_ach_authorization'>
          By checking the box to the left
          and clicking the \"I Agree\" button below,
          you are electronically signing the
          Payment Authorization
          and you
          certify that you have fully read and understood the
          <a data-target='#eft-authorization' data-toggle='modal' href='#'>
            ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR CASH ADVANCES AND PAYMENT
            AUTHORIZATION
          </a>
          provisions, you agree to comply with, and be bound by, their terms and you
          agree and
          understand that you are authorizing us to credit your
          Bank Account with the proceeds of your cash advances
          and to debit
          your Bank Account for the amounts owed under the Open-End Line of
          Credit
          Agreement
        </label>
      </label>
    </div>

    <hr>
  </div>
  <p class='strong-text'>
    AGREEMENT TO OPEN-END LINE OF CREDIT AGREEMENT
  </p>
  By entering your name below and
  clicking the \"I Agree\" button below, you are electronically signing this Agreement and acknowledging
  that (a)
  Republic Bank & Trust Company is your lender under the Agreement, (b) you have reviewed and agree to the entire
  <a data-backdrop='static' data-target='#terms-and-conditions' data-toggle='modal' href='#'>
    OPEN-END LINE OF CREDIT
    AGREEMENT
  </a>
  including the
  <a data-target='#account-disclosures' data-toggle='modal' href='#'>
    ACCOUNT OPENING
    DISCLOSURES
  </a>
  ;
  (c) you have reviewed and agree to the
  <a target=\"_blank\" href=\"https://www.netcredit.com/rbt-privacy-policy\">REPUBLIC BANK &amp; TRUST COMPANY PRIVACY
    POLICY</a> and
  <a target=\"_blank\" href=\"https://www.netcredit.com/privacy-policy\">NETCREDIT PRIVACY POLICY</a> and
  <a target=\"_blank\" href=\"https://www.staging.netcredit.com/terms-of-use\">Terms of Use</a>, and
  (d) you acknowledge
  that your right to file suit against us for any claim or dispute regarding this Account is limited by the
  <a data-target='#waiver-provision' data-toggle='modal' href='#'>
    WAIVER OF JURY TRIAL, CLASS ACTION
    WAIVER
  </a>
  and
  <a data-target='#arbitration-provision' data-toggle='modal' href='#'>
    ARBITRATION
  </a>
  provisions, (e) you represent that you are not a debtor under any proceeding in
  bankruptcy and have no intention to
  file a petition for relief under any chapter of the United States Bankruptcy
  Code, (f) that all of the information
  you have submitted in connection with this account is true, accurate, and
  complete, and (g) you acknowledge that
  you have read and understood all the terms of this Agreement, including the
  provisions mentioned above, and you
  agree to comply with, and be bound by, all of those terms.
  <br>
  <br>
  <p>
    YOU
    SHOULD PRINT AND RETAIN A COPY OF THE OPEN-END LINE OF CREDIT AGREEMENT FOR YOUR RECORDS. AN ELECTRONIC COPY
    WILL
    BE MAINTAINED ON THIS WEBSITE IN YOUR PASSWORD PROTECTED CUSTOMER HISTORY.
  </p>
  <p class='strong-text underlined-text'>
    CAUTION - IT IS IMPORTANT THAT YOU THOROUGHLY READ THE CONTRACT BEFORE YOU
    SIGN IT.
  </p>
  <br>
  <br>
  <br>
  <div class='hidden'>
    <div class='form-fieldset'>
      <div class='form-field text-field'>
        <label for='signature_first_name'>First Name</label>
        <input autocorrect='off' class='input-text form-control bloc-form-input first-name' id='signature_first_name'
          name='signature[first_name]' size='30' type='text'>
      </div>
      <div class='form-field text-field'>
        <label for='signature_last_name'>Last Name</label>
        <input autocorrect='off' class='input-text form-control bloc-form-input first-name' id='signature_last_name'
          name='signature[last_name]' size='30' type='text'>
      </div>
    </div>
  </div>
  <div data-triggers-hidden='#loan_agreement_repayment_method_paper_check'
    data-triggers-visible='#loan_agreement_repayment_method_ach'>
    <p>
      Please note that when you click the \"I Agree\"
      button below, we
      authenticate your electronic signature and we also separately authenticate
      your electronic
      signature for the PAYMENT AUTHORIZATION that you separately
      and voluntarily entered above.
    </p>
  </div>

</div>
<div class='modal' id='eft-authorization' style='display:none'>
  <div class='modal-dialog'>
    <div class='modal-content'>
      <div class='mobile-scrollable'>
        <div class='modal-header'>
          <button aria-label='Close' class='close' data-dismiss='modal' type='button'>
            <span aria-hidden='true'>&times;</span>
          </button>
          <h4 class='modal-title'>
            ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR CASH ADVANCES
          </h4>
        </div>
        <div class='modal-body'>
          <div class='scrollpane'>
            <h3>ELECTRONIC FUND TRANSFER AUTHORIZATION</h3>
            <h4>ELECTRONIC
              FUND TRANSFER AUTHORIZATION FOR YOUR CASH ADVANCES</h4>
            <p>
              By signing below, you authorize us, Republic
              Bank & Trust Company, and our successors and assigns,
              to initiate an electronic fund transfer
              (\"
              <strong>EFT</strong>
              \") to credit your bank account ending in
              <span class='last4_account_number_placeholder'>
                6776
              </span>
              with routing number ending in
              <span class='last4_routing_number_placeholder'>
                5629
              </span>
              (the “
              <strong>Bank
                Account</strong>
              ”)
              with the proceeds of any cash advances you request in the amount you are entitled
              to receive. In the
              event that an error is made in scheduling or processing an EFT, you further authorize
              us to initiate an
              EFT to your Bank Account to correct the error.
            </p>
            <h4>PAYMENT AUTHORIZATION</h4>
            <h4>Optional Nature of Authorization</h4>
            <p>
              You are not required to authorize us to initiate EFTs for
              your payments in order to qualify for a
              line of credit. You have the option of arranging to make your
              payments by a method that does not
              involve EFTs. You agree that making your payments by EFT is for your
              convenience and that you
              voluntarily elected this repayment method. By signing this Payment Authorization
              and clicking the
              \"I Agree\" button below, you acknowledge that you are voluntarily choosing to make your
              payments by EFT.
            </p>
            <p>
              <b>
                EFT Authorization for Minimum Payments, Cancellation, and Error
                Correction
              </b>
            </p>
            <p>
              By signing below, you voluntarily authorize us, our servicers/service
              providers, successors and assigns,
              to initiate an EFT through the Automated Clearing House
              (“
              <strong>ACH</strong>
              ”) network (or another network of our choosing) from your Bank Account for the
              following:
            </p>
            <ul>
              <li>
                each of the Minimum Payments due under the Open-End Line of Credit Agreement
                (the “
                <strong>Agreement</strong>
                ”) as shown on your periodic statement (less the amount of any
                partial payment you make or the amount attributed to a cancelled cash advance) on or after
                its due
                date, including any payment due on a non-banking day, which will be processed on the
                following banking
                day;
              </li>
              <li>
                in the event that you rescind a cash advance as prescribed in the Cancelling
                Requested Cash Advances
                provision of the Agreement, the entire cash advance within two (2)
                “
                <strong>Business Days</strong>
                ” (Monday through Friday, excepting Federal holidays) of timely
                cancellation; and
              </li>
              <li>
                the amount required to correct any error in scheduling or processing an
                EFT (or that we otherwise discover)
                on or after the date the error is discovered.
              </li>
            </ul>
            <h4>
              Returned Payments</h4>
            <p>
              If any payment cannot be obtained by EFT, you remain responsible for such
              payment and any resulting fees under
              the Agreement. You agree that we may resubmit any returned EFT as
              permitted by applicable law and network rules.
            </p>
            <h4>Payment Amounts</h4>
            <p>
              Your periodic
              statement will provide the Minimum Payment amount due each billing period.
            </p>
            <h4>Modified Charges
            </h4>
            <p>
              Instead of or in addition to any of the EFTs described above, you may subsequently authorize us
              by phone, fax,
              text message, e-mail, or by logging into the online Account to process one or more EFTs
              through the ACH network
              or another network of our choosing (including, for example, a payment card
              network) for payment. If you, using
              any of the methods of contact described above, (i) specify the
              amount(s) and date(s) of the EFT(s); (ii)
              identify your Bank Account from which the EFT(s) will be
              debited; and (iii) electronically sign an EFT
              authorization, then you authorize us to initiate EFTs from
              your Bank Account in the amounts and on the dates
              that you specify (and, if necessary, to electronically
              correct any erroneous EFTs with debits or credits). All
              EFT transactions that you authorize must comply
              with applicable law and network rules.
            </p>
            <h4>Your Bank Account Information</h4>
            <p>
              You confirm
              that you own the Bank Account, and that it is a legitimate, open and active account. If there is
              any
              missing or erroneous information regarding your Bank Account or the financial institution that holds
              it,
              then you authorize us to verify and correct such information. You have given us certain information
              from your
              Bank Account so that we can verify the account number and routing number. You are responsible
              for updating us
              if there are any changes to your Bank Account or information relating to your Bank
              Account that may affect
              your Account.
            </p>
            <h4>Fees Your Financial Institution May Charge You for
              Rejected Payments</h4>
            <p>
              You understand that the financial institution holding the Bank Account may
              impose additional fees in connection
              with returned or rejected EFTs, and you agree that we do not have
              any liability regarding any such fees.
              <b>
                IN THE EVENT THAT YOU DO NOT HAVE SUFFICIENT FUNDS IN YOUR
                BANK ACCOUNT TO COVER THE AMOUNT OF ANY EFT
                AUTHORIZED HERE, YOU SHOULD CONTACT US AT LEAST THREE (3)
                BUSINESS DAYS IN ADVANCE OF OUR INITIATING THE EFT
                SO THAT WE CAN STOP THE EFT AND ARRANGE FOR AN
                ALTERNATIVE METHOD OF REPAYMENT.
              </b>
            </p>
            <h4>Right to Stop Authorizations and Procedure for Doing
              So</h4>
            <p>
              You may terminate one or all of the authorizations to initiate EFTs from your Bank Account
              by calling us at
              (877) 392-2014 or writing us via fax at (855) 878-1068, e-mailing us at
              support@netcredit.com, or by mail
              at Republic Bank & Trust, c/o NetCredit, 175 W. Jackson Blvd., Suite
              600, Chicago, IL 60604; however, any
              request to cancel your next EFT must be received by us at least
              three (3) Business Days before the EFT is
              scheduled to be processed. In addition, you must specify
              whether you wish to cancel a single EFT or whether
              you wish to cancel all future EFT’s. If you notify us
              that you wish to cancel all future EFT’s by calling us
              instead of writing to us, we may also require you
              to put your request in writing and get it to us within
              fourteen (14) days after your call in order for
              your request to be effective beyond that 14 day period.
            </p>
            <p>
              In the event that you terminate this
              authorization, you will still be responsible for paying all amounts
              you owe us under this Agreement by
              using another permitted payment method. This Payment Authorization will
              remain in full force and effect
              until (i) we have received adequate notiﬁcation from you, as described
              above or (ii) until your Account
              is closed.
            </p>
          </div>
        </div>
      </div>
      <div class='modal-footer'>
        <a class='dismiss-modal cta-btn' data-dismiss='modal'>Dismiss</a>
      </div>
    </div>
  </div>
</div>
<div class='modal' id='payment-authorization' style='display:none'>
  <div class='modal-dialog'>
    <div class='modal-content'>
      <div class='mobile-scrollable'>
        <div class='modal-header'>
          <button aria-label='Close' class='close' data-dismiss='modal' type='button'>
            <span aria-hidden='true'>&times;</span>
          </button>
          <h4 class='modal-title'>
            PAYMENT AUTHORIZATION
          </h4>
        </div>
        <div class='modal-body'>
          <div class='scrollpane'>
            <h3>ELECTRONIC FUND TRANSFER AUTHORIZATION</h3>
            <h4>ELECTRONIC FUND TRANSFER
              AUTHORIZATION FOR YOUR CASH ADVANCES</h4>
            <p>
              By signing below, you authorize us, Republic Bank & Trust
              Company, and our successors and assigns,
              to initiate an electronic fund transfer
              (\"
              <strong>EFT</strong>
              \") to credit your bank account ending in
              <span class='last4_account_number_placeholder'>
              </span>
              with routing number ending in
              <span class='last4_routing_number_placeholder'>
              </span>
              (the “
              <strong>Bank Account</strong>
              ”)
              with the
              proceeds of any cash advances you request in the amount you are entitled to receive. In the
              event that an
              error is made in scheduling or processing an EFT, you further authorize us to initiate an
              EFT to your
              Bank Account to correct the error.
            </p>
            <h4>PAYMENT AUTHORIZATION</h4>
            <h4>Optional Nature of
              Authorization</h4>
            <p>
              You are not required to authorize us to initiate EFTs for your payments in order
              to qualify for a
              line of credit. You have the option of arranging to make your payments by a method that
              does not
              involve EFTs. You agree that making your payments by EFT is for your convenience and that
              you
              voluntarily elected this repayment method. By signing this Payment Authorization and clicking
              the
              \"I Agree\" button below, you acknowledge that you are voluntarily choosing to make your payments by
              EFT.
            </p>
            <p>
              <b>
                EFT Authorization for Minimum Payments, Cancellation, and Error Correction
              </b>
            </p>
            <p>
              By signing below, you voluntarily authorize us, our servicers/service providers, successors and
              assigns,
              to initiate an EFT through the Automated Clearing House (“
              <strong>ACH</strong>
              ”) network (or
              another network of our choosing) from your Bank Account for the following:
            </p>
            <ul>
              <li>
                each of the
                Minimum Payments due under the Open-End Line of Credit Agreement (the “
                <strong>Agreement</strong>
                ”)
                as shown on your periodic statement (less the amount of any partial payment you make or the amount
                attributed to a cancelled cash advance) on or after
                its due date, including any payment due on a
                non-banking day, which will be processed on the
                following banking day;
              </li>
              <li>
                in the event that
                you rescind a cash advance as prescribed in the Cancelling Requested Cash Advances
                provision of the
                Agreement, the entire cash advance within two (2) “
                <strong>Business Days</strong>
                ” (Monday through
                Friday, excepting Federal holidays) of timely cancellation; and
              </li>
              <li>
                the amount required to
                correct any error in scheduling or processing an EFT (or that we otherwise discover)
                on or after the
                date the error is discovered.
              </li>
            </ul>
            <h4>Returned Payments</h4>
            <p>
              If any payment cannot be
              obtained by EFT, you remain responsible for such payment and any resulting fees under
              the Agreement. You
              agree that we may resubmit any returned EFT as permitted by applicable law and network rules.
            </p>
            <h4>
              Payment Amounts</h4>
            <p>
              Your periodic statement will provide the Minimum Payment amount due each
              billing period.
            </p>
            <h4>Modified Charges</h4>
            <p>
              Instead of or in addition to any of the EFTs
              described above, you may subsequently authorize us by phone, fax,
              text message, e-mail, or by logging
              into the online Account to process one or more EFTs through the ACH network
              or another network of our
              choosing (including, for example, a payment card network) for payment. If you, using
              any of the methods
              of contact described above, (i) specify the amount(s) and date(s) of the EFT(s); (ii)
              identify your Bank
              Account from which the EFT(s) will be debited; and (iii) electronically sign an EFT
              authorization, then
              you authorize us to initiate EFTs from your Bank Account in the amounts and on the dates
              that you specify
              (and, if necessary, to electronically correct any erroneous EFTs with debits or credits). All
              EFT
              transactions that you authorize must comply with applicable law and network rules.
            </p>
            <h4>Your Bank
              Account Information</h4>
            <p>
              You confirm that you own the Bank Account, and that it is a legitimate,
              open and active account. If there is
              any missing or erroneous information regarding your Bank Account or
              the financial institution that holds it,
              then you authorize us to verify and correct such information.
              You have given us certain information from your
              Bank Account so that we can verify the account number and
              routing number. You are responsible for updating us
              if there are any changes to your Bank Account or
              information relating to your Bank Account that may affect
              your Account.
            </p>
            <h4>Fees Your Financial
              Institution May Charge You for Rejected Payments</h4>
            <p>
              You understand that the financial institution
              holding the Bank Account may impose additional fees in connection
              with returned or rejected EFTs, and you
              agree that we do not have any liability regarding any such fees.
              <b>
                IN THE EVENT THAT YOU DO NOT HAVE
                SUFFICIENT FUNDS IN YOUR BANK ACCOUNT TO COVER THE AMOUNT OF ANY EFT
                AUTHORIZED HERE, YOU SHOULD
                CONTACT US AT LEAST THREE (3) BUSINESS DAYS IN ADVANCE OF OUR INITIATING THE EFT
                SO THAT WE CAN STOP
                THE EFT AND ARRANGE FOR AN ALTERNATIVE METHOD OF REPAYMENT.
              </b>
            </p>
            <h4>Right to Stop
              Authorizations and Procedure for Doing So</h4>
            <p>
              You may terminate one or all of the authorizations to
              initiate EFTs from your Bank Account by calling us at
              (877) 392-2014 or writing us via fax at (855)
              878-1068, e-mailing us at support@netcredit.com, or by mail
              at Republic Bank & Trust, c/o NetCredit, 175
              W. Jackson Blvd., Suite 600, Chicago, IL 60604; however, any
              request to cancel your next EFT must be
              received by us at least three (3) Business Days before the EFT is
              scheduled to be processed. In addition,
              you must specify whether you wish to cancel a single EFT or whether
              you wish to cancel all future EFT’s.
              If you notify us that you wish to cancel all future EFT’s by calling us
              instead of writing to us, we may
              also require you to put your request in writing and get it to us within
              fourteen (14) days after your
              call in order for your request to be effective beyond that 14 day period.
            </p>
            <p>
              In the event that
              you terminate this authorization, you will still be responsible for paying all amounts
              you owe us under
              this Agreement by using another permitted payment method. This Payment Authorization will
              remain in full
              force and effect until (i) we have received adequate notiﬁcation from you, as described
              above or (ii)
              until your Account is closed.
            </p>
          </div>
        </div>
      </div>
      <div class='modal-footer'>
        <a class='dismiss-modal cta-btn' data-dismiss='modal'>Dismiss</a>
      </div>
    </div>
  </div>
</div>

<br>
<p>
  By clicking \"I Agree\" below, you understand and agree that we will obtain your credit report, which may impact
  your credit score.
</p>
<button class='cta-btn hidden' type='submit'>
  I Agree
</button>
`;
