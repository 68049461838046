import React from 'react';

import {
  Container,
  LoadingSpinner,
  LoadingSpinnerPropsT,
} from '@consumer-frontend/shared-ui';

import '@consumer-bootstrap/netcredit.scss';
// eslint-disable-next-line import/order
import '@consumer-bootstrap/brands/netcredit/loading-spinner.scss';

export type NcLoadingSpinnerT = React.FC<LoadingSpinnerPropsT & { className?: string; }>;

const defaultClasses = [
  'align-items-center',
  'd-flex',
  'flex-column',
  'justify-content-center',
  'm-0',
  'p-0',
].join(' ');

export const NcLoadingSpinner: NcLoadingSpinnerT = ({ className, ...props }) => {
  return (
    <Container className={`nc-loading-spinner ${className || ''} ${defaultClasses}`}>
      <div className="">
        <LoadingSpinner {...props} />
      </div>
    </Container>
  );
};
