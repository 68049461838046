import { AxiosError } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { apiEndpoints } from '../lib';
import { SimplifiedAccountInfoT } from '../types';

export const useAccountInfo = (accountId: string) => {
  const {
    data,
    error,
    isLoading,
  } = useSWRImmutable<SimplifiedAccountInfoT, AxiosError>(
    apiEndpoints.accountSummary(accountId),
  );

  return {
    accountInfo: data,
    error,
    isLoading,
  };
};
