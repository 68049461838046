import React from 'react';

import { ListGroup as BootstrapListGroup } from 'react-bootstrap';

export type ListGroupPropsT = {
  children: React.ReactNode;
  as?: React.ElementType;
  className?: string;
  horizontal?: boolean;
  id?: string;
  numbered?: boolean;
  variant?: 'flush';
};
export type ListGroupT = React.FC<ListGroupPropsT>;

export const ListGroup: ListGroupT = ({ children, ...props }) => (
  <BootstrapListGroup {...props} as="ul">
    {children}
  </BootstrapListGroup>
);
