import { useContext } from 'react';

import {
  OptimizelyContext, OptimizelyDecision,
} from '@optimizely/react-sdk';

const useOptimizelyFeature = (featureKey: string): OptimizelyDecision => {
  const { optimizely } = useContext(OptimizelyContext);

  if (!optimizely) {
    if (import.meta.env.VITE_ENV !== 'development') {
      console.error(`Error fetching Optimizely decision for feature: ${featureKey}`);
    }

    return { enabled: import.meta.env.VITE_MOCKING } as OptimizelyDecision;
  }

  return optimizely.decide(featureKey);
};

export { useOptimizelyFeature };
