import React from 'react';

import { Navbar as BootstrapNavbar } from 'react-bootstrap';

type NavbarPropsT = {
  children?: React.ReactNode;
  className?: string;
  expand?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  expanded?: boolean;
  fixed?: 'top' | 'bottom';
  onToggle?: (expanded: boolean) => void;
  variant?: 'light' | 'dark' | string;
};
export type NavBarT = React.FC<NavbarPropsT>;

export const Navbar: NavBarT = ({
  children,
  ...props
}: NavbarPropsT) => (
  <BootstrapNavbar {...props}>
    {children}
  </BootstrapNavbar>
);
