import { atom } from 'jotai';

import { cookieJar } from '@consumer-frontend/nc-api';
import { PageValuesT } from '@consumer-frontend/shared-ui';

import { initialModalConfig, ModalConfigT } from './constants';

export const accountIdAtom = atom(() => {
  const [accountId, error] = cookieJar.getAccountId();

  if (!accountId || error) {
    console.warn('Error getting account ID from cookie jar:', error, { accountId });

    return '';
  }

  return accountId;
});

export const messageCenterPaginationAtom = atom<PageValuesT>({
  limit: 10,
  offset: 0,
  total: 0,
});

export const messageDetailPaginationAtom = atom<PageValuesT>({
  limit: 1,
  offset: 0,
  total: 0,
});

export const showMobileNavAtom = atom(false);

export const modalConfigAtom = atom<ModalConfigT>(initialModalConfig);
