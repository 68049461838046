import { AxiosError } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { apiEndpoints } from '../lib';
import { AdobeDataLayerT } from '../types';

export const useAdobeDataLayer = (accountId: string) => {
  const {
    data,
  } = useSWRImmutable<AdobeDataLayerT, AxiosError>(
    apiEndpoints.adobeDataLayer(accountId),
  );

  return {
    adobeDataLayer: data,
  };
};
