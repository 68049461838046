import React from 'react';

import { useAtom } from 'jotai';

import { uiRoutes } from '@consumer-frontend/nc-api';
import {
  Container,
  Icon,
  Image,
  Link,
  Navbar,
  NavbarOffcanvas,
  NavbarToggle,
} from '@consumer-frontend/shared-ui';

import { showMobileNavAtom } from '../../store';
import { NcNav } from '../NcNav';

import '@consumer-bootstrap/brands/netcredit/header.scss';
import '@consumer-bootstrap/netcredit.scss';

export const NcHeader = () => {
  const LOGO_URI = '/assets/images/NC_color_TV_tagline.svg';
  const [showMobileNav, setShowMobileNav] = useAtom(showMobileNavAtom);

  return (
    <>
      {/* Desktop header */}
      <Container
        hasBoxShadow
        className={[
          'd-none',
          'd-md-flex',
          'w-100',
          'header',
        ].join(' ')}
      >
        <Link to={uiRoutes.accountSummary}>
          <Image
            className="logo-img"
            alt="NetCredit Logo"
            src={LOGO_URI}
          />
        </Link>
      </Container>

      {/* Mobile header */}
      <Navbar className="p-0" expand={false}>
        <Container
          hasBoxShadow
          className={[
            'd-flex',
            'd-md-none',
            'p-0',
            'w-100',
            'header',
          ].join(' ')}
        >
          <NavbarToggle
            aria-controls="offcanvas"
            data-testid="offcanvas-toggle"
            className={['border-0', 'hamburger'].join(' ')}
            onClick={() => setShowMobileNav(true)}
          >
            <Icon className={['d-flex', 'toggle-icon'].join(' ')} iconName="list" />
          </NavbarToggle>
          <NavbarOffcanvas
            aria-labelledby="offcanvas"
            id="offcanvas"
            show={showMobileNav}
            onKeyDown={(e) => e.key === 'Escape' && setShowMobileNav(false)}
          >
            <Container className="mobile-nav">
              <NavbarToggle
                aria-controls="offcanvas"
                data-testid="in-content-offcanvas-toggle"
                className={['border-0', 'close-icon'].join(' ')}
                onClick={() => setShowMobileNav(false)}
              >
                <Icon className={['d-flex', 'toggle-icon'].join(' ')} iconName="x-lg" />
              </NavbarToggle>
              <NcNav responsiveFlexClass="d-flex" />
            </Container>
          </NavbarOffcanvas>
          <Link to={uiRoutes.accountSummary}>
            <Image className="logo-img" alt="NetCredit Mobile Logo" src={LOGO_URI} />
          </Link>
        </Container>
      </Navbar>
    </>
  );
};
