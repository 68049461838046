import React, {
  cloneElement,
  ReactElement,
  useCallback,
} from 'react';

import { useAtom } from 'jotai';

import { modalConfigAtom } from '../../store';

type NcModalAnchorPropsT = {
  body: string | JSX.Element;
  children: ReactElement;
  primaryText: string;
  title: string;
  modalOnClick?: () => void;
  secondaryText?: string;
};

export const NcModalAnchor: React.FC<NcModalAnchorPropsT> = ({
  children,
  ...modalConfigProps
}) => {
  const [, setModalConfig] = useAtom(modalConfigAtom);

  const openModal = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();

      setModalConfig({
        ...modalConfigProps,
        show: true,
      });
    },
    [modalConfigProps, setModalConfig],
  );

  return cloneElement(children, { onClick: openModal });
};
