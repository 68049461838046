import { uiRoutes } from '@consumer-frontend/nc-api';
import { Link } from '@consumer-frontend/shared-ui';

import '@consumer-bootstrap/brands/netcredit/footer.scss';
import '@consumer-bootstrap/netcredit.scss';

export const NcDisclaimerText = () => {
  return (
    <div className="disclaimers">
      <p>
        Approved applications are typically funded the next business day. Applicants who submit an application before 11:00
        a.m. CT Monday – Friday may receive their funds the same business day. The exact timing as to when your loan funds
        will be available will be determined by your banking institution. Approval subject to additional verification.
        Failure to provide additional documentation promptly may delay funding.
      </p>

      <p>
        All NetCredit loans and lines of credit are offered
        &#32;
        <Link onDarkBg to={uiRoutes.lendingPartners}>
          by a member of the NetCredit family of companies or a lending partner bank.
        </Link>
        &#32;
        All loans and lines of credit will be serviced by NetCredit.
      </p>

      <p>
        In states where NetCredit loans and lines of credit are offered by a lending partner bank, loans and lines of credit
        are underwritten by, approved by and funded by Capital Community Bank, Republic Bank &amp; Trust Company or
        Transportation Alliance Bank, Inc. d/b/a TAB Bank. Refer to the
        &#32;
        <Link onDarkBg to={uiRoutes.ratesAndTerms}>Rates &amp; Terms page</Link>
        &#32;
        for your state for more information.
      </p>

      <p>
        This website may utilize session replay technologies to collect, record and track data about how you interact with
        our website. By signing in or logging on to the netcredit.com website, you acknowledge and agree for NetCredit to
        utilize session replay technologies. Please visit our
        &#32;
        <Link onDarkBg to={uiRoutes.termsOfUse}>Terms of Use</Link>
        &#32;
        to learn more about how we utilize session replay technology to monitor and record interactions with our website.
      </p>

      <p>
        CA residents: NC FINANCIAL SOLUTIONS OF CALIFORNIA, LLC d/b/a NetCredit is licensed by the Department of Financial
        Protection and Innovation. Loans made pursuant to a California Financing Law license (License No. 603K020).
      </p>

      <p className="copyright">
        &copy; {new Date().getFullYear()} NetCredit
      </p>
    </div>
  );
};
