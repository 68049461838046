import { useEffect } from 'react';

import * as bootstrap from 'bootstrap';
import DOMPurify from 'dompurify';

import { ProductTypeT } from '@consumer-frontend/nc-api';

const fixInstallmentLinks = () => {
  const linkIds = [
    '#payment_authorization',
    '#authorization_agreement',
    '#truth_in_lending',
    '#waiver_of_jury_trial',
    '#arbitration_provision',
    '#electronic_fund_transfer',
    '#itemization_of_amount_financed',
    '#cancellation',
    '#default_and_acceleration',
    '#application_of_payments',
    '#class_action_waiver',
  ];

  const selector = linkIds.map((linkId) => `a[href="${linkId}"]`).join(', ');
  const links = document.querySelectorAll(selector);

  links.forEach((link) => {
    link.setAttribute('href', `/contract${link.getAttribute('href')}`);
  });
};

const fixLOCLinks = () => {
  const linksIds = [
    '#terms-and-conditions',
    '#account-disclosures',
    '#waiver-provision',
    '#arbitration-provision',
  ];

  const selector = linksIds.map((linkId) => `a[data-target="${linkId}"]`).join(', ');
  const links = document.querySelectorAll(selector);

  links.forEach((link) => {
    link.removeAttribute('href');
    link.removeAttribute('data-target');
    link.removeAttribute('data-toggle');
  });
};

const fixEFTModal = () => {
  const eft = '#eft-authorization';
  const eftLink = document.querySelector(`a[data-target="${eft}"]`);

  if (!eftLink) {
    return;
  }

  const eftModal = bootstrap.Modal.getOrCreateInstance(eft);
  const eftModalClose = document.querySelector('button[data-dismiss="modal"]');
  const eftModalDismiss = document.querySelector('a[data-dismiss="modal"]');

  eftLink.removeAttribute('data-target');
  eftLink.removeAttribute('data-toggle');
  eftLink.addEventListener('click', (event) => {
    event.preventDefault();
    eftModal.show();
  });

  if (eftModalClose) {
    eftModalClose.addEventListener('click', () => {
      eftModal.hide();
    });
  }

  if (eftModalDismiss) {
    eftModalDismiss.addEventListener('click', () => {
      eftModal.hide();
    });
  }
};

export const useParseContract = (body: string, productType: ProductTypeT) => {
  const safeHTML = DOMPurify.sanitize(body);

  useEffect(() => {
    if (productType === 'Line of Credit') {
      fixLOCLinks();
      fixEFTModal();
    } else {
      fixInstallmentLinks();
    }
  }, [productType]);

  return safeHTML;
};
