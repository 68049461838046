import { StrictMode } from 'react';

import * as ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';

import { swrConfig, worker } from '@consumer-frontend/nc-api';

import { App } from './app/App';
import './utils/datadog';
import { NcOptimizelyProvider } from './utils/optimizely';

async function enableMocking() {
  if (
    import.meta.env.VITE_ENV === 'development'
    && import.meta.env.VITE_MOCKING === 'true'
  ) {
    await worker.start({
      // This disables reporting of unhandled requests. Cf: https://github.com/mswjs/msw/discussions/1589
      onUnhandledRequest: 'bypass',
    });
  }
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <StrictMode>
      <SWRConfig value={swrConfig}>
        <NcOptimizelyProvider>
          <App />
        </NcOptimizelyProvider>
      </SWRConfig>
    </StrictMode>,
  );
});
