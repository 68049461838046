import React from 'react';

import {
  isLoanTypeLoc,
  isOpenLoan,
  SimplifiedAccountInfoT,
  useOptimizelyFeature,
} from '@consumer-frontend/nc-api';

import { InstallmentNav } from './InstallmentNav';
import { LocNav } from './LocNav';

export type NavSelectionPropsT = {
  accountInfo?: SimplifiedAccountInfoT;
};

export type NavSelectionT = React.FC<NavSelectionPropsT>;

export const NavSelection: NavSelectionT = ({ accountInfo }) => {
  const showReactContract = useOptimizelyFeature('account_home_frontend_contracts');
  const showInstallmentContract = showReactContract.enabled && isOpenLoan(accountInfo?.open_loan_type);

  if (accountInfo && isLoanTypeLoc(accountInfo.open_loan_type) && accountInfo.open_loan_number) {
    return <LocNav
      openLoanNumber={accountInfo.open_loan_number}
      showContract={showReactContract.enabled}
      showFeeSaverStatus={accountInfo.show_fee_saver_status}
    />;
  }

  return <InstallmentNav showContract={showInstallmentContract} />;
};
