import { http, HttpResponse } from 'msw';

import { apiEndpoints } from '../../lib';

export const loanHandlers = {
  default: http.get(apiEndpoints.loanSummary(':accountId', ':loanNumber'), () => HttpResponse.json({
    has_draw_fee_saver: true,
    has_statement_balance_fee_saver: false,
  }, { status: 200 })),
  error: http.get(apiEndpoints.loanSummary(':accountId', ':loanNumber'), () => new Promise(
    (_, reject) => { setTimeout(() => reject(HttpResponse.error()), 1000); },
  )),
  installment: http.get(apiEndpoints.loanSummary(':accountId', ':loanNumber'), () => HttpResponse.json(
    {},
    { status: 200 },
  )),
  loading: http.get(apiEndpoints.loanSummary(':accountId', ':loanNumber'), () => new Promise(
    (resolve) => { setTimeout(() => resolve(HttpResponse.json([], { status: 200 })), 30000); },
  )),
  locNotFeeSaver: http.get(apiEndpoints.loanSummary(':accountId', ':loanNumber'), () => HttpResponse.json({
    has_draw_fee_saver: false,
    has_statement_balance_fee_saver: false,
  }, { status: 200 })),
};
