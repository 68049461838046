import React from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';

export type LinkTargetT = '_blank' | '_self' | '_parent' | '_top';

type LinkPropsT = {
  to: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  onDarkBg?: boolean;
  target?: LinkTargetT;
};
export type LinkT = React.FC<LinkPropsT>;

export const Link: LinkT = ({
  children,
  className,
  onDarkBg,
  ...props
}: LinkPropsT) => {
  const cssClasses = `${onDarkBg ? 'on-dark-bg ' : ''}${className || ''}`;

  return (
    <ReactRouterLink {...props} className={cssClasses}>
      {children}
    </ReactRouterLink>
  );
};
