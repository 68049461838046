import Analytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';

type AnalyticsWindowT = {
  analytics: AnalyticsWindowT;
  analyticsDataLayer: Record<string, unknown>;
  s: unknown;
} & Window;

type ConfigT = { enabled: boolean; };

type PayloadT = {
  event: string;
  properties: Record<string, unknown>;
  traits: Record<string, unknown>;
};

type EventCallbackT = {
  instance: AnalyticsInstance;
  payload: PayloadT;
};

declare let window: AnalyticsWindowT;

const adobeLaunchURL: string = import.meta.env.VITE_ADOBE_LAUNCH_URL || '';

const isDeployed = import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging';

function buildAdobePlugin(config: ConfigT): AnalyticsPlugin {
  const enabled = config.enabled && !!adobeLaunchURL;

  if (config.enabled && !adobeLaunchURL) {
    console.warn('Adobe not configured');
  }

  return {
    config: { enabled },
    identify: <T extends EventCallbackT>({ payload }: T) => {
      if (payload.traits) {
        window.analyticsDataLayer = payload.traits;
      } else if (!window.analyticsDataLayer) {
        window.analyticsDataLayer = {};
      }
    },
    initialize: () => {
      const adobeLaunch = document.createElement('script');

      adobeLaunch.setAttribute('src', adobeLaunchURL);
      document.head.prepend(adobeLaunch);
    },
    loaded: () => !!window.s,
    name: 'adobe',
    track: <T extends EventCallbackT>({ payload, instance }: T) => {
      window.analyticsDataLayer = instance.user().traits;
      document.body.dispatchEvent(
        new CustomEvent(payload.event, {
          bubbles: true,
          composed: true,
          detail: payload.properties,
        }),
      );
    },
  };
}

export const analytics = Analytics({
  app: 'account-home-frontend',
  debug: !isDeployed,
  plugins: [buildAdobePlugin({ enabled: isDeployed })],
}) as AnalyticsInstance;

window.analytics = window;
