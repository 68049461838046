import { Button, ButtonPropsT } from '@consumer-frontend/shared-ui';

import '@consumer-bootstrap/brands/netcredit/buttons.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcButtonT = React.FC<ButtonPropsT>;

export const NcButton: NcButtonT = ({
  children,
  className,
  ...props
}) => (
  <Button className={`nc-button ${className}`} {...props}>
    {children}
  </Button>
);
