import React, { useEffect, useRef } from 'react';

export type NcMessageDetailIframePropsT = {
  html: string;
};
export type NcMessageDetailIframeT = React.FC<NcMessageDetailIframePropsT>;

export const NcMessageDetailIframe: NcMessageDetailIframeT = ({ html }) => {
  // This iframe auto-size hack is based on https://stackoverflow.com/a/67219041/3251463, JFYI.
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState<number>(0);

  const onLoadHandler = () => {
    setHeight((iframeRef.current?.contentWindow?.document?.body?.scrollHeight || 0) + 16);
  };

  useEffect(onLoadHandler, []);

  return <iframe
    height={`${height}px`}
    onLoad={onLoadHandler}
    ref={iframeRef}
    srcDoc={html}
    title="messageDetail"
    data-chromatic="ignore"
  />;
};
