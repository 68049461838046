import {
  useEffect, useRef,
} from 'react';

export const useTrapFocus = (show: boolean) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      const modalElement = modalRef.current;

      if (!modalElement) {
        return undefined;
      }

      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

      const handleTabKeyPress = (event: KeyboardEvent): void => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey
            && document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }

        return undefined;
      };

      modalElement.addEventListener('keydown', handleTabKeyPress);

      return () => {
        modalElement.removeEventListener('keydown', handleTabKeyPress);
      };
    }

    return undefined;
  }, [modalRef, show]);

  return { modalWrapper: modalRef };
};
