import { accountSummaryHandlers } from './accountSummaryHandlers';
import { contractHandlers } from './contractHandlers';

export const searchProduct = () => {
  const productType = new URLSearchParams(window.location.search).get('productType');

  switch (productType) {
    case 'lineOfCredit':
      return [accountSummaryHandlers.lineOfCredit];

    case 'installment':
      return [accountSummaryHandlers.installment];

    default:
      return [];
  }
};

export const searchContract = () => {
  const contract = new URLSearchParams(window.location.search).get('contract');

  switch (contract) {
    case 'lineOfCredit':
      return [contractHandlers.lineOfCredit];

    case 'empty':
      return [contractHandlers.empty];

    case 'error':
      return [contractHandlers.error];

    case 'loading':
      return [contractHandlers.loading];

    case 'noBrandSignature':
      return [contractHandlers.noBrandSignature];

    default:
      return [];
  }
};
