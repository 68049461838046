import React from 'react';

import { NcPagination } from './NcPagination';
import { messageDetailPaginationAtom } from '../../store';

export const MessageDetailPagination = () => {
  return (
    <NcPagination atom={messageDetailPaginationAtom} hideLabel />
  );
};
