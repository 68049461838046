import React, { useCallback } from 'react';

import { useAtom } from 'jotai';

import { MessageT } from '@consumer-frontend/nc-api';
import { ListGroup, ListGroupItem } from '@consumer-frontend/shared-ui';

import { messageDetailPaginationAtom } from '../../store';
import { NcMessageCenterLink } from '../NcMessageCenterLink';

import '@consumer-bootstrap/brands/netcredit/message-center.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcMessageCenterListGroupPropsT = {
  messages: MessageT[];
};
export type NcMessageCenterListGroupT = React.FC<NcMessageCenterListGroupPropsT>;

export const NcMessageCenterListGroup: NcMessageCenterListGroupT = ({ messages }) => {
  // TEMP PAGINATION HACK: revert once total count is fixed.
  // const [_, setMessageDetailPaginationValues] = useAtom(messageDetailPaginationAtom);
  const [{ offset }, setMessageDetailPaginationValues] = useAtom(messageDetailPaginationAtom);

  const handleClick = useCallback(
    (listOffset: number) => {
      setMessageDetailPaginationValues((values) => {
        return { ...values, offset: listOffset + values.offset };
      });
    },
    [setMessageDetailPaginationValues],
  );

  return (
    <ListGroup className="nc-message-center-list">
      {/* TEMP PAGINATION HACK: revert once total count is fixed. */}
      {/* {!messages?.length && <ListGroupItem>No messages yet.</ListGroupItem>} */}
      {!messages?.length && (
        <ListGroupItem>
          {offset > 0 ? 'End of messages.' : 'No messages yet.'}
        </ListGroupItem>
      )}

      {!!messages?.length && messages.map((message, idx) => (
        <NcMessageCenterLink
          key={message.id}
          messageDate={message.sent_at}
          messageTitle={message.subject}
          onClick={() => handleClick(idx)}
        />
      ))}
    </ListGroup>
  );
};
