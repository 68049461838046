import { http, HttpResponse } from 'msw';

import { apiEndpoints } from '../../lib';

export const locHandlers = {
  accountSummary: http.get(apiEndpoints.accountSummary(':accountId'), () => HttpResponse.json(
    {
      account_id: 12345,
      first_name: 'John',
      last_name: 'Doe',
      login: 'johndoe@gmail.com',
      open_loan_number: 'LOAN1234',
      open_loan_type: 'netcredit_line_of_credit',
      show_fee_saver_status: true,
    },
    { status: 200 },
  )),
};
