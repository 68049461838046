export const installmentDirectWIAchWithoutAccount = `<html><head></head><body><div class="loan-contract installment-contract">

  <div class="article loan-agreement">

    <h2 class="contract-title">WISCONSIN CONSUMER INSTALLMENT LOAN AGREEMENT</h2>

    <div class="section summary table-responsive">
      <div class="entities">
            </div><table class="finance-charge table table-bordered">
        <tbody><tr>
          <td>
            <dl class="projected-disbursement-date"><dt>Projected Disbursement Date:</dt> <dd>Nov 19, 2024</dd></dl>
          </td>
          <td>
            <dl class="account-num"><dt>Loan #:</dt> <dd>2024WI546941660</dd></dl>
            <dl class="account-num"><dt>Account ID:</dt>52148005</dl>
          </td>
        </tr>

        <tr>
          <td>
              <div class="entity lender">
                <h3 class="party">Creditor:</h3>
                <div class="location">
                  <div class="company">NC Financial Solutions of Wisconsin, LLC, d/b/a NetCredit</div>
                  <address>
                    <span class="address-1">175 W. Jackson Blvd., </span><br>
                    
                      <span class="address-2">Suite 600 </span><br>
                    
                    <span class="city">Chicago, </span>
                    <span class="state">IL </span>
                    <span class="zip">60604 </span><br>
                  </address>
                  <div class="phone home">877-392-2014 </div>
                </div>
              </div>
            </td>

            <td>
              <div class="entity borrower">
                <h3 class="party">Borrower:</h3>
                <div class="location">
                  <div class="person">Johanna Tromp  </div>
                  <address>
                    <span class="address-1">leads_decision: staging_approved post_contract_mock: staging_approved ebs_uw_required:0, </span><br>
                    
                      <span class="address-2">1 </span><br>
                    
                    <span class="city">Bentonville, </span>
                    <span class="state">WI </span>
                    <span class="zip">24635 </span><br>
                  </address>
                  <div class="phone home">074-276-8921 </div>
                  <div class="phone work">880-747-3855 </div>
                </div>
              </div>
            </td>
          
        </tr>
      </tbody></table>
    </div>

    <div class="section terms">
      <p>
        This Consumer Installment Loan Agreement (hereinafter the “Agreement”) includes the
        disclosures above, the Federal Truth in Lending Act Disclosures immediately below (“TILA Disclosures”)
        and the Additional Terms.
      </p>
      <p>
        In this Agreement, the words "you," "your" and “I” mean the borrower who has electronically signed it and the words "Licensee," "we," "us" and "our" mean&nbsp;<strong>NC Financial Solutions of Wisconsin,
        LLC</strong>&nbsp;d/b/a NetCredit ("Lender"), licensed by the Wisconsin Department of Financial Institutions ("DFI"),&nbsp;201 W.
        Washington Ave, Suite 500, P.O. Box 8041, Madison, WI&nbsp;53708-8041 (collectively, the parties). You acknowledge that this
        loan is made for personal, family or household purposes.
      </p>
      <p><strong>This Agreement is made pursuant to Section 138.09 of the Wisconsin Statutes.</strong></p>
      <p>
        Before we disburse Loan funds, we will seek to verify the information we have collected in connection with your request for a Loan.
        Such verification may require you to provide additional information to us. You agree that, in the event that we are unable to complete
        the verification to our satisfaction, we are not obligated to disburse Loan funds. If we do not disburse Loan funds, we will notify
        you at the e-mail address you provided on your application.
      </p>
      <p>
        Certain of the TILA Disclosures are based on our expectation that Loan funds will be disbursed to you on the Projected Disbursement Date
        set forth above. Banking delays or holidays, or delays in making contact with you to verify the information you have provided to us, may
        cause the Loan funds to be disbursed later. We will begin accruing interest on the date the Loan funds are actually disbursed by us.
      </p>
      <p>
        <strong>THIS AGREEMENT INCLUDES AN ARBITRATION PROVISION. UNLESS YOU PROPERLY REJECT THE ARBITRATION PROVISION OR ARE A MEMBER OF THE ARMED
        FORCES OR A DEPENDENT OF SUCH A MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT, THE ARBITRATION PROVISION WILL HAVE A SUBSTANTIAL EFFECT
        ON YOUR RIGHTS IN THE EVENT OF A DISPUTE WITH US. FOR EXAMPLE, IF WE ELECT TO REQUIRE YOU TO ARBITRATE ANY CLAIM, YOU WILL NOT HAVE THE RIGHT
        TO A JURY TRIAL OR THE RIGHT TO PARTICIPATE IN A CLASS ACTION IN COURT OR IN ARBITRATION.</strong>
      </p>
      <br>
    </div>

    <div class="section truth-in-lending panel panel-default" id="truth_in_lending">
     <h3>FEDERAL TRUTH IN LENDING ACT DISCLOSURES</h3>
     <div class="table-responsive">
       <table class="finance-charge table table-bordered">
         <tbody><tr>
           <th class="important">
             ANNUAL PERCENTAGE RATE
             <span>The cost of your credit as a yearly rate.</span>
           </th>
           <th class="important">
             FINANCE CHARGE
             <span>The dollar amount the credit will cost you.</span>
           </th>
           <th>
             Amount Financed
             <span>The amount of credit provided to you or on your behalf.</span>
           </th>
           <th>
             Total of Payments
             <span>The amount you will have paid after you have made all payments as scheduled</span>
           </th>
         </tr>
         <tr>
           <td class="cost-of-credit"><strong>35.48% (e)</strong></td>
           <td class="cost-of-credit"><strong>$2,587.92 (e)</strong></td>
           <td>$4,000.00</td>
           <td>$6,587.92 (e)</td>
         </tr>
       </tbody></table>
     </div>

     <h3>Payment Schedule (e):</h3>
       <p>Your payment schedule will be 78 payments due every two weeks on Fridays,       beginning Friday, Dec 13, 2024       and ending Friday, Nov 26, 2027, except that if any of these days is not a Business Day,       your payment will be due on the preceding Business Day.       Each payment will be in the amount of $84.47, except for your final payment which will be in the amount of $83.73.</p>
     
     <div class="clause federal-disclosure" id="fed_disclosure">
      
        <p><strong>Security: </strong>You are giving us a security interest in the Payment Authorization.</p>
      
       <p><strong>Late Charge: </strong>If you fail to make all or any part of a scheduled installment payment within 10 days of its scheduled installment due date, you may be charged a late charge of the lesser of $10.00 or 5% of the unpaid amount of the scheduled installment payment.</p>
       <p>
        <strong>Prepayment: </strong>
        If you prepay in full, you will not be entitled to a refund of the prepaid finance charge, if any.
        If you prepay in part, you will not be entitled to a refund of the prepaid finance charge, if any.
        If you prepay in full or in part, you will not have to pay a penalty.
       </p>
       <p><strong>Contract Reference: </strong>See the Additional Terms for any additional information about nonpayment, default, our right to accelerate maturity of this Agreement and prepayment rebates and penalties.</p>
       <p>(e) means estimate.</p>
       <br>
     </div>
    </div>

    <div class="amount-itemization">
      <h3>ITEMIZATION OF AMOUNT FINANCED</h3>
      <div class="item">
        <ol>
          <li>
            <span class="value">Total amount advanced (Principal Amount)</span>
            <span class="value">$4,000.00</span>
          </li>
          <li>
            <span class="value">Prepaid finance charge (Origination Fee)</span>
            <span class="value">$0.00</span>
          </li>
          
          <li>
            <span class="value">Amount given to you directly (1-2=3)</span>
            <span class="value">$270.00</span>
          </li>
        </ol>
      </div>
      <br>
    </div>
    <h3 style="text-align: center; text-decoration: underline;">ADDITIONAL TERMS</h3>

    <h3>PROMISE TO PAY, LATE CHARGE, AND CALCULATION OF INTEREST.</h3>
      <p>
        You promise to pay us <strong>$4,000.00</strong>
        (the amount of the principal) plus interest from the date the Loan Funds are actually disbursed to you until
        the final installment due date set forth in the payment schedule above (“Payment Schedule”),
        or any adjusted payment schedule subsequently agreed to by you and us and confirmed by
        us in writing (“Maturity Date”). If there is a fee specified on line 2 of the Itemization of Amount Financed above
        (the "Origination Fee"), it is included in the principal amount of this Loan, is fully-earned as of the date of this
        Agreement and is non-refundable. You promise to pay the Origination Fee, if any, and any other charges provided
        for under this Agreement.
        
          The Finance Charge disclosure above represents
          the amount of interest plus the Origination Fee, if any, that you will pay under this Agreement assuming you pay all your scheduled installment
          payments when due according to the Payment Schedule (and assuming your Loan is disbursed on the Projected Disbursement Date).
        
        You promise to make the payments you owe by
        
          authorizing us to initiate electronic fund transfers from a deposit account of yours; or,
          in the event that we are unable to process electronic fund transfers,
        
        by making payment by another method acceptable to us.
        In order for your payment to be considered timely, it must be submitted by the cut-off time applicable
        to your method of payment, which can be found at <a href="https://www.netcredit.com/faq" target="_blank">https://www.netcredit.com/faq</a>.
        If you fail to make all or any
        part of a scheduled installment payment within 10 days of its scheduled installment
        due date, you may be charged a late charge of the lesser of $10.00 or 5% of the unpaid amount of the scheduled installment payment.
        
        We charge interest under
        an adjusted daily simple interest method at the annual rate of
        35.68% (“Contract Rate”). Interest shall not be payable in
        advance or compounded. We apply the Contract Rate to the “Adjusted Principal Balance” from the actual disbursement date until the earlier of (i) the Maturity Date, (ii) prepayment in full, or (iii) acceleration pursuant to the default provisions. The term “Adjusted Principal Balance” (hereinafter the “APB”) means
        the loan principal balance that would be outstanding assuming you make each payment timely,
        less any additional prepayments of principal. “Timely” means making payment in accordance with
        the Payment Schedule or any adjusted payment schedule agreed to by the parties and confirmed by
        us in writing. The APB will equal the outstanding loan principal balance if you make all payments
        timely and no other payments. You will not be required to pay more total interest than the amount
        set forth, less the Origination Fee, if any, in the Finance Charge box above (unless your Loan is disbursed before the Projected Disbursement Date).
      </p>

      <h3>PREPAYMENT.</h3>
      <p>
        You have the right to prepay in full or in part at any time. You will not incur an
        additional charge, fee or penalty for prepayment. Prepayments of principal may reduce
        the total amount of interest you pay under this Agreement. To process a partial or full
        prepayment or receive a payoff balance, you should call us at (877) 392-2014 or
        tell us of your intent to prepay in writing via fax at (855) 878-1068 or email at
        <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
        Please note that, unless otherwise specified, any payoff amount we provide will be calculated
        as of the date we expect to seek payment from your deposit account for the balance owing.
      </p>

      <h3>CANCELLATION.</h3>
      <p>
        You have the right to cancel this Loan until 06:00 PM Eastern Time on the next business
        day immediately following the day we disburse the proceeds of your Loan or, in the case of
        a refinancing, the third business day immediately following the day we apply your Loan
        proceeds to pay off another loan (as applicable, the “Cancellation
        Deadline”). To cancel, you must, no later than the Cancellation Deadline, inform us in
        writing via fax at (855) 878-1068 or email at
        <a href="mailto:support@netcredit.com">support@netcredit.com</a>
        of your intent to cancel, which will authorize us to debit for the principal amount of
        the Loan, as described below. If you seek to cancel and your cancellation payment is
        successful, all interest that has accrued and the Origination Fee, if any, will be waived and you will have no further
        payment obligations under this Agreement. If you seek to cancel but your cancellation
        payment is returned unpaid or is otherwise not successful, your Loan will not be cancelled
        and your obligations under this Agreement will continue just as if you had not attempted
        to cancel.
      </p>

      <h3>APPLICATION OF PAYMENTS.</h3>
      <p>
        Payments will be applied first to satisfy any past due amounts owing, and then to any currently scheduled installment amounts owing.
        If you are past due, we credit all payments received first to the oldest past due amounts owing by applying such first to unpaid fees (excluding any Origination Fee),
        second to accumulated but unpaid interest, and third to past due principal owing (including any Origination Fee). We repeat the process until all past due amounts
        have been paid in full. If you are not past due, we credit all payments to the current or upcoming scheduled installment by applying
        such first to fees (excluding any Origination Fee), second to interest, and third to principal (including any Origination Fee) as of the date received. If an early payment is less than the amount
        of the next scheduled installment, then you must pay the difference on or before the corresponding installment due date. Making an
        installment payment in excess of what is due may reduce the total amount of interest you are scheduled to pay under this Agreement
        and your remaining payments (starting with your last payment and working backwards). However, you cannot generally rely on an excess
        payment made on or before one installment due date to satisfy the installment obligation associated with the next installment due date.
        For example, if you have a 12-installment loan and, on your second installment due date, you make a double payment, you will still owe
        a regular installment payment on your third due date (but that extra payment will likely reduce the amount of interest you pay and may
        reduce or eliminate your twelfth installment payment).
      </p>

      
      <div class="section authorization" id="authorization">
        <h2 id="authorization_agreement">ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS.</h2>
        <p>
          You authorize us to initiate an electronic fund transfer (“EFT”) to credit the checking or
          savings account specified in your Application/Data Verification form or any substitute
          account you may later provide us (the “Deposit Account”) with the proceeds of your
          Loan. In the event that we make an error in processing this EFT, you further authorize us
          to initiate an EFT to the Deposit Account to correct the error. You authorize us to initiate
          EFT credits to the Deposit Account as may be necessary to obtain an accurate balance
          under this Agreement.
        </p>

        <h2 id="payment_authorization">PAYMENT AUTHORIZATION.</h2>
        
          <h3>EFT Authorization for Installment Payments; Error Correction; and in the Event of Acceleration, the Loan Balance</h3>
        
        <p>
          You authorize us (which includes, for the purpose of this Payment Authorization, our
          servicers/service providers, successors and assigns and the servicers/service providers of
          our successors and assigns) to initiate an EFT through the ACH
          network (or another network of our choosing) from the Deposit Account for the
          following:
        </p>
        <ul>
          
            <li>
              Each of the installment payments due under the Agreement (or, if you make a partial
              installment payment, the amount of the installment less the amount of any such payment)
              on or after its due date, including any payment due on a non-banking day, which will be
              processed on the preceding business day;
            </li>
            <li>
              In the event that you fail to make all or any part of a scheduled installment payment
              within 10 days of its scheduled installment due date, you may be charged a late charge of the lesser
              of $10.00 or 5% of the unpaid amount of the scheduled installment payment on or after the next scheduled installment due date;
            </li>
            <li>
              The final installment payment due, which may vary based upon payment history (including early
              payments or extra payments) during the course of the Agreement;
            </li>
          
          <li>
            In the event that you cancel the loan as prescribed in the Cancellation provision above the entire
            loan principal 
            within two business days of timely cancellation;
          </li>
          <li>
            In the event that you default and we declare the entire outstanding balance (the “Accelerated Balance”),
            including all accrued and unpaid interest that you owe under this Agreement, due and payable,
            the Accelerated Balance on or after the date we accelerate; and
          </li>
          <li>
            The amount required to correct any error we make in processing an EFT on or after the date we make the error.
          </li>
        </ul>
        
          <p>
            If any payment cannot be obtained by EFT, you remain responsible for such payment under the Agreement.
            You agree that we may resubmit any returned EFT as permitted by law and network rules.
            If you incur any late charges, you agree to allow us to add the amount of such late charge that comes
            due to your next scheduled installment EFT or to initiate a separate EFT for the late charge on the date
            of your next scheduled installment EFT.
            The amount of any EFT for a scheduled installment payment may be reduced by any amount we receive
            in advance of the EFT that reduces the installment due.
            You acknowledge that the origination of EFT transactions to the Deposit Account must comply with the provisions of U.S. law.
          </p>
          <h3>Modified Charges</h3>
          <p>
            Instead of or in addition to any of the EFTs described above, you authorize us to process any EFTs
            you subsequently confirm by phone, fax, text message, email, or by logging into the online
            Loan account portal.
          </p>
        

        <h3>Right to Stop Payment and Procedure for Doing So</h3>
        <p>
          You may terminate one or all of the authorizations to initiate EFTs from the Deposit Account set forth above
          by calling us, c/o NetCredit, at (877) 392-2014, or writing us via fax at (855) 878-1068
          or e-mail at <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
          We will discontinue initiating any EFTs you specify as soon as we
          reasonably can and, in any event, within three business days after receiving your termination request.
        </p>
        
          <p>
            In the event that you terminate this authorization, you may make payments on your loan by calling us at
            (877) 392-2014 to initiate repayment by debit card, money order, Western Union, or MoneyGram or
            by sending us a check to NetCredit, P.O. Box 206766, Dallas, TX 75320-6766. The Individual account
            ID and Customer Name should be included with payment. No correspondence should be included.
          </p>
          <h3>Range of Varying Amounts</h3>
          <p>
            Your scheduled installment payments provided in the Payment Schedule shown above will vary if:
            (1) you make partial payments; and/or (2) you are assessed any late charges
            pursuant to the Agreement. The range of your scheduled installment payments will normally not vary by more
            than the amount necessary for such adjustments. If your scheduled installment payment exceeds the described
            range, we will send you notice at least ten (10) days in advance of the payment.
          </p>
          <p>
            You may elect to receive notice in advance of any payment amount that varies from that which is in your payment
            schedule or your most recent payment. To receive advance notice of all varying amount payments, you may call us,
            c/o NetCredit, at (877) 392-2014, or write us via fax at (855) 878-1068
            or e-mail at <a href="mailto:support@netcredit.com">support@netcredit.com</a>.
          </p>
        

        <h3>Your Deposit Account Information</h3>
        <p>
          If there is any missing or erroneous information concerning your Deposit Account or the financial institution
          that holds it, then you authorize us to verify and correct such information. You have given us certain information
          about your Deposit Account so that we can verify the account number and routing number and you acknowledge that
          your Deposit Account is a legitimate, open, and active account.
        </p>

        <h3>Fees Your Financial Institution May Charge You For Rejected Payments</h3>
        <p>
          You understand that the financial institution holding your Deposit Account may impose additional fees in
          connection with returned or rejected EFTs, and you agree that we do not have any liability regarding any
          such fees. <strong>IN THE EVENT THAT YOU DO NOT HAVE SUFFICIENT FUNDS IN YOUR DEPOSIT ACCOUNT TO COVER THE
          AMOUNT OF ANY EFT AUTHORIZED HERE, YOU SHOULD CONTACT US AT LEAST THREE BUSINESS DAYS IN ADVANCE OF OUR
          INITIATING THE EFT SO THAT WE CAN STOP THE EFT AND ARRANGE FOR AN ALTERNATIVE METHOD OF REPAYMENT.</strong>
        </p>

        <h3>Full Force and Effect</h3>
        <p>
          This Payment Authorization will remain in full force and effect until (i) we have received notification
          from you, as instructed above, of its termination in such time and in such manner as to afford us and the
          financial institution holding the Deposit Account a reasonable opportunity to act on it, or (ii) upon full
          and final payment of the amount you owe us under this Agreement.
        </p>

        
          <h3>Optional Nature of Authorization</h3>
          <p>
            Please note that you are not required to authorize us to initiate EFTs for your installment payments to enter into this Agreement.
            You have the option of arranging to make your installment payments by a method that does not involve EFTs.
            You agree that making your installment payments by EFT is for your convenience. By entering the last four digits of
            your Social Security Number and signing this Payment Authorization and clicking the “I AGREE” button below,
            you acknowledge that you are voluntarily choosing to make your installment payments by EFT.
          </p>
        
      </div> 

      
        <h3>SECURITY.</h3>
        <p>
          Pursuant to Comment 2(a)(25) of the Federal Reserve Board Official Staff Commentary to
          Regulation Z §1026.2, we have disclosed to you that our interest in the ACH Authorization
          is a security interest for Truth-in-Lending purposes only, because Federal and Wisconsin law do
          not clearly address whether our interest in the ACH Authorization is a "security interest."
        </p>
      

      
      <div class="waiver_of_jury_trial" id="trial_waiver">
        <h2 id="waiver_of_jury_trial">WAIVER OF JURY TRIAL.</h2>
        <p>
          Subject to the Arbitration Provision, if you sign this Agreement you waive your right to have a
          jury trial to resolve any dispute you may have against us or a related third party. This Waiver
          of Jury Trial provision does not apply to you if, at the time of entering into this Agreement,
          you are a member of the armed forces or a dependent of such a member covered by the Federal Military Lending Act.
        </p>

        <h3>DEFAULT AND ACCELERATION.</h3>
        <p>
          Subject to any applicable law which may require that we notify and/or give you a right to cure your 
          default, we may declare you in default under this Agreement if: (1) an amount exceeding one full payment 
          remains unpaid for more than 10 days after the scheduled or deferred due date; or (2) you fail to pay the 
          first payment or last payment within 40 days of its scheduled or deferred due date. For purposes of this 
          paragraph, the amount outstanding will not include any delinquency or deferral charges and will be computed 
          by applying each payment first to the installment most delinquent then to subsequent installments in the 
          order they come due. In the event of default, we may (i) declare the entire outstanding balance that you 
          owe under this Agreement, and if applicable, any other charges permitted by law, immediately due and payable; (ii)
          proceed to reduce our claim to a judgment in accordance with applicable law; and (iii) exercise any
          other rights we have under this Agreement or the law. To the extent permitted by applicable law, you
          agree to pay our court costs, reasonable attorneys’ fees and other collection costs related to the default.
        </p>

        <h3 style="text-decoration: underline;">Wisconsin Residents</h3>
        <p>
          Pursuant to applicable law, no provision(s) in this Agreement providing for the payment
          of attorneys’ fees by You shall apply.
        </p>

        <h3>NON-WAIVER OF OUR RIGHTS.</h3>
        <p>
          By choosing any one or more of these remedies listed above, we do not give up our right to use another
          remedy later. By deciding not to use any remedy should you be in default, we do not give up our right to
          consider the event a default if it happens again. We may delay or refrain from enforcing any of our rights
          under this Agreement without waiving such rights. You understand that any waiver of our rights will not be
          effective unless it is in writing signed by us.
        </p>

        <div class="callout marquee">
          <h3>CREDIT REPORTING.</h3>
          <p>
            <strong>We may report information about your Loan to credit bureaus. Late payments, missed payments or
            other defaults on your Loan may be reflected in your credit report.</strong>
          </p>
          <p>
            <strong>If you believe that any information about your Loan that we have furnished to a consumer reporting
            agency is inaccurate, or if you believe that you have been the victim of identity theft in connection
            with any Loan made by us, write to us at NC Financial Solutions of Wisconsin, LLC. Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. In your letter (i) provide
            your name and Loan number, (ii) identify the specific information that is being disputed, (iii) explain
            the basis for the dispute and (iv) provide any supporting documentation you have that substantiates the
            basis of the dispute. If you believe that you have been the victim of identity theft, submit an identity
            theft affidavit or identity theft report.</strong>
          </p>
        </div>

        <h3>GOVERNING LAW AND EXECUTION.</h3>
        <p>
          <strong>This Agreement (other than the Arbitration Provision) and all related disputes are governed by the laws of
          the United States and the State of Wisconsin. Except as provided in the <a href="#arbitration_provision">ARBITRATION PROVISION</a>,
          if any provision of this Agreement is determined to be void or unenforceable under any applicable law, rule or regulation,
          all other provisions of this Agreement will remain valid and enforceable. This Agreement is a final expression
          of the agreement between you and us and this Agreement may not be contradicted by evidence of any alleged oral
          agreement. The terms of this Agreement may not be changed except in a writing signed by you and us. No change
          shall release any party from liability unless otherwise expressly stated in writing. All of our rights are cumulative.
          If we approve this Agreement, then you agree that this Agreement will be binding and enforceable as to both parties.</strong>
        </p>

        <h3>CORRESPONDENCE.</h3>
        <p>
          All notices to us must be sent via fax at (855) 878-1068 or e-mail at <a href="mailto:support@netcredit.com">
          support@netcredit.com</a> (“the Notice Address”), with such attention as may be specified in this Agreement. To the
          extent permitted under applicable law, any notice you send us will not be effective until we receive and have had a reasonable
          opportunity to act on such notice. Any written or electronic correspondence we send to you will be effective and deemed delivered
          when sent in accordance with any authorization for electronic communications you execute or mailed to you at your mail address,
          as it appears on our records.
        </p>

        <h3>BANKRUPTCY.</h3>
        <p>
          All bankruptcy notices and related correspondence to us must be sent to the Notice Address, Attn: Bankruptcy Notice.
          You promise that you have no current intent to file any bankruptcy petition and have not consulted a bankruptcy
          attorney in the past six months.
        </p>

        <h3>CHANGE IN CIRCUMSTANCES.</h3>
        <p>
          You must notify us of any changes to your name, mailing or email address, or phone number (including your cell phone number)
          within fifteen (15) days by writing us at the Notice Address or going on www.netcredit.com (the “Website”).
          We will rely on your mail and email addresses as they appear on our records for any and all communications
          we send you by mail or email unless and until either you or, in the case of your mailing address, the U.S. Postal Service,
          notifies us of a change of address and we have had a reasonable opportunity to act on such notice.
        </p>

        <h3>PARTIAL PAYMENTS MARKED PAYMENT IN FULL; SETTLEMENTS.</h3>
        <p>
          Any check or other payment you send us for less than the total outstanding balance due under this Agreement that
          is marked “payment in full” or with any similar language or that you otherwise tender as full satisfaction of a
          disputed amount must be sent to NetCredit, P.O. Box 206766, Dallas, TX 75320-6766. We may deposit any such
          payment without such deposit effecting a satisfaction of the disputed amount. Any settlement of your Account balance
          for less than what is owed requires our written agreement.
        </p>

        <h3>INADVERTENT OVERCHARGES.</h3>
        <p>
          It is not our intention to charge any interest, fees or other amounts in excess of those permitted by applicable
          law or this Agreement. If any interest, fee or other amount is determined to be in excess of that permitted by
          applicable law or this Agreement, the excess amount will be applied to reduce the outstanding balance due under
          this Agreement or, if there is no outstanding balance, will be refunded to you.
        </p>

        <h3>CREDIT REPORTS; CREDIT HISTORY.</h3>
        <p>You authorize us to obtain credit and other reports about you from time to time.</p>

        <h3>CONTACTING YOU; PHONE AND TEXT MESSAGES; CALL RECORDING.</h3>
        <p>
          You authorize us and our assignees and the affiliates, agents, representatives, assigns and service providers of
          us and our assignees (collectively, the “Messaging Parties”) to contact you using automatic telephone dialing systems,
          artificial or prerecorded voice message systems, text messaging systems and automated email systems in order to
          provide you with information about this Agreement, including information about upcoming payment due dates, missed
          payments and returned payments. You authorize  the Messaging Parties to make such contacts using any telephone numbers
          (including wireless, landline and VOIP numbers) or email addresses you supply to the Messaging Parties in connection
          with this Agreement, the Messaging Parties' servicing and/or collection of amounts you owe the Messaging Parties or
          any other matter. You understand that anyone with access to your telephone or email account may listen to or read the
          messages the Messaging Parties leave or send you, and you agree that the Messaging Parties will have no liability for
          anyone accessing such messages. You further understand that, when you receive a telephone call, text message or email,
          you may incur a charge from the company that provides you with telecommunications, wireless and/or Internet services,
          and you agree that the Messaging Parties will have no liability for such charges. You expressly authorize the Messaging
          Parties to monitor and record your calls with the Messaging Parties.
        </p>

        <h3>ASSIGNMENT.</h3>
        <p>
          We may sell or otherwise transfer our rights under this Agreement and amounts owed by you to another creditor at any time.
          If we do, this Agreement will remain in effect. You may not transfer your rights under this Agreement. Any attempt to do
          so will be void.
        </p>

        <h3>MILITARY LENDING ACT.</h3>
        <h3 style="text-decoration: underline;">General</h3>
        <p>
          <strong>The Military Lending Act provides protections for certain members of the Armed
          Forces and their dependents (“Covered Borrowers”). The provisions of this section
          apply to Covered Borrowers. If you would like more information about whether you
          are a Covered Borrower and whether this section applies to you, please contact us at
          (877) 392-2014.</strong>
        </p>

        <h3 style="text-decoration: underline;">Statement of MAPR</h3>
        <p>
          <strong>Federal law provides important protections to members of the Armed Forces and their dependents relating to extensions
          of consumer credit. In general, the cost of consumer credit to a member of the Armed Forces and his or her dependent
          may not exceed an Annual Percentage Rate of 36 percent. This rate must include, as applicable to the credit transaction
          or account: (1) the costs associated with credit insurance premiums; (2) fees for ancillary products sold in connection
          with the credit transaction; (3) any application fee charged (other than certain application fees for specified credit
          transactions or accounts); and (4) any participation fee charged (other than certain participation fees for a credit
          card account).</strong>
        </p>

        <h3 style="text-decoration: underline;">Oral Disclosures</h3>
        <p>
          <strong>Before signing this Agreement, in order to hear important disclosures and payment information about
          this Agreement, please call (877) 392-2014.</strong>
        </p>
        <h3 style="text-decoration: underline;">Covered Borrowers Savings Clause</h3>
        <p>
          <strong>
            The provisions of this Paragraph apply only to a “Covered Borrower” as that term is defined by 32 C.F.R.
            § 232.3(g).  If any contract provision not identified herein is contrary to the rights and protections
            afforded to you by Federal law pursuant to 10 U.S.C. § 987 and its implementing regulations, including,
            but not limited to 32 C.F.R. § 232.8, then the conflicting provisions or proscribed terms are inoperative,
            and shall have no force and effect.  However, all remaining contract terms and provisions not proscribed
            or prohibited shall remain in full force and effect.
          </strong>
        </p>

        <h3>MISCELLANEOUS.</h3>
        <p>
          The section headings used in this Agreement are for convenience of reference only and do not in any way limit or
          define your or our rights or obligations hereunder. If any provision of this Agreement is invalid or unenforceable
          under any law, rule or regulation, it shall not affect the validity or enforceability of any other provision of this
          Agreement. Except as otherwise provided in this Agreement or as required by applicable law, we will not be responsible
          for any claim or defense you may have against any third party that arises out of or in connection with this Agreement.
        </p>

        <h3 id="arbitration_provision">ARBITRATION PROVISION.</h3>
        <p>
          Arbitration is a process in which persons with a dispute(s): (a) agree to submit their dispute(s) to a neutral third
          person (an "arbitrator") for a decision; and (b) waive their rights to file a lawsuit in court to resolve their dispute(s).
          Each party to the dispute(s) has an opportunity to present some evidence to the arbitrator. Pre-arbitration discovery may
          be limited. Arbitration proceedings are private and less formal than court trials. The arbitrator will issue a final and
          binding decision resolving the dispute(s), which may be enforced as a court judgment. A court rarely overturns an
          arbitrator's decision.
        </p>
        <p>
          <strong>THIS ARBITRATION PROVISION APPLIES UNLESS (1) YOU HAVE EXERCISED OR EXERCISE YOUR RIGHT TO REJECT ARBITRATION OR (2)
          AS OF THE DATE OF THIS AGREEMENT, YOU ARE A MEMBER OF THE ARMED FORCES OR A DEPENDENT OF SUCH MEMBER COVERED BY THE
          FEDERAL MILITARY LENDING ACT. IF YOU WOULD LIKE MORE INFORMATION ABOUT WHETHER YOU ARE COVERED BY THE MILITARY LENDING
          ACT, IN WHICH CASE THIS ARBITRATION PROVISION DOES NOT APPLY TO YOU, PLEASE CONTACT US AT (877) 392-2014.</strong>
        </p>
        <p>
          <strong>READ THIS ARBITRATION PROVISION CAREFULLY AS IT WILL HAVE A SUBSTANTIAL IMPACT ON HOW LEGAL CLAIMS YOU
          AND WE HAVE AGAINST EACH OTHER ARE RESOLVED.</strong>
        </p>
        <p>
          <strong>YOU HAVE THE RIGHT TO OPT-OUT OF (NOT BE BOUND BY) THIS ARBITRATION PROVISION AS DESCRIBED BELOW. IF YOU DO NOT DO SO:</strong>
        </p>
        <ol id="court-and-class-action-waivers">
          <li>
            <strong>
              YOU ARE WAIVING YOUR RIGHT TO HAVE A JURY OR A COURT, OTHER THAN A SMALL CLAIMS COURT OR SIMILAR COURT OF
              LIMITED JURISDICTION, RESOLVE ANY CLAIM THAT IS SUBJECT TO ARBITRATION; and
            </strong>
          </li>
          <li>
            <strong>
              YOU ARE WAIVING YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE
              CAPACITY, AND/OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN COURT OR IN ARBITRATION WITH RESPECT TO ANY
              CLAIM THAT IS SUBJECT TO ARBITRATION.
            </strong>
          </li>
        </ol>
        <p>THEREFORE, YOU ACKNOWLEDGE AND AGREE AS FOLLOWS:</p>

        <h3 style="text-decoration: underline;">Interstate Commerce</h3>
        <p>
          This Arbitration Provision is made pursuant to a transaction involving interstate commerce and shall be governed
          by the Federal Arbitration Act (“FAA”), 9 U.S.C. § 1 et seq., and not by any state arbitration law.
        </p>

        <h3 style="text-decoration: underline;">Scope</h3>
        <p>
          For purposes of this Arbitration Provision, the word “Claim” has the broadest possible meaning and includes, without limitation (a)
          all federal or state law claims, disputes or controversies, whether preexisting, present or future, arising from or relating directly
          or indirectly to this Agreement, the relationship between you and us, the information you gave us before entering into this Agreement, including your application, and/or any
          past agreement or agreements between you and us, any loan or credit product or related product or services obtained from us and any
          advice, recommendations, solicitations, communications, disclosures, promotions or advertisements concerning the same; (b) all initial
          claims, counterclaims, cross-claims and third-party claims and claims which arose before the effective date of this Arbitration Provision;
          (c) all common law claims based upon contract, tort, fraud, or other intentional torts; (d) all claims based upon a violation of any local,
          state or federal constitution, statute, ordinance or regulation, including without limitation all claims alleging unfair, deceptive or
          unconscionable trade practices; (e) all claims asserted by us against you, including claims for money damages to collect any sum we claim
          you owe us; (f) all claims asserted by you individually against us and/or any of our employees, directors, officers, shareholders, governors,
          managers, members, parent company or affiliated entities, or assignees (including but not limited to NetCredit) or against the servicer of
          your Loan (including but not limited to NetCredit) (hereinafter collectively referred to as "related third parties"), including claims for
          money damages, restitution and/or equitable or injunctive relief; (g) claims asserted by you against other persons and entities if you assert
          a Claim against such other persons and entities in connection with a Claim you assert against us or related third parties; and (h) all data
          breach or privacy claims arising from or relating directly or indirectly to the disclosure by us or related third parties of any non-public
          personal information about you. Notwithstanding the foregoing, the word “Claim” does not include any dispute or controversy about the validity,
          enforceability, coverage or scope of this Arbitration Provision or any part thereof (including, without limitation, the provision titled "Class Action Waiver"
          set forth below, subparts A and B of the provision titled "Binding Effect; Survival; Severability"
          set forth below and/or this sentence); all such disputes or controversies are for a court and not an arbitrator to decide. However, any dispute
          or controversy that concerns the validity or enforceability of the Agreement as a whole is for the arbitrator, not a court, to decide. In addition,
          this Arbitration Provision will not apply to (1) any individual action brought by you in small claims court or your state’s equivalent court,
          unless such action is transferred, removed, or appealed to a different court; or (2) any disputes that are the subject of a class action filed
          in court that is pending as of the effective date of this Arbitration Provision in which you are alleged to be a member of the putative class
          for as long as such class action is pending.
        </p>

        <h3 style="text-decoration: underline;">Class Action Waiver</h3>
        <p>
          <strong>Notwithstanding any other provision of this Arbitration Provision, if either you or we elect to arbitrate a Claim, neither you nor we will
          have the right: (a) to participate in a class action, private attorney general action or other representative action in court or in arbitration,
          either as a class representative or class member, or (b) to join or consolidate Claims with Claims of any other persons. No arbitrator shall
          have authority to conduct any arbitration in violation of this provision or to issue any relief that applies to any person or entity other than you
          and/or us individually. (Provided, however, that the Class Action Waiver does not apply to any lawsuit or administrative proceeding filed against
          us by a state or federal government agency even when such agency is seeking relief on behalf of a class of borrowers including you. This means that
          we will not have the right to compel arbitration of any claims brought by such an agency). An award in arbitration shall determine the rights and
          obligations of the named parties only, and only with respect to the Claim(s) in arbitration, and shall not (i) determine the rights, obligations,
          or interests of anyone other than a named party, or resolve any Claim of anyone other than a named party; nor (ii) make an award for the benefit of,
          or against, anyone other than a named party. No arbitration administrator or arbitrator shall have the power or authority to waive, modify, or fail to
          enforce this section, and any attempt to do so, whether by rule, policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any
          challenge to the validity of this Class Action Waiver shall be determined exclusively by a court and not by the administrator or any arbitrator.</strong>
        </p>

        <h3 style="text-decoration: underline;">Electing Arbitration</h3>
        <p>
          A party may elect arbitration of a Claim by sending the other party written notice by certified mail return receipt requested of their
          intent to arbitrate and setting forth the subject of the dispute along with the relief requested. Your notice must be sent to
          NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604, and our notice must be sent to the most recent address for you in our files. If a lawsuit concerning the
          Claim has been filed, such notice can be provided by papers filed in the lawsuit, such as a motion to compel arbitration.
        </p>

        <h3 style="text-decoration: underline;">Administrator</h3>
        <p>
          Regardless of who demands arbitration, you shall have the right to select either of the following arbitration organizations to
          administer the arbitration: the American Arbitration Association, 120 Broadway, Floor 21, New York, N.Y. 10271
          (1-800-778-7879), <a href="http://www.adr.org" target="_blank">http://www.adr.org</a>; or JAMS, 8401 N. Central Expressway, Suite 610,
          Dallas, TX 75225 (1-800-352-5267), <a href="http://jamsadr.com" target="_blank">http://jamsadr.com</a>. However, the parties may agree
          to a local arbitrator who is an attorney, retired judge, or arbitrator registered and in good standing  with a bona fide arbitration
          association and arbitrate pursuant to the arbitrator’s rules. If the AAA and JAMS are unable or unwilling to serve as administrator,
          or the parties are unable to agree upon another administrator, a court with jurisdiction shall determine the administrator or arbitrator. The arbitration
          hearing will take place at a location reasonably convenient to where you reside.
        </p>
        <p>
          If you demand arbitration, you must inform us in your demand of the arbitration organization you have selected or whether you desire to
          select a local arbitrator. If related third parties or we demand arbitration, you must notify us within 20 days in writing by certified
          mail, return receipt requested, of your decision to select an arbitration organization or your desire to select a local arbitrator. Your
          notice must be sent to NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. If you fail to notify us,
          then we have the right to select an arbitration organization. The arbitration will be governed by the rules and procedures of this arbitration
          organization applicable to individual consumer disputes. You may get a copy of the rules and procedures by contacting the arbitration organization
          listed above. In an event of a conflict between the provisions of the Arbitration Provision, on the one hand, and any applicable rules of the AAA
          or JAMS or other administrator used or any other terms of this Agreement, on the other hand, the provisions of this Arbitration Provision shall
          control. If a party files a lawsuit in court asserting Claim(s) that are subject to arbitration and the other party files a motion to compel
          arbitration with the court which is granted, it will be the responsibility of the party prosecuting the Claim(s) to select an arbitration administrator
          in accordance with this paragraph and commence the arbitration proceeding in accordance with the administrator’s rules and procedures.
        </p>

        <p>
          <strong>Non-Waiver</strong>. Even if all parties have opted to litigate a Claim in court, you or we may elect arbitration with respect to any Claim made by a
          new party or any Claim later asserted by a party in that or any related or unrelated lawsuit (including a Claim initially asserted on an individual
          basis but modified to be asserted on a class, representative or multi-party basis).  Nothing in that litigation shall constitute a waiver of any
          rights under this Arbitration Provision. For example, if we file a lawsuit against you in court to recover amounts due under the Agreement, you have
          the right to request arbitration, but if you do not elect to request arbitration, we reserve and do not waive the right to request arbitration of
          any Claim (including any counterclaim) you later assert against us in that or any related or unrelated lawsuit. This Arbitration Provision will apply
          to all Claims, even if the facts and circumstances giving rise to the Claims existed before the effective date of this Arbitration Provision.
        </p>

        <h3 style="text-decoration: underline;">Arbitrator and Award</h3>
        <p>
          Any arbitrator must be a practicing attorney with ten or more years of experience or a retired judge. The arbitrator will not be bound by
          judicial rules of procedure or evidence that would apply in a court, nor by state or local laws that relate to arbitration proceedings. The
          arbitrator will apply the same statutes of limitations and privileges that a court would apply if the matter were pending in court. The arbitrator
          may decide, with or without a hearing, any motion that is substantially similar to a motion to dismiss for failure to state a claim or a motion
          for summary judgment. In determining liability or awarding damages or other relief, the arbitrator will follow the applicable substantive law,
          consistent with the FAA, which would apply if the matter had been brought in court. The arbitrator may award any damages or other relief or remedies
          that would apply under applicable law to an individual action brought in court, including, without limitation, punitive damages (which shall be
          governed by the Constitutional standards employed by the courts) and injunctive, equitable and declaratory relief (but only in favor of the individual
          party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim). 
          No arbitration award involving the parties will have any preclusive effect as to issues or claims in any dispute 
          involving anyone who is not a party to the arbitration, nor will an arbitration award in prior disputes involving 
          other parties have preclusive effect in an arbitration between the parties to this Arbitration Provision.
        </p>

        <h3 style="text-decoration: underline;">Fees and Costs</h3>
        <p>
          At your written request, we will pay all filing, hearing and/or other fees charged by the administrator and arbitrator to you for Claim(s)
          asserted by you in an individual arbitration after you have paid an amount equivalent to the fee, if any, for filing such Claim(s) in state or
          federal court (whichever is less) in the judicial district in which you reside. (If you have already paid a filing fee for asserting the Claim(s)
          in court, you will not be required to pay that amount again). We will not seek reimbursement of such fees from you even if we prevail in the
          arbitration. In addition, the administrator may have a procedure whereby you can seek a waiver of fees charged to you by the administrator and
          arbitrator. We will always pay any fees or expenses that we are required to pay by law or the administrator’s rules or that we are required to pay
          for this Arbitration Provision to be enforced. The arbitrator will have the authority to award fees and costs of attorneys, witnesses and experts to
          the extent permitted by this Agreement, the administrator’s rules or applicable law. With respect to Claim(s) asserted by you in an individual arbitration,
          we will pay your reasonable attorney, witness and expert fees and costs if and to the extent you prevail, if applicable law requires us to or if we must
          bear such fees and costs in order for this Arbitration Provision to be enforced. At the timely request of either party, the arbitrator shall write a brief
          explanation of the grounds for the decision.
        </p>

        <h3 style="text-decoration: underline;">Appeal</h3>
        <p>
          The arbitrator’s award shall be final and binding on all parties, except for any right of appeal provided by the FAA. However, if the amount in
          controversy exceeds $50,000, and if permitted by the Administrator’s rules, you or we can, within 14 days after the entry of the award by the
          arbitrator, appeal the award to a three-arbitrator panel administered by the Administrator. The panel shall reconsider anew any aspect of the initial
          award requested by the appealing party. The decision of the panel shall be by majority vote. Reference in this
          Arbitration Provision to “the arbitrator” shall mean the panel if an appeal of the arbitrator’s decision has been taken. The costs of such an appeal will
          be borne in accordance with the paragraph above titled “Fees and Costs.” Any final decision of the appeal is subject to judicial review only as provided
          under the FAA. A judgment on the award may be entered by any court having jurisdiction.
        </p>

        <h3 style="text-decoration:underline;">Notice and Cure; Special Payment</h3>
        <p>
          Prior to initiating a Claim, you may send us a written Claim Notice. In order for a Claim Notice to be valid and effective, it must: (a)
          state your name, address and loan number; (b) be signed by you; (c) describe the basis of your Claim and the amount you would accept to
          resolve the Claim; (d) state that you are exercising your rights under the “Notice and Cure” paragraph of the Arbitration Provision; and
          (e) be sent to us by certified mail, return receipt requested, at NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604. This is the sole and only method by which
          you can submit a Claim Notice. Upon receipt of a Claim Notice, we will credit you for the standard cost of a certified letter. You must
          give us a reasonable opportunity, not less than 30 days, to resolve the Claim. If, and only if, (i) you submit a Claim Notice in accordance
          with this paragraph on your own behalf (and not on behalf of any other party); (ii) you cooperate with us by promptly providing the information
          we reasonably  request; (iii) we refuse to provide you with the relief you request before an arbitrator is appointed; and (iv) the matter then
          proceeds to arbitration and the arbitrator subsequently determines that you were entitled to such relief (or greater relief), you will be entitled
          to a minimum award of at least $7,500 (not including any arbitration fees and attorneys’ fees and costs to which you will also be entitled).
          We encourage you to address all Claims you have in a single Claim Notice and/or a single arbitration. Accordingly, this $7,500 minimum award is
          a single award that applies to all Claims you have asserted or could have asserted in the arbitration, and multiple awards of $7,500 are not contemplated.
        </p>
      </div> 

      <h3 style="text-decoration: underline;">Binding Effect; Survival; Severability</h3>
      <p>
        This Arbitration Provision is binding upon and benefits you, your respective heirs, successors and assigns. The Arbitration
        Provision is binding upon and benefits us, our successors and assigns, and related third parties. The Arbitration Provision
        shall survive any bankruptcy to the extent consistent with applicable bankruptcy law. The Arbitration Provision survives any
        termination, amendment, expiration or performance of this Agreement and any transaction between you and us and continues in
        full force and effect unless you and we otherwise agree in writing. If any part of this Arbitration Provision, other than the
        Class Action Waiver, is deemed or found to be unenforceable for any reason, the remainder shall be enforceable, except as follows:
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; A. &nbsp; &nbsp; The parties to this Arbitration Provision acknowledge that the provision titled "Class Action Waiver" is material and essential
        to the arbitration of any disputes between the parties and is non-severable from this Arbitration Provision. If the Class Action
        Waiver is limited, voided or found unenforceable, then the parties' Arbitration Provision (except for this sentence) shall be null
        and void with respect to such proceeding, subject to the right to appeal the limitation or invalidation of the Class Action Waiver.
        The parties acknowledge and agree that under no circumstances will a class action be arbitrated.
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; B. &nbsp; &nbsp; If a Claim is brought seeking public injunctive relief and a court determines that the restrictions in the provision titled
        "Class Action Waiver" prohibiting the arbitrator from awarding relief on behalf of third parties are unenforceable with respect to
        such Claim (and that determination becomes final after all appeals have been exhausted), the Claim for public injunctive relief will
        be determined in court and any individual Claims seeking monetary relief will be arbitrated.  In such a case the parties will request
        that the court stay the Claim for public injunctive relief until the arbitration award pertaining to individual relief has been entered
        in court.  In no event will a Claim for public injunctive relief be arbitrated.
      </p>

      <h3 style="text-decoration: underline;">Opt Out Process</h3>
      <p>
        You may choose to opt out of this Arbitration Provision but only by following the process set forth below. If you do not wish to be subject
        to this Arbitration Provision, then you must notify us in writing postmarked within sixty (60) calendar days of the date of this Agreement
        at the following address: <strong>NetCredit, Attn: General Counsel, 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604</strong>. Your notice must be sent
        to us by certified mail, return receipt requested. Upon receipt of an Opt-Out Notice, we will credit you for the standard cost of a certified
        letter. Your Opt-Out Notice must include your name, address, Social Security number, the date of this Agreement, a statement that you wish to
        opt out of the Arbitration Provision and must not be sent with any other correspondence. Indicating your desire to opt out of this Arbitration
        Provision will not affect your other rights or responsibilities under this Agreement, and applies only to this Arbitration Provision between
        you and us.
      </p>

      <h3 style="text-decoration: underline;">REASONABLE ACCOMMODATION.</h3>
      <p>
        Individuals with disabilities who require an accommodation to access our products or services should contact us via email at
        <a href="mailto:support@netcredit.com">support@netcredit.com</a>;
        by telephone at (877) 392-2014; or by mail at 175 W. Jackson Blvd., Suite 600, Chicago, IL 60604.  Please note that we may need advance notice to
        provide certain accommodations.
      </p><p>


      </p><h3>IMPORTANT NOTICES REQUIRED BY LAW.</h3>
      <h3>Married Wisconsin Residents</h3>
      <p>
        If you are married: (1) You confirm that the Loan is being incurred in the interest of your marriage or family. (2) No provisions
        of any marital property agreement, unilateral statement, or court decree under Wisconsin’s marital property act will adversely
        affect a creditor’s interest unless, prior to the time credit is granted, the creditor is furnished a copy of the agreement, statement
        or decree or has actual knowledge of the adverse provision. (3) If approved, you agree to advise us if you have a spouse who needs
        to receive notification of this extension of credit. The creditor may give notice of the opening of any credit account to the applicant’s spouse.
      </p>
  </div>

  <div class="article electronic-consent" id="contract-signature">
    <h2>BANK ACCOUNT INFORMATION, ELECTRONIC SIGNATURES AND CONSENTS.</h2>
    <p>By entering your bank account information, clicking the "I AGREE" button below and providing any additional information as may be requested below, you understand and acknowledge that you are taking the following four (4) separate actions:</p>

    <div class="section ach-authorization panel panel-default clearfix signature-form">
  <h3 class="panel-heading">1. BANK ACCOUNT INFORMATION</h3>
  <div class="sig-form-copy panel-body bank-account-information">
     <p id="bank_information_script">Enter the personal checking account where the majority of your income is deposited.
    This is also the Deposit Account where your funds will be deposited upon final approval and
    your payments will be withdrawn on your due dates according to the terms and conditions of the
    <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>.
    Do not enter a checking account that is already associated with an outstanding NetCredit loan.
    No funds will be transferred to or from your Deposit Account if your Loan application is withdrawn
    or declined during loan processing. In some cases, an electronic bank statement or
    similar information will be required in order to process your Loan application and make a final
    approval decision.</p>
  </div>

  <div class="sig-form-data panel-body">
    <div id="signature_bank_form" class="form-fieldset">
      
        <label for="signature_bank_routing_number">
          9-Digit Routing Number
        </label>
        
        <input type="text" class="input-text form-control" id="signature_bank_routing_number" name="signature[bank_account][bank_routing_number]" size="4" maxlength="9" autocorrect="off">
      

      
        <label for="signature_bank_account_number">
          Checking Account Number
        </label>
        <input type="text" class="input-text form-control" id="signature_bank_account_number" name="signature[bank_account][bank_account_number]" size="4" maxlength="17" autocorrect="off">
      

      
        <label for="signature_bank_account_number_confirmation">
          Verify Your Account Number
        </label>
        <input type="text" class="input-text form-control" id="signature_bank_account_number_confirmation" name="signature[bank_account][bank_account_number_confirmation]" size="4" maxlength="17" autocorrect="off">
      
    </div>
  </div>
</div>


    <div class="section ach-authorization panel panel-default clearfix signature-form">
      <h3 class="panel-heading">2. PAYMENT AUTHORIZATION</h3>
      <div class="sig-form-copy panel-body">
        <p>
          By typing in the last four digits of your Social Security number below and clicking the “I Agree” button below,
          you are electronically signing the Payment Authorization and you certify that you have fully read and understood
          the sections of this Agreement captioned "<a href="#payment_authorization">PAYMENT AUTHORIZATION</a>" and
          "<a href="#authorization_agreement">ELECTRONIC FUND TRANSFER AUTHORIZATION FOR YOUR LOAN PROCEEDS</a>", you agree to comply with, and be bound by,
          their terms and you agree and understand that you are authorizing us to credit your Deposit Account with the proceeds of your Loan
          
            and to seek payment from this Deposit Account for the amounts owed
          
          under this Agreement as set forth in those sections.
        </p>
      </div>

      <div class="sig-form-data panel-body">
        <div class="form-fieldset">
          <div class="form-field text-field">
            <label for="signature_ssn_last4">
              Enter the last 4 digits of your Social Security Number
            </label>
            
              <div class="input-text">2176</div>
            
          </div>
          <div class="fieldset-copy">
            <p>Please note that we separately authenticate the above electronic signature when you click the "I Agree" button below</p>
          </div>
        </div>
      </div>
    </div>


    <div class="section agreement-terms panel panel-default clearfix signature-form">
      <h3 class="panel-heading">
        3. AGREEMENT TO INSTALLMENT LOAN AGREEMENT
      </h3>
      <div class="sig-form-copy panel-body">
        <p>
          By entering your name and social security number below and clicking the “I AGREE” button below, you are electronically signing this Agreement
          and acknowledging that (a) you understand that NetCredit would be your lender under the above Agreement; (b) the Agreement was filled in
          before you signed it and that you have reviewed the entire Agreement, including the <a href="#truth_in_lending">FEDERAL TRUTH-IN-LENDING DISCLOSURES</a>;
          (c) you have reviewed and agree to NetCredit's <a href="https://www.netcredit.com/privacy-policy" target="_blank">PRIVACY POLICY</a>; (d) you
          acknowledge that your right to file suit against us for any claim or dispute regarding this Agreement is limited by the
          <a href="#waiver_of_jury_trial">WAIVER</a> and <a href="#arbitration_provision">ARBITRATION</a> provisions; (e) you represent that you are not
          a debtor under any proceeding in bankruptcy and have no intention to file a petition for relief under any chapter of the United States Bankruptcy
          Code; and (f) you acknowledge that you have read and understood all of the terms of this Agreement, including the provisions mentioned above and
          you agree to comply with, and be bound by, all of those terms.
        </p>
        <p>
          Please note that when you click the 'I Agree' button below, we authenticate your electronic signature and we also separately authenticate your
          electronic signature for the <a href="#payment_authorization">PAYMENT AUTHORIZATION</a>
          that you separately and voluntarily entered above.
        </p>
        <p>
          YOU SHOULD PRINT AND RETAIN A COPY OF THIS
          AGREEMENT FOR YOUR RECORDS. AN ELECTRONIC COPY WILL BE MAINTAINED ON THIS WEBSITE IN YOUR PASSWORD PROTECTED CUSTOMER HISTORY.</p>
        <p>
          <strong>Any comments or questions may be directed to our Customer Comment Line at the following toll-free number: (877) 392-2014 or via e-mail to
          <a href="mailto:support@netcredit.com">support@netcredit.com</a>.</strong>
        </p>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="text-danger">
          <p><strong>CAUTION: IT IS IMPORTANT THAT YOU THOROUGHLY READ THE AGREEMENT BEFORE YOU SIGN IT.</strong></p><p>
        </p></div>
        
          <p>
            <strong>THIS AGREEMENT INCLUDES AN ARBITRATION PROVISION. UNLESS YOU PROPERLY REJECT THE ARBITRATION PROVISION OR ARE
            A MEMBER OF THE ARMED FORCES OR A DEPENDENT OF SUCH A MEMBER COVERED BY THE FEDERAL MILITARY LENDING ACT, THE
            ARBITRATION PROVISION WILL HAVE A SUBSTANTIAL EFFECT ON YOUR RIGHTS IN THE EVENT OF A DISPUTE WITH US. FOR EXAMPLE,
            IF WE ELECT TO REQUIRE YOU TO ARBITRATE ANY CLAIM, YOU WILL NOT HAVE THE RIGHT TO A JURY TRIAL OR THE RIGHT TO
            PARTICIPATE IN A CLASS ACTION IN COURT OR IN ARBITRATION.</strong>
          </p>
        
      </div>

      
        
      
    </div>

    <div class="section consent-to-communication panel panel-default clearfix">
      <h3 class="panel-heading">4. CONSENT TO ELECTRONIC SIGNATURES</h3>
      <p class="panel-body">
        By clicking the “I AGREE” button below, you have consented to sign this Agreement using an electronic signature, you intend:
        (i) your electronic signature to be an electronic signature under applicable federal and state law; (ii) any printout of an
        electronic record of this Agreement and related notices to be an original document; (iii) to conduct business with Lender by
        electronic records and electronic signatures; and (iv) this Agreement will not be governed by Article 3 of the Uniform Commercial Code.
      </p>
    </div>

    
      <div>
        <table class="finance-charge">
          <tbody><tr><th>
            <p><strong>CAUTION—IT IS IMPORTANT THAT YOU THOROUGHLY READ THE AGREEMENT BEFORE YOU SIGN IT.</strong></p>
          </th>
          <th>
            <p>
              <strong>NOTICE TO BORROWER<br>
              (a) DO NOT SIGN THIS AGREEMENT BEFORE YOU READ THE WRITING ABOVE, EVEN IF OTHERWISE ADVISED.<br>
              (b) DO NOT SIGN THIS AGREEMENT IF IT CONTAINS ANY BLANK SPACES.<br>
              (c) YOU ARE ENTITLED TO AN EXACT COPY OF ANY AGREEMENT YOU SIGN.<br>
              (d) YOU HAVE THE RIGHT AT ANY TIME TO PAY IN ADVANCE THE UNPAID BALANCE DUE UNDER THIS AGREEMENT
                WITHOUT PENALTY AND YOU MAY BE ENTITLED TO A PARTIAL REFUND OF THE FINANCE CHARGE.</strong>
            </p>
          </th>
        </tr></tbody></table>
      </div>
      <br>
    

    

    <div id="consent-to-credit-report-hard-pull">
      <p>By clicking "I Agree" below, I understand that an inquiry will be
         run on my credit report, which may impact my credit score.
       </p>
    </div>
  </div>
</div>
</body></html>`;
