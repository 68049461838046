import React, { useCallback } from 'react';

import { useAtom } from 'jotai';

import { Modal } from '@consumer-frontend/shared-ui';

import { modalConfigAtom } from '../../store';
import { NcButton } from '../NcButton';

import '@consumer-bootstrap/brands/netcredit/modal.scss';
import '@consumer-bootstrap/netcredit.scss';

export const NcModal: React.FC = () => {
  const [modalconfig, setModalConfig] = useAtom(modalConfigAtom);
  const {
    body,
    modalOnClick,
    primaryText,
    secondaryText,
    show,
    title,
  } = modalconfig;

  const closeModal = useCallback(
    () => setModalConfig((prevConfig) => ({ ...prevConfig, show: false })),
    [setModalConfig],
  );

  const handlePrimaryClick = useCallback(
    () => {
      modalOnClick?.();
      closeModal();
    },
    [modalOnClick, closeModal],
  );

  return (
    <Modal
      show={show}
      onHide={closeModal}
      title={title}
      body={body}
      footer={
        <div className={secondaryText ? 'default' : 'informational'}>
          {secondaryText && (
            <NcButton variant="outline-primary" onClick={closeModal}>
              {secondaryText}
            </NcButton>
          )}
          <NcButton variant="primary" onClick={handlePrimaryClick}>
            {primaryText}
          </NcButton>
        </div>
      }
    />
  );
};
