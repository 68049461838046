import React from 'react';

import { DropdownToggle as BootstrapToggle } from 'react-bootstrap';

export type DropdownTogglePropsT = {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
};

export type DropdownToggleT = React.FC<DropdownTogglePropsT>;

export const DropdownToggle: DropdownToggleT = ({ children, ...props }) => (
  <BootstrapToggle {...props}>
    {children}
  </BootstrapToggle>
);
