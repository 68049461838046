import React, { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';

import { useMessages } from '@consumer-frontend/nc-api';
import { Container } from '@consumer-frontend/shared-ui';

import {
  accountIdAtom, messageCenterPaginationAtom, messageDetailPaginationAtom,
} from '../../store';
import { NcError } from '../NcError';
import { NcLoadingSpinner } from '../NcLoadingSpinner';
import { NcMessageCenterListGroup } from '../NcMessageCenterListGroup';
import { NcPagination } from '../NcPagination';

import '@consumer-bootstrap/brands/netcredit/message-center.scss';

export const messagesLoadingError = 'There was an issue loading your messages.';
export type NcMessageCenterT = React.FC;

export const NcMessageCenter: NcMessageCenterT = () => {
  const [accountId] = useAtom(accountIdAtom);
  const [{ limit, offset: summaryOffset }, setSummaryPaginationValues] = useAtom(messageCenterPaginationAtom);
  const [_, setDetailPaginationValues] = useAtom(messageDetailPaginationAtom);
  const {
    count: messageCount,
    error: messagesError,
    isLoading: messagesAreLoading,
    messages,
  } = useMessages({
    id: accountId || '',
    limit,
    offset: summaryOffset,
  });

  // Re-sync message detail pagination values every time message center page is loaded
  // or when offset or totalCount are updated.
  useEffect(
    () => {
      setDetailPaginationValues((detailValues) => {
        // Guard against unnecessary re-renders
        if (messageCount === detailValues.total && summaryOffset === detailValues.offset) { return detailValues; }

        return {
          ...detailValues,
          offset: summaryOffset,
          total: messageCount || 0,
        };
      });
    },
    [
      messageCount,
      setDetailPaginationValues,
      summaryOffset,
    ],
  );

  // Update message summary pagination values when message count changes.
  useEffect(
    () => {
      setSummaryPaginationValues((values) => {
        // Guard against unnecessary re-renders
        if (messagesAreLoading || messageCount === values.total) { return values; }

        return { ...values, total: messageCount || 0 };
      });
    },
    [
      messageCount,
      messagesAreLoading,
      setSummaryPaginationValues,
    ],
  );

  // Track page views in Datadog.
  useEffect(
    () => {
      if (messagesAreLoading) return;

      datadogRum.addAction(messageCount ? 'NcMessageCenterView' : 'NcMessageCenterEmptyView');
    },
    [messageCount, messagesAreLoading],
  );

  // Focus first message
  useEffect(
    () => {
      if (messagesAreLoading || !messages?.length) return;

      const firstMessage = document.querySelector<HTMLElement>('a.nc-message-center-link');

      firstMessage?.focus();
      firstMessage?.blur();
    },
    [messages, messagesAreLoading],
  );

  return (
    <Container className="message-center d-flex flex-column rounded" hasBoxShadow>
      <h1 className="h2">Message Center</h1>
      {messagesError && <NcError displaySupportText message={messagesLoadingError} />}
      {!messagesError && (
        <>
          <div className="d-flex flex-row top-nav">
            <h2 className="h3">Inbox</h2>
            <NcPagination atom={messageCenterPaginationAtom} />
          </div>
          {messagesAreLoading && <NcLoadingSpinner />}
          {!messagesAreLoading && (
            <>
              <NcMessageCenterListGroup messages={messages || []} />

              {/* Only show bottom pagination when there are messages */}
              {!!messages?.length && (
                <div className="d-flex flex-row bottom-nav">
                  <NcPagination atom={messageCenterPaginationAtom} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
